import { RootState, initialRootState } from './state'
import { AppAction, ActionType } from './actions'
import { List } from 'immutable'

export const rootReducer = (state: RootState = initialRootState, action: AppAction): RootState => {
    console.log("rootReducer: " + action.type)
    if (action.type === ActionType.CRITICAL_ERROR) {
        return state.merge({failure: {present: true, data: action.data}})
    } else if (action.type === ActionType.RESET_STATE) {
        return state.merge(initialRootState)
    } else if (action.type === ActionType.SET_TOKEN) {
        return state.merge({token: action.token})
    } else if (action.type === ActionType.SET_DB_INSTANCES) {
        return state.merge({dbInstances: action.dbInstances})
    } else if (action.type === ActionType.SET_SELECTED_DB_INSTANCE_ID) {
        return state.merge({selectedDbInstanceId: action.dbInstanceId})
    } else if (action.type === ActionType.FETCH_COMMON_DATA_REQUEST) {
        return state.merge({commonData: null})
    } else if (action.type === ActionType.FETCH_COMMON_DATA_RESPONSE) {
        return state.merge({commonData: action.payload})
    } else if (action.type === ActionType.NAVIGATE_TO) {
        return state.merge({
            navigation: {
                screenStack: List([action.stackItem])
            }
        })
    } else if (action.type === ActionType.NAVIGATION_PUSH) {
        return state.merge({
            navigation: {
                screenStack: state.navigation.screenStack.push(action.stackItem)
            }
        })
    } else if (action.type === ActionType.NAVIGATION_POP) {
        return state.merge({
            navigation: {
                screenStack: state.navigation.screenStack.pop()
            }
        })
    } else {
        return state
    }
}