import React, { CSSProperties } from 'react'
import '../fonts/fonts.css'
import { ContentText, iconFontFamily, normalFontSize, normalTextColor, palette, standardHalfMargin, View } from '../styles'

const iconChar = {
    'warning': '',
    'hourglass': '',
    'confirmed': '',
    'eye': '',
    'edit': '',
    'cancel': '',
    'filter': '',
    'add': '',
    'calendar': '',
    'clock': '',
    'crown': '',
    'arrow-right': '',
    'excel': '',
    'team': '',
    'person': '',
    'timer': '',
    'play': '',
    'info': '',
}

export type IconName = keyof typeof iconChar

export const getIconChar = (name: IconName): string => iconChar[name]

type IconProps = {
    name: IconName,
    style?: CSSProperties,
    tooltip?: string,
}
export const BaseIcon: React.FunctionComponent<IconProps> = props =>
    <View tooltip={props.tooltip}>
        <ContentText string={getIconChar(props.name)} style={{fontFamily: iconFontFamily, color: normalTextColor, ...props.style}} />
    </View>

export const SmallIcon: React.FunctionComponent<IconProps> = props =>
    <BaseIcon {...props} style={{fontSize: normalFontSize * 2, margin: -standardHalfMargin.marginTop, ...props.style}} />

export const LargeIcon: React.FunctionComponent<IconProps> = props =>
    <BaseIcon {...props} style={{fontSize: normalFontSize * 2.5, margin: -standardHalfMargin.marginTop * 1.5, ...props.style}} />


type NamedIconProps = {
    style?: CSSProperties,
    tooltip?: string,
}

export const WarningSmallIcon: React.FunctionComponent<NamedIconProps> = props => <SmallIcon {...props} name='warning' style={{color: palette.errorText, ...props.style}} />
export const WarningLargeIcon: React.FunctionComponent<NamedIconProps> = props => <LargeIcon {...props} name='warning' style={{color: palette.errorText, ...props.style}} />

export const HourglassSmallIcon: React.FunctionComponent<NamedIconProps> = props => <SmallIcon {...props} name='hourglass' style={{color: normalTextColor, ...props.style}} />
export const HourglassLargeIcon: React.FunctionComponent<NamedIconProps> = props => <LargeIcon {...props} name='hourglass' style={{color: normalTextColor, fontSize: normalFontSize * 3, margin: -standardHalfMargin.marginTop * 2, ...props.style}} />

export const ConfirmedSmallIcon: React.FunctionComponent<NamedIconProps> = props => <SmallIcon {...props} name='confirmed' style={{color: palette.successText, ...props.style}} />
export const ConfirmedLargeIcon: React.FunctionComponent<NamedIconProps> = props => <LargeIcon {...props} name='confirmed' style={{color: palette.successText, ...props.style}} />

export const TooltipIcon: React.FunctionComponent<NamedIconProps> = props => <SmallIcon {...props} name='info' style={{color: palette.buttonPrimary, ...props.style}} />