import React, { CSSProperties } from 'react'
import { BaseText, palette, standardHalfMargin, standardRadius, View } from '../styles'
import {likertMin, likertMax, likertStep, likertReached, likertValues} from '../screens/CreateKpiScreen'
import { numberToLocString, roundLikert } from '../utils'

type LikertProgressBarInputProps = {
    aggregateValue: number,
    lightBg: boolean,
    containerStyle?: CSSProperties,
    sizeScale?: number,
}

const LikertProgressBar: React.FunctionComponent<LikertProgressBarInputProps> = props => {
    const roundedVal = roundLikert(props.aggregateValue)
    const barColor =
        roundedVal < likertReached ? palette.progressUnreachedBg :
        roundedVal < likertReached + likertStep ? palette.progressJustReachedBg :
        palette.progressOverBg
    const bgColor = props.lightBg ? palette.white : palette.textInputFocus
    const scale = props.sizeScale ?? 1
    return <View style={{flexDirection: 'row', alignItems: 'center', ...standardHalfMargin, ...props.containerStyle}} tooltip={numberToLocString(props.aggregateValue, 3)}>
        {likertValues.map((likert) => {
            const radiuses: CSSProperties =
                likert === likertMin ? {borderTopLeftRadius: standardRadius, borderBottomLeftRadius: standardRadius} :
                likert === likertMax ? {borderTopRightRadius: standardRadius, borderBottomRightRadius: standardRadius} :
                {}
            return <View key={likert} style={{
                width: 25 * scale,
                height: 18 * scale,
                backgroundColor: likert <= roundedVal ? barColor : bgColor,
                marginLeft: likert === likertMin ? 0 : 2,
                ...radiuses,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                {(/*likert === likertMin || likert === likertMax || */likert === roundedVal) &&
                    <BaseText style={{color: likert <= roundedVal ? palette.white : palette.progressUnreachedFg, fontSize: 12 * scale}} string={likert === roundedVal ? numberToLocString(props.aggregateValue, 1) : numberToLocString(likert)} />}
            </View>
        })}
    </View>
}
export default LikertProgressBar