import { LocStrings } from './def'

const strings: LocStrings = {
    dateFormat: "MM/DD/YYYY",

    loadingMessage: "Please wait...",

    ok: "Ok",
    cancel: "Cancel",
    error: "Error",
    back: "Back",
    select: "Select",

    selectedItems: "Selected",

    yes: "Yes",
    no: "No",
    name: "Name",
    members: "Members",
    title: "Title",
    description: "Description",
    type: "Type",
    manager: "Manager",
    assignee: "Assignee",
    assignees: "Assignees",
    samplers: "Samplers",
    none: "None",
    team: "Team",
    parent: "Parent",
    activityPeriod: "Activity perdiod",
    credits: "Credits",
    typology: "Typology",
    visibility: "Visibility",
    aggregationMethod: "Aggregation method",
    weight: "Weight",
    kpi: "KPI",
    kpis: "KPIs",
    login: "Login",
    logout: "Logout",
    collaborator: "Collaborator",
    status: "Status",
    goal: "Goal",
    goals: "Goals",
    empty: "Empty",
    reparent: "Change parent",
    reassign: "Change assignees",
    delete: "Delete",
    lastValue: "Last value",
    date: "Date",
    newValue: "New value",
    details: "Details",
    rootGoal: "Root goal",
    rootGoals: "Root goals",
    recipients: "Recipients",
    invalid: "Invalid",
    edit: "Edit",
    kpiTarget: "Target",
    unknownLabel: "(unknown)",
    copy: "Copy",
    create: "Create",
    confirm: "Confirm",
    mode: "Mode",
    cancelConfirmation: "Cancel confirmation",
    closeGoal: "Close goal",
    reopen: "Reopen",
    showMore: "Show more",
    search: "Search",
    reset: "Reset",

    kpiTypeQuantitativeThreshold: "Quantitative threshold",
    kpiTypeQuantitativeContinuous: "Quantitative continuous",
    kpiTypeQualitative: "Qualitative",
    kpiAggrAverage: "Average",
    kpiAggrMinimum: "Minimum",
    goalVisStandard: "Standard",
    goalVisPublic: "Public",
    goalVisPrivate: "Private",
    goalPhaseUnapproved: "Unconfirmed",
    goalPhaseOpen: "Open",
    goalPhaseClose: "Close",
    goalStatusInconsistent: "Inconsistent",
    goalStatusExpired: "Expired",

    averageScore: "Results average",
    totGoalNumber: "Goals total",

    allGoals: "All goals",
    myGoals: "My goals",
    collaboratorGoals: "Collaborator goals",
    goalsByManager: "Goals by manager",
    createGoal: "Create goal",
    createKpi: "Create KPI",
    viewGoal: "Goal details",
    viewKpi: "KPI details",
    manageInfluences: "Manage influences",
    editGoal: "Edit goal",
    editKpi: "Edit KPI",

    kpiTitle: "KPI title",
    selectSamplerGroups: "Select sampler groups",
    selectSharedCollection: "Select shared source",
    kpiData: "KPI data",
    thresholds: "Thresholds",
    calculateLinearly: "Calculate linearly",
    clearThresholds: "Clear",
    collectionData: "Source",
    newSingleCollection: "New SINGLE data source",
    newSharedCollection: "New SHARED data source",
    existingCollection: "Existing data source",
    singleCollection: "SINGLE collection",
    sharedCollection: "SHARED collection",
    samplingNotes: "Sampling notes",
    unitOfMeasure: "Unit of measure",

    selectManager: "Select manager",
    selectParent: "Select parent",
    goalsOfTheManager: "Manager goals",
    selectAssignees: "Select assignees",
    goalData: "Goal data",
    noParentSoIsRoot: "No parent (root goal)",
    noManager: "No manager",
    addKpi: "Add KPI",
    cannotDeleteBecauseOfChildren: "Cannot delete goal because it has some children:",
    deleteGoalConfirmQuestion: "Are you sure you want to delete this goal?",
    selectReparentManager: "Select new manager",
    selectReparentGoal: "Select new parent",
    updateKpis: "Update KPIs",
    nonPrivilegedMakeGoalEffectiveMessage: "If you confirm the goal, you will no longer be able to change it. Are you sure you want to confirm?",
    privilegedMakeGoalEffectiveMessage: "Are you sure you want to confirm the goal?",
    userModePrivilegedDescr: "Create goal as privileged",
    userModeManagerDescr: "Create goal for your assignee(s)",
    userModeAssigneeDescr: "Suggest your goal to your manager",
    makeGoalNotEffectiveMessage: "Are you sure you want to cancel the confirmation of this goal?",
    closeGoalMessage: "Are you sure you want to close this goal? Please check that the KPIs are updated, first!",
    reopenGoalMessage: "Are you sure you want to reopen this goal?",

    sharedCollections: "Shared data sources",
    updateCollections: "Update sources",
    sharedCollectionUsedByFollowingGoals: "Cannot delete collection because it is used by the following goals:",
    deleteSharedCollectionConfirmQuestion: "Are you sure you want to delete this shared collection?",

    deleteLastSample: "Delete last value",
    deleteSampleConfirmMessage: "Are you sure you want to delete the value?",

    selectRootGoal: "Select root goal",
    influences: "Influences",

    errorMessage: "An error occurred",
    restart: "Restart",
    sessionExpired: "Session expired",
    sessionExpiredMessage: "Your session expired, please login again",

    backoffice: "Backoffice",

    activityStart: "Activity start",
    activityEnd: "Activity end",
    value: "Value",
    phase: "Phase",
    consistent: "Consistent",
    kpiNumber: "KPI number",
    kpiTitles: "KPI titles",
    kpiTypes: "KPI types",

    includePartialActivityPeriods: "Include partial periods",
    score: "Score",
    selectTeam: "Select team",
    selectPerson: "Select person",
    searchGoals: "Search goal",

    parentTooltip: "The current goal is bound to a goal from which it descends: the achievement of the current goal is part of the achievement of the parent goal",
    assigneesTooltip: "Person or group of persons to whom the goal is assigned",
    kpiTooltip: "Key performance indicators of the goal",
    kpiAggregationTooltip: "Method by which KPIs are merged into a unique indicator for the achievement of the goal",
    quantitativeThresholdTooltip: "The score 'jumps' when a threshold is reached",
    quantitativeContinuousTooltip: "The score is calculated by interpolating between thresholds",
    qualitativeTooltip: "Non-quantifiable milestones can be expressed",
    creditsTooltip: "A quantification of the importance of the goal",
    visibilityTooltip: "Standard: visible to assignees and his collaborators. Private: visible only to assignees.",
    thresholdsTooltip: "1 - 2.5: target not achieved<br>3: target achieved<br>3.5 - 5: target overachieved",
    samplersTooltip: "Who can add samples to this data source",
    unitOfMeasureTooltip: "Unit of measure of the quantitative data",

    filterTitle: "Filter title",
    filterCollaborator: "Filter collaborator",
    filterManager: "Filter manager",
    filterTeam: "Filter team",

    selectDbInstance: "Select the instance you want to access",
}
export default strings