import React from 'react';
import { View } from './styles';
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from './state'
import LoadingScreen from './screens/LoadingScreen'
import PureRouter from './components/PureRouter'
import ErrorScreen from './screens/ErrorScreen'
import SelectDbInstanceScreen from './screens/SelectDbInstanceScreen';

const connector = connect((s: RootState) => ({state: s}))

type Props = ConnectedProps<typeof connector>

const App: React.FunctionComponent<Props> = props => {
    return (
        <View style={{flex: 1}}>
            {props.state.failure.present ?
                <ErrorScreen />
            :
                !props.state.dbInstances ?
                    <LoadingScreen />
                :
                    props.state.selectedDbInstanceId === null ?
                        <SelectDbInstanceScreen />
                    :
                        !props.state.commonData ?
                            <LoadingScreen />
                        :
                            <PureRouter />
            }
        </View>
    );
}

export default connector(App)