import { GoalsScreen } from './screens/GoalListScreens'
import CreateGoalScreen from './screens/CreateGoalScreen'
import ViewGoalScreen from './screens/ViewGoalScreen'
import ListSelectorScreen from './screens/ListSelectorScreen'
import { ConnectedComponent } from 'react-redux'
import CreateKpiScreen from './screens/CreateKpiScreen'
import { getPlatform } from './baseUtils'
import { LS } from './loc/loc'
import LoadingScreen from './screens/LoadingScreen'
import SharedCollectionsScreen from './screens/SharedCollectionsScreen'
import UpdateCollectionsScreen from './screens/UpdateCollectionsScreen'
import ViewKpiScreen from './screens/ViewKpiScreen'
import CreateInfluenceScreen from './screens/CreateInfluencesScreen'
import BackofficeScreen from './screens/BackofficeScreen'
import ViewSharedCollectionScreen from './screens/ViewSharedCollectionScreen'
import CreateSharedCollectionScreen from './screens/CreateSharedCollectionScreen'
import SelectParentForNormalScreen from './screens/SelectParentForNormalScreen'
import SelectUserModeScreen from './screens/SelectUserModeScreen'
import SelectDbInstanceScreen from './screens/SelectDbInstanceScreen'

export enum ScreenRouteId {
    SelectDbInstance,
    Goals,
    SelectUserMode,
    CreateGoal,
    SelectParentForNormal,
    ViewGoal,
    ListSelector,
    CreateKpi,
    ViewKpi,
    Loading,
    SharedCollections,
    ViewSharedCollection,
    CreateSharedCollection,
    UpdateCollections,
    CreateInfluences,
    Backoffice,
}
export const defaultScreenRoute = ScreenRouteId.Goals

export type ScreenRouteData = {
    id: ScreenRouteId,
    compDef: React.FunctionComponent | React.ComponentClass | ConnectedComponent<any, any>,
    title: string,
}

const routesArray: ScreenRouteData[] = [
    {id: ScreenRouteId.SelectDbInstance, compDef: SelectDbInstanceScreen, title: LS('selectDbInstance')},
    {id: ScreenRouteId.Goals, compDef: GoalsScreen, title: LS('goals')},
    {id: ScreenRouteId.SelectUserMode, compDef: SelectUserModeScreen, title: LS('createGoal')},
    {id: ScreenRouteId.CreateGoal, compDef: CreateGoalScreen, title: LS('createGoal')},
    {id: ScreenRouteId.SelectParentForNormal, compDef: SelectParentForNormalScreen, title: LS('selectParent')},
    {id: ScreenRouteId.ViewGoal, compDef: ViewGoalScreen, title: LS('viewGoal')},
    {id: ScreenRouteId.ListSelector, compDef: ListSelectorScreen, title: ""},
    {id: ScreenRouteId.CreateKpi, compDef: CreateKpiScreen, title: LS('createKpi')},
    {id: ScreenRouteId.ViewKpi, compDef: ViewKpiScreen, title: LS('viewKpi')},
    {id: ScreenRouteId.Loading, compDef: LoadingScreen, title: ''},
    {id: ScreenRouteId.SharedCollections, compDef: SharedCollectionsScreen, title: LS('sharedCollections')},
    {id: ScreenRouteId.ViewSharedCollection, compDef: ViewSharedCollectionScreen, title: LS('details')},
    {id: ScreenRouteId.CreateSharedCollection, compDef: CreateSharedCollectionScreen, title: LS('create')},
    {id: ScreenRouteId.UpdateCollections, compDef: UpdateCollectionsScreen, title: LS('updateCollections')},
    {id: ScreenRouteId.CreateInfluences, compDef: CreateInfluenceScreen, title: LS('manageInfluences')},
    {id: ScreenRouteId.Backoffice, compDef: BackofficeScreen, title: LS('backoffice')},
]

const routesMap = new Map(routesArray.map(r => [r.id, r]))

export const routeDataFromId = (id: ScreenRouteId): ScreenRouteData => {
    const data = routesMap.get(id)
    if (!data) {
        throw new Error("route id not found: " + id)
    }
    return data
}

const routeUrlParamName = 'route'
export const getUrlFromRoute = (route: ScreenRouteId) => '?' + routeUrlParamName + '=' + ScreenRouteId[route]
export const getRouteFromUrl = (urlStr: string): ScreenRouteId => {
    const url = new URL(urlStr)
    const routeFromUrl = url.searchParams.get(routeUrlParamName)
    const routes = routesArray.map(route => route.id).filter(route => ScreenRouteId[route] === routeFromUrl) // serve questo casino perchè non c'è un modo per passare direttamente da stringa a enum
    return routes.length === 1 ? routes[0] : defaultScreenRoute
}
export const getCurrentUrl = async () => getPlatform() === 'web' ? window.location.href : (() => { throw new Error("only web is supported for now") })()