import { LocStrings } from './def'

const strings: LocStrings = {
    dateFormat: "DD/MM/YYYY",

    loadingMessage: "Attendere prego...",

    ok: "Ok",
    cancel: "Annulla",
    error: "Errore",
    back: "Indietro",
    select: "Seleziona",

    selectedItems: "Selezionati",

    yes: "Si",
    no: "No",
    name: "Nome",
    members: "Membri",
    title: "Titolo",
    description: "Descrizione",
    type: "Tipo",
    manager: "Responsabile",
    assignee: "Assegnatario",
    assignees: "Assegnatari",
    samplers: "Rilevatori",
    none: "Nessuno",
    team: "Squadra",
    parent: "Genitore",
    activityPeriod: "Periodo di attività",
    credits: "Crediti",
    typology: "Tipologia",
    visibility: "Visibilità",
    aggregationMethod: "Metodo di aggregazione",
    weight: "Peso",
    kpi: "KPI",
    kpis: "KPI",
    login: "Login",
    logout: "Logout",
    collaborator: "Collaboratore",
    status: "Stato",
    goal: "Obiettivo",
    goals: "Obiettivi",
    empty: "Vuoto",
    reparent: "Cambia genitore",
    reassign: "Cambia assegnatari",
    delete: "Elimina",
    lastValue: "Ultimo valore",
    date: "Data",
    newValue: "Nuovo valore",
    details: "Dettagli",
    rootGoal: "Obiettivo radice",
    rootGoals: "Obiettivi radice",
    recipients: "Destinatari",
    invalid: "Non valido",
    edit: "Modifica",
    kpiTarget: "Target",
    unknownLabel: "(sconosciuto)",
    copy: "Copia",
    create: "Crea",
    confirm: "Conferma",
    mode: "Modalità",
    cancelConfirmation: "Annulla conferma",
    closeGoal: "Chiudi obiettivo",
    reopen: "Riapri",
    showMore: "Mostra di più",
    search: "Cerca",
    reset: "Resetta",

    kpiTypeQuantitativeThreshold: "Quantitativo a soglie",
    kpiTypeQuantitativeContinuous: "Quantitativo continuo",
    kpiTypeQualitative: "Qualitativo",
    kpiAggrAverage: "Media",
    kpiAggrMinimum: "Minimo",
    goalVisStandard: "Standard",
    goalVisPublic: "Pubblico",
    goalVisPrivate: "Privato",
    goalPhaseUnapproved: "Non confermato",
    goalPhaseOpen: "Aperto",
    goalPhaseClose: "Chiuso",
    goalStatusInconsistent: "Inconsistente",
    goalStatusExpired: "Scaduto",

    averageScore: "Media risultati",
    totGoalNumber: "Totale obiettivi",

    allGoals: "Tutti gli obiettivi",
    myGoals: "Miei obiettivi",
    collaboratorGoals: "Obiettivi collaboratori",
    goalsByManager: "Obiettivi per responsabile",
    createGoal: "Crea obiettivo",
    createKpi: "Crea KPI",
    viewGoal: "Dettagli obiettivo",
    viewKpi: "Dettagli KPI",
    manageInfluences: "Gestisci influenze",
    editGoal: "Modifica obiettivo",
    editKpi: "Modifica KPI",
    nonPrivilegedMakeGoalEffectiveMessage: "Se confermi l'obiettivo, non potrai più modificarlo. Sei sicuro di volerlo confermare?",
    privilegedMakeGoalEffectiveMessage: "Sei sicuro di voler confermare l'obiettivo?",
    makeGoalNotEffectiveMessage: "Sei sicuro di voler annullare la conferma di questo obiettivo?",
    closeGoalMessage: "Sei sicuro di volere chiudere questo obiettivo? Per favore prima controlla di avere aggiornato i KPI!",
    reopenGoalMessage: "Sei sicuro di volere riaprire questo obiettivo?",

    kpiTitle: "Titolo KPI",
    selectSamplerGroups: "Seleziona gruppi di rilevatori",
    selectSharedCollection: "Seleziona fonte condivisa",
    kpiData: "Dati KPI",
    collectionData: "Fonte",
    thresholds: "Soglie",
    calculateLinearly: "Calcola linearmente",
    clearThresholds: "Svuota",
    newSingleCollection: "Nuova fonte dati SINGOLA",
    newSharedCollection: "Nuova fonte dati CONDIVISA",
    existingCollection: "Fonte dati esistente",
    singleCollection: "Fonte dati SINGOLA",
    sharedCollection: "Fonte dati CONDIVISA",
    samplingNotes: "Note per il rilevatore",
    unitOfMeasure: "Unità di misura",

    selectManager: "Seleziona responsabile",
    selectParent: "Seleziona genitore",
    goalsOfTheManager: "Obiettivi del responsabile",
    selectAssignees: "Seleziona assegnatari",
    goalData: "Dati obiettivo",
    noParentSoIsRoot: "Nessun genitore (obiettivo radice)",
    noManager: "Nessun responsabile",
    addKpi: "Aggiungi KPI",
    cannotDeleteBecauseOfChildren: "Impossibile eliminare l'obiettivo perchè ha dei figli:",
    deleteGoalConfirmQuestion: "Sei sicuro di voler eliminare questo obiettivo?",
    selectReparentManager: "Seleziona nuovo responsabile",
    selectReparentGoal: "Seleziona nuovo genitore",
    updateKpis: "Aggiorna KPI",
    userModePrivilegedDescr: "Crea un obiettivo come privilegiato",
    userModeManagerDescr: "Crea un obiettivo per un tuo collaboratore (o più collaboratori)",
    userModeAssigneeDescr: "Proponi un tuo obiettivo al tuo responsabile",

    sharedCollections: "Fonti dati condivise",
    updateCollections: "Aggiorna fonti",
    sharedCollectionUsedByFollowingGoals: "Impossibile eliminare la fonte condivisa perchè è utilizzata dai seguenti obiettivi:",
    deleteSharedCollectionConfirmQuestion: "Sei sicuro di voler eliminare questa fonte condivisa?",

    deleteLastSample: "Elimina ultima valore",
    deleteSampleConfirmMessage: "Sei sicuro di voler eliminare il valore?",

    selectRootGoal: "Seleziona obiettivo radice",
    influences: "Influenze",

    errorMessage: "Si è verificato un errore",
    restart: "Riavvia",
    sessionExpired: "Sessione scaduta",
    sessionExpiredMessage: "La sessione è scaduta, per favore rieffettua il login",

    backoffice: "Backoffice",

    activityStart: "Inizio attività",
    activityEnd: "Fine attività",
    value: "Valore",
    phase: "Fase",
    consistent: "Consistente",
    kpiNumber: "Numero KPI",
    kpiTitles: "Titoli KPI",
    kpiTypes: "Tipo KPI",

    includePartialActivityPeriods: "Includi periodi parziali",
    score: "Risultato",
    selectTeam: "Seleziona squadra",
    selectPerson: "Seleziona persona",
    searchGoals: "Cerca obiettivi",

    parentTooltip: "L'obiettivo corrente è legato ad un obiettivo da cui discende: il raggiungimento dell'obiettivo corrente è parte del conseguimento dell'obiettivo genitore",
    assigneesTooltip: "Persona o gruppo di persone a cui l'obiettivo è assegnato",
    kpiTooltip: "Indicatori di avanzamento dell'obiettivo",
    kpiAggregationTooltip: "Metodo attraverso il quale si uniscono più KPI per costruire un indicatore unico per il raggiungimento dell'obiettivo",
    quantitativeThresholdTooltip: "Al raggiungimento della soglia scatta il punteggio",
    quantitativeContinuousTooltip: "Il punteggio è calcolato con interpolazione tra le soglie indicate",
    qualitativeTooltip: "Si possono indicare traguardi non quantificabili",
    creditsTooltip: "Una quantificazione dell'importanza dell'obiettivo",
    visibilityTooltip: "Standard: visibile agli assegnatari e ai loro collaboratori, potrà essere genitore. Privato: visibile solo agli assegnatari, non potrà essere genitore.",
    thresholdsTooltip: "1 - 2,5: target non raggiunto<br>3: target raggiunto<br>3,5 - 5: target superato",
    samplersTooltip: "Chi può aggiungere rilevamenti a questa fonte dati",
    unitOfMeasureTooltip: "Unità di misura dei dati quantitativi",

    filterTitle: "Filtra titolo",
    filterCollaborator: "Filtra collaboratore",
    filterManager: "Filtra responsabile",
    filterTeam: "Filtra squadra",

    selectDbInstance: "Seleziona l'istanza a cui vuoi accedere",
}
export default strings