import React, { CSSProperties, ReactElement, useState } from 'react'
import { imageSource, isGoalOpenAndExpired } from './utils'
import './fonts/fonts.css'
import { GoalInstanceComplete, GoalPhaseId } from './backendTypes'
import ReactTooltip from 'react-tooltip'
import { SmallIcon, TooltipIcon } from './components/Icon'

export const palette = {
    menu: '#333333',
    menuOver: '#4F4F4F',
    borderDefault: '#828282',
    borderFocus: '#8C766F',
    buttonDisabled: '#E3E3E3',
    button: '#F6F6F6',
    textInputFocus: '#F7F1EF',
    pageBg: '#FAFAFA',

    logo: '#FA440F',
    buttonPrimary: '#FF5727',
    pageTitle: '#FF7A53',
    pageTitlePrev: '#FFD2C2',
    tableHover: '#ffe7df',

    white: '#ffffff',

    successBg: '#DFECBB',
    successText: '#93BE1F',
    errorBg: '#FFD2C2',
    errorText: '#FF7A53',
    /*warningBg: 'rgba(255,51,102,0.25)',
    warningText: '#F36',*/
    infoBg: 'rgba(51,153,255,0.25)',
    infoText: '#39F',

    progressUnreachedBg: '#e02',
    progressJustReachedBg: '#fb0',
    progressOverBg: '#190',
    progressUnreachedFg: '#BDBDBD',
}

export const pageWidth = 1200
export const pageBgColor = palette.pageBg
export const pageBoxShadow = '0 0 50px rgba(0,0,0,0.3)'

export const normalFontSize = 14
export const normalFontFamily = 'Roboto, Arial, sans-serif'
export const iconFontFamily = 'K-Icons'
export const normalTextColor = palette.menu

export const standardSpaceVert = 10
export const standardSpaceHor = 10

export const standardRadius = 4

export const logoSize = 25
export const headerRowHeight = 40
export const headerFontSize = normalFontSize

export const screenTitleFontSize = 23
export const menuHeaderColor = palette.menu
export const titleHeaderColor = palette.pageTitle
export const cardHeaderTextColor = palette.pageTitle
export const cardHeaderFontSize = 18
export const cardHeaderColor = palette.borderFocus
export const cardBorderRadius = 8
export const cardBgColor = palette.white
export const cardBoxShadow = '0 2px 4px rgba(0,0,0,0.1)'

export const contentBorderStyle: CSSProperties = {
    borderStyle: 'solid',
    borderWidth: 1,
}

export const contentBorderStyleWithRadius: CSSProperties = {
    ...contentBorderStyle,
    borderRadius: standardRadius,
}

export const contentSingleLineHeight = 30
export const inputIconCellWidth = 32

export const buttonBorderRadius = contentBorderStyleWithRadius.borderRadius
export const buttonMainMinWidth = 200
export const buttonMenuMinWidth = 96
export const buttonContentMinWidth = 95
export const buttonHeight = contentSingleLineHeight
export const buttonFontSize = normalFontSize
export const buttonBoxShadow = '0 2px 4px rgba(0,0,0,0.2)'

export const disabledTextColor = palette.borderDefault

export const tableBorderRadius = cardBorderRadius

export const closedGoalOpacity = 0.3

export const getTextStyleForGoal = (g: GoalInstanceComplete): CSSProperties | undefined =>
    isGoalOpenAndExpired(g)
        ? {fontWeight: 'bold'}
        : g.phaseId === GoalPhaseId.Closed
            ? {opacity: closedGoalOpacity}
            : undefined

// non usati horizontal e vertical perchè sembra che a volte non vadano
export const standardFullPadding = {
    paddingTop: standardSpaceVert,
    paddingBottom: standardSpaceVert,
    paddingLeft: standardSpaceHor,
    paddingRight: standardSpaceHor,
}
export const standardHalfPaddingHor = {
    paddingLeft: standardFullPadding.paddingLeft / 2,
    paddingRight: standardFullPadding.paddingRight / 2,
}
export const standardHalfPaddingVert = {
    paddingTop: standardFullPadding.paddingTop / 2,
    paddingBottom: standardFullPadding.paddingBottom / 2,
}
export const standardHalfPadding = {
    ...standardHalfPaddingHor,
    ...standardHalfPaddingVert,
}
export const standardHalfMarginHor = {
    marginLeft: standardHalfPadding.paddingLeft,
    marginRight: standardHalfPadding.paddingRight,
}
export const standardHalfMarginVert = {
    marginTop: standardHalfPadding.paddingTop,
    marginBottom: standardHalfPadding.paddingBottom,
}
export const standardHalfMargin = {
    ...standardHalfMarginHor,
    ...standardHalfMarginVert,
}

type ViewProps = {
    onPress?: () => void,
    onHoverStart?: () => void,
    onHoverEnd?: () => void,
    style?: CSSProperties,
    tooltip?: string,
}

export const View: React.FunctionComponent<ViewProps> = props => {
    React.useEffect(() => {
        if (props.tooltip !== undefined) {
            ReactTooltip.rebuild()
        }
    })

    return <div
        onClick={e => {
            if (props.onPress) {
                e.stopPropagation()
                props.onPress()
            }
        }}
        onMouseEnter={props.onHoverStart}
        onMouseLeave={props.onHoverEnd}
        style={{display: 'flex', flexDirection: 'column', overflow: 'visible', boxSizing: 'border-box', ...props.style}}
        // roba di ReactTooltip
        data-html={true}
        data-tip={props.tooltip === undefined ? undefined : '<p style="max-width: 25em">' + props.tooltip + '</p>'}
    >
        {props.children}
    </div>
}

export const ScrollView: typeof View = props =>
    <View {...props} style={{overflow: 'auto', ...props.style}}>{props.children}</View>

export type BaseTextProps = {string: string, style?: CSSProperties, tooltip?: string}

export const BaseText: React.FunctionComponent<BaseTextProps> = props =>
    <View tooltip={props.tooltip} style={{whiteSpace: 'pre-wrap', fontSize: normalFontSize, fontFamily: normalFontFamily, color: normalTextColor, ...props.style}}>
        <span>{
            props.string.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <br />}
                    {line}
                </React.Fragment>
            ))
        }</span>
    </View>

export const ContentText: React.FunctionComponent<BaseTextProps> = props =>
    <BaseText {...props} style={{...standardHalfMargin, color: normalTextColor, ...props.style}} />

// usato per i titoli degli input, magari si vuole mettere più margine sopra
export const ContentLabel = ContentText

export const ContentSeparator: React.FunctionComponent<{string?: string, rightElem?: ReactElement}> = props => {
    const hasString = props.string !== undefined && props.string !== ""
    return <View style={{marginTop: hasString ? standardHalfMargin.marginTop : undefined, flexDirection: 'row', alignItems: 'baseline'}}>
        {hasString && <ContentText string={props.string ?? ""} />}
        {props.rightElem}
        <View style={{...standardHalfMargin, flex: 1, borderStyle: 'solid', borderColor: palette.buttonDisabled, borderWidth: 1}} />
    </View>
}

export const VerticalContentSeparator: React.FunctionComponent = props =>
    <View style={{...standardHalfMargin, borderStyle: 'solid', borderWidth: 1, borderColor: palette.buttonDisabled}} />

export const WrapperLabel: React.FunctionComponent<{string: string, rightElem?: ReactElement, contentStyle?: CSSProperties, tooltip?: string}> = props =>
    <View style={{flexDirection: 'column', ...standardHalfMarginVert}}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <ContentLabel string={props.string} />
            {props.tooltip === undefined ? undefined : <TooltipIcon tooltip={props.tooltip} />}
            {props.rightElem}
        </View>
        <View style={props.contentStyle}>
            {props.children}
        </View>
    </View>

export const LabelBox: React.FunctionComponent<BaseTextProps & {highlighted?: boolean}> = props => {
    const color = props.highlighted ? palette.pageTitle : palette.borderDefault
    return <BaseText style={{
            ...standardHalfMargin,
            ...contentBorderStyleWithRadius,
            height: contentSingleLineHeight,
            ...standardFullPadding,
            borderColor: color,
            alignSelf: 'stretch',
            alignItems: 'center',
            justifyContent: 'center',
            color: color,
            textAlign: 'center',
            ...props.style,
        }} string={props.string} />
}

export const TitleText: React.FunctionComponent<BaseTextProps> = props =>
    <BaseText {...props} string={props.string.toUpperCase()} />

export const CardHeaderText: React.FunctionComponent<{titleString: string, style?: CSSProperties, tooltip?: string}> = props =>
    <TitleText tooltip={props.tooltip} string={props.titleString} style={{...standardHalfMargin, color: cardHeaderTextColor, fontSize: cardHeaderFontSize, fontWeight: 'bold', ...props.style}} />

export const ContentBigText: React.FunctionComponent<BaseTextProps> = props =>
    <CardHeaderText titleString={props.string} tooltip={props.tooltip} style={{color: palette.borderDefault, ...props.style}} />

export type CardProperties = {
    titleString?: string,
    containerStyle?: CSSProperties,
    headerItem?: React.ReactElement,
    tooltip?: string,
}

export const Card: React.FunctionComponent<CardProperties> = props =>
    <View style={{...standardHalfMargin,  ...standardHalfPadding, flexDirection: 'column', flexGrow: 1, boxShadow: cardBoxShadow, borderRadius: cardBorderRadius, backgroundColor: cardBgColor, ...props.containerStyle}}>
        {props.titleString !== undefined &&
            <React.Fragment>
                <View style={{flexDirection: 'row', flex: 1, alignItems: 'center', flexBasis: buttonHeight + standardSpaceVert}}> {/* height per contenere i bottoni */}
                    <CardHeaderText titleString={props.titleString} />
                    {props.tooltip !== undefined && <TooltipIcon tooltip={props.tooltip} style={{marginBottom: 3}} />}
                    <View style={{flexDirection: 'row', flex: 1, justifyContent: 'flex-end'}}>
                        {props.headerItem}
                    </View>
                </View>
                <ContentSeparator />
            </React.Fragment>
        }
        {props.children}
    </View>

type ButtonStateStyle = {
    bgColor: string,
    textColor: string,
}

type PressableAreaProps = ViewProps & {
    enabled?: boolean,
    hoveredStyle?: CSSProperties,
    disabledStyle?: CSSProperties,
}

export class PressableArea extends React.PureComponent<PressableAreaProps, {hovered: boolean}> {
    constructor(props: PressableAreaProps) {
        super(props)

        this.state = {
            hovered: false,
        }
    }

    render() {
        const {props, state} = this
        const showHover = props.enabled ?? props.onPress !== undefined
        return (
            <View
                {...props}
                onPress={props.enabled ?? true ? props.onPress : undefined}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: (props.enabled ?? true) && props.onPress ? 'pointer' : undefined,
                    ...props.style,
                    ...(!showHover ? props.disabledStyle : (state.hovered ? props.hoveredStyle : null))
                }}
                onHoverStart={() => {
                    this.setState({hovered: true})
                    if (props.onHoverStart) props.onHoverStart()
                }}
                onHoverEnd={() => {
                    this.setState({hovered: false})
                    if (props.onHoverEnd) props.onHoverEnd()
                }}
            />
        )
    }
}

type BaseButtonProps = {
    onPress: (() => void) | undefined,
    enabled?: boolean,
    label?: string,
    icon?: string | ReactElement,
    iconSize?: number,
    normalStyle?: ButtonStateStyle,
    hoveredStyle?: ButtonStateStyle,
    disabledStyle?: ButtonStateStyle,
    minWidth?: number,
    uppercaseOverride?: boolean,
}

class BaseButton extends React.PureComponent<BaseButtonProps, {hovered: boolean}> {
    constructor(props: BaseButtonProps) {
        super(props)

        this.state = {
            hovered: false,
        }
    }

    render() {
        const {props, state} = this
        const enabled = props.enabled ??  props.onPress !== undefined
        const textColor = !enabled ? props.disabledStyle?.textColor : state.hovered ? props.hoveredStyle?.textColor : props.normalStyle?.textColor
        const labelString = (props.uppercaseOverride ?? true) ? props.label?.toUpperCase() : props.label
        return (
            <PressableArea onPress={props.onPress} enabled={props.enabled} onHoverStart={() => this.setState({hovered: true})} onHoverEnd={() => this.setState({hovered: false})}
                style={{
                    alignSelf: 'flex-start',
                    boxShadow: enabled ? buttonBoxShadow : undefined,
                    ...standardHalfMargin,
                    ...standardHalfPadding,
                    height: buttonHeight,
                    minWidth: props.minWidth,
                    borderRadius: buttonBorderRadius,
                    backgroundColor: !enabled ? props.disabledStyle?.bgColor : state.hovered ? props.hoveredStyle?.bgColor : props.normalStyle?.bgColor,
                }}
            >
                {typeof props.icon === 'string' ? <BaseText style={{fontSize: props.iconSize ?? normalFontSize, ...standardHalfMargin, color: textColor}} string={props.icon} /> : props.icon}
                {labelString && <BaseText string={labelString} style={{fontSize: normalFontSize, color: textColor, ...standardHalfMargin}} />}
                {props.children}
            </PressableArea>
        )
    }
}

export const MainButton: React.FunctionComponent<BaseButtonProps & {primary?: boolean}> = props =>
    <BaseButton
        normalStyle={props.primary ? {bgColor: palette.buttonPrimary, textColor: palette.white} : {bgColor: palette.button, textColor: normalTextColor}}
        hoveredStyle={props.primary ? {bgColor: palette.pageTitle, textColor: normalTextColor} : {bgColor: palette.white, textColor: normalTextColor}}
        disabledStyle={{bgColor: palette.buttonDisabled, textColor: palette.borderDefault}}
        minWidth={buttonMainMinWidth}
        {...props}
    />

export const MenuButton: React.FunctionComponent<BaseButtonProps & {selected: boolean}> = props =>
    <BaseButton
        normalStyle={props.selected ? {bgColor: palette.buttonPrimary, textColor: palette.white} : {bgColor: normalTextColor, textColor: palette.white}}
        hoveredStyle={props.selected ? {bgColor: palette.pageTitle, textColor: normalTextColor} : {bgColor: palette.menuOver, textColor: palette.white}}
        disabledStyle={{bgColor: palette.buttonDisabled, textColor: palette.borderDefault}}
        minWidth={buttonMenuMinWidth}
        {...props}
    />

export const ContentButton: React.FunctionComponent<BaseButtonProps & {valid?: boolean}> = props =>
    <BaseButton
        normalStyle={{bgColor: (props.valid ?? true) ? palette.button : palette.errorBg, textColor: normalTextColor}}
        hoveredStyle={{bgColor: palette.pageBg, textColor: normalTextColor}}
        disabledStyle={{bgColor: palette.buttonDisabled, textColor: palette.borderDefault}}
        minWidth={props.label ? buttonContentMinWidth : buttonHeight}
        {...props}
    />

type TextInputProps = {
    value: string,
    onChangeText: (text: string) => void,
    multiline?: boolean,
    valid?: boolean,
    style?: CSSProperties,
    placeholder?: string,
    editable?: boolean,
    icon?: string | ReactElement,
    onChangeFocus?: (focused: boolean) => void,
}

export const InputRightIconCell: React.FunctionComponent<{icon: string | ReactElement, color: string}> = props =>
    <View style={{width: inputIconCellWidth, alignSelf: 'stretch', borderLeftStyle: 'solid', borderColor: props.color, borderWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
        {typeof props.icon === 'string'
            ? <BaseText string={props.icon} style={{fontSize: 1.25 * normalFontSize, color: props.color}} />
            : props.icon}
    </View>

export const ContentTextInput: React.FunctionComponent<TextInputProps> = props => {
    const [focused, setFocused] = React.useState(false)
    const [hovered, setHovered] = React.useState(false)
    const readOnly = props.editable !== undefined ? !props.editable : false
    const borderColor =
        readOnly ? palette.buttonDisabled :
        focused ? palette.borderFocus :
        hovered ? palette.borderFocus :
        palette.borderDefault
    const textColor =
        readOnly ? disabledTextColor :
        (props.valid ?? true) ? normalTextColor :
        palette.errorText
    const inputStyle: CSSProperties = {
        ...(props.multiline ? standardFullPadding : {paddingLeft: standardFullPadding.paddingLeft, paddingRight: standardFullPadding.paddingRight}),
        fontSize: normalFontSize,
        fontFamily: normalFontFamily,
        color: textColor,
        boxSizing: 'border-box',
        borderStyle: 'none',
        borderRadius: contentBorderStyleWithRadius.borderRadius, // perchè "overflow: hidden" non si sa perchè qui non va
        backgroundColor: 'transparent',
        width: props.style?.width,
    }
    const containerStyle = {
        ...standardHalfMargin,
        ...contentBorderStyleWithRadius,
        borderColor: borderColor,
        backgroundColor: (props.valid ?? true) ? ((focused && !readOnly) ? palette.textInputFocus : palette.white) : palette.errorBg,
        height: props.multiline ? 90 : contentSingleLineHeight,
        alignItems: 'stretch',
        ...props.style,
    }
    const inputProps = {
        value: props.value,
        onChange: (ev: any) => props.onChangeText(ev.target.value),
        placeholder: props.placeholder,
        readOnly: readOnly,
        onFocus: () => {
            setFocused(true)
            if (props.onChangeFocus) props.onChangeFocus(true)
        },
        onBlur: () => {
            setFocused(false)
            if (props.onChangeFocus) props.onChangeFocus(false)
        },
        onMouseEnter: () => setHovered(true),
        onMouseLeave: () => setHovered(false),
    }
    return props.multiline ?
        <textarea {...inputProps} style={{resize: 'block', ...inputStyle, ...containerStyle}} />
    :
        <View style={{flexGrow: 1, flexDirection: 'row', ...containerStyle}} onPress={() => {}}> {/* imposta onPress in modo che non si propaghi l'evento, ad esempio per le tabelle foldabili */}
            <input {...inputProps} style={{minWidth: 0, height: contentSingleLineHeight - 2 * (contentBorderStyle.borderWidth as number), flex: 1, ...inputStyle}} />
            {props.icon && <InputRightIconCell icon={props.icon} color={borderColor} />}
        </View>
}

type PickerProps = {
    items: {label: string, value: string}[],
    selectedValue: string,
    onValueChange: (value: string) => void,
    style?: CSSProperties,
    editable?: boolean,
    onChangeFocus?: (focus: boolean) => void,
}

export const ContentPicker: React.FunctionComponent<PickerProps> = props => {
    const [hovered, setHovered] = useState(false)
    const readOnly = !(props.editable ?? true)
    const borderColor = readOnly ? palette.buttonDisabled : hovered ? palette.borderFocus : palette.borderDefault
    const textColor = readOnly ? disabledTextColor : normalTextColor
    const clearStyle: CSSProperties = {
        appearance: 'none',
        WebkitAppearance: 'none',
        MozAppearance: 'none',
    }
    return <View style={{position: 'relative'}}>
        <select style={{
            width: 'calc(100% - ' + standardSpaceHor + 'px)', // se no fa allargare il contenitore se il testo delle option è più largo
            ...standardHalfMargin,
            paddingLeft: standardHalfPadding.paddingLeft,
            paddingRight: standardHalfPadding.paddingRight + inputIconCellWidth,
            ...contentBorderStyleWithRadius,
            borderColor: borderColor,
            height: contentSingleLineHeight,
            backgroundColor: palette.white,
            color: textColor,
            fontFamily: normalFontFamily,
            fontSize: normalFontSize,
            ...clearStyle,
            ...props.style}}
            value={props.selectedValue} onChange={ev => props.onValueChange(ev.target.value)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            disabled={readOnly}
            onFocus={() => props.onChangeFocus && props.onChangeFocus(true)}
            onBlur={() => props.onChangeFocus && props.onChangeFocus(false)}
        >
            {props.items.map(item =>
                <option value={item.value} key={item.value}>
                    {item.label}
                </option>)}
        </select>
        <span style={{position: 'absolute', fontFamily: iconFontFamily, fontSize: normalFontSize * 3, right: standardHalfMargin.marginRight * 0.25, top: -standardHalfMargin.marginTop * 0.75, color: borderColor, pointerEvents: 'none'}}></span>
        <View style={{position: 'absolute', right: standardHalfMargin.marginRight + inputIconCellWidth, top: standardHalfMargin.marginTop, height: contentSingleLineHeight, borderLeftStyle: 'solid', borderWidth: 1, borderColor: borderColor}} />
    </View>
}

export const InputLikePressableArea: React.FunctionComponent<{
        onPress: () => void,
        enabled?: boolean,
        valid?: boolean,
        string?: string,
        icon: string | ReactElement,
        containerStyle?: CSSProperties}> = props => {
    const [hovered, setHovered] = React.useState(false)

    const enabled = props.enabled ?? true
    const valid = props.valid ?? true
    const borderColor = !enabled ? palette.buttonDisabled : hovered ? palette.borderFocus : palette.borderDefault
    const textColor = !enabled ? disabledTextColor : !valid ? palette.errorText : normalTextColor
    const bgColor = !valid ? palette.errorBg : palette.white
    return <PressableArea
            onPress={enabled ? props.onPress : undefined}
            style={{flexDirection: 'row', alignItems: 'center', ...standardHalfMargin, ...contentBorderStyleWithRadius, borderColor: borderColor, backgroundColor: bgColor, height: contentSingleLineHeight, ...props.containerStyle}}
            onHoverStart={() => setHovered(true)}
            onHoverEnd={() => setHovered(false)}
            >
        <View style={{...standardHalfPaddingHor, flex: 1}}>
            {!props.string ? props.children : <ContentText string={props.string} style={{color: textColor}} />}
        </View>
        <View style={{...contentBorderStyle, borderTopStyle: 'none', borderBottomStyle: 'none', borderRightStyle: 'none', borderColor: borderColor, width: inputIconCellWidth, alignSelf: 'stretch', alignItems: 'center', justifyContent: 'center'}}>
            {typeof props.icon === 'string' ? <BaseText string={props.icon} style={{color: textColor}} /> : props.icon }
        </View>
    </PressableArea>
}

type CheckboxProps = {string: string, checked: boolean, onPress: (wasChecked: boolean) => void, enabled?: boolean, style?: CSSProperties, textStyle?: CSSProperties, tooltip?: string}

const BaseCheckbox: React.FunctionComponent<CheckboxProps & {round: boolean}> = props => {
    const enabled = props.enabled ?? true
    const textColor = enabled ? normalTextColor : disabledTextColor
    const borderColor = enabled ? palette.borderDefault : palette.buttonDisabled
    const checkColor = enabled ? palette.borderFocus : textColor
    return <PressableArea onPress={enabled ? (() => props.onPress(props.checked)) : undefined} style={{...standardHalfMargin, padding: 0, justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center', ...props.style}}>
        <View style={{...contentBorderStyle, width: 16, height: 16, borderRadius: props.round ? 10 : 2, alignItems: 'center', justifyContent: 'center', color: borderColor}}>
            {props.checked && <View style={{width: 10, height: 10, borderRadius: props.round ? 5 : 1, backgroundColor: checkColor}} />}
        </View>
        <BaseText string={props.string} style={{color: textColor, marginLeft: standardHalfMargin.marginLeft, marginRight: standardHalfMargin.marginRight, ...props.textStyle}} />
        {props.tooltip !== undefined && <TooltipIcon tooltip={props.tooltip} />}
    </PressableArea>
}

export const RadioCheckbox: React.FunctionComponent<CheckboxProps> = props =>
    <BaseCheckbox round={true} {...props} />

export const SquareCheckbox: React.FunctionComponent<CheckboxProps> = props =>
    <BaseCheckbox round={false} {...props} />

export const BaseImage: React.FunctionComponent<{path: string, style: CSSProperties}> = props =>
    <img src={imageSource(props.path)} style={props.style} alt={"<not loaded>"} />

export const ContentImage: typeof BaseImage = props =>
    <BaseImage {...props} style={{...standardHalfPadding, ...props.style}} />