import { getLocale } from '../baseUtils'
import { LocStrings } from './def'

import enStrings from './en_strings'
import itStrings from './it_strings'
import { KpiAggregationPolicyId, GoalVisibilityPolicyId, GoalPhaseId } from '../backendTypes'
import { customSettings } from '../config'
import produce from 'immer'

export type Language = 'en' | 'it'

const locStrings: LocStrings = (() => {
    const lang = getLocale().split('-')[0]
    const strings =
        lang === 'en' ? enStrings :
        lang === 'it' ? itStrings :
        null
    if (!strings) throw new Error("could not find localization for language " + lang)

    // override da config
    const overridePairs = customSettings.locStringsOverride[lang as Language]
    const stringsWithOverride = !overridePairs
        ? strings
        : produce(strings, strs => {
            overridePairs.forEach(([key, val]) => {
                strs[key] = val
            })
        })

    return stringsWithOverride
})()

export const LS = (key: keyof LocStrings): string => locStrings[key]

export const LSl: typeof LS = key => {
    const str = LS(key)
    return str[0].toLocaleLowerCase() + str.substr(1)
}

export const locAggregationPolicy = (aggr: KpiAggregationPolicyId): string =>
    aggr === KpiAggregationPolicyId.Average ? LS('kpiAggrAverage') :
    aggr === KpiAggregationPolicyId.Minimum ? LS('kpiAggrMinimum') :
    "(could not localize aggregation)"

export const locVisibilityPolicy = (vis: GoalVisibilityPolicyId): string =>
    vis === GoalVisibilityPolicyId.Standard ? LS('goalVisStandard') :
    vis === GoalVisibilityPolicyId.Public ? LS('goalVisPublic') :
    vis === GoalVisibilityPolicyId.Private ? LS('goalVisPrivate') :
    "(could not localize visibility)"

export const locKpiType = (scalar: boolean, linear: boolean): string =>
    LS(scalar ?
        linear ?
            'kpiTypeQuantitativeContinuous' :
            'kpiTypeQuantitativeThreshold' :
        'kpiTypeQualitative')

export const locGoalPhase = (pId: GoalPhaseId): string =>
    pId === GoalPhaseId.Unapproved ? LS('goalPhaseUnapproved') :
    pId === GoalPhaseId.Open ? LS('goalPhaseOpen') :
    pId === GoalPhaseId.Closed ? LS('goalPhaseClose') :
    "(could not localize goal phase)"