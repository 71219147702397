import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../state'
import { ActionType, AffectInfluences, CreateInfluences } from '../actions'
import { getRootGoals, getTeams, getterInfluencesFromCollaboratorId, getterMembershipString, getUserPerson, getVisibleInfluences, makeCanPersonEditOrDeleteInfluence, makeGetInfluenceFromRef, makeIsLegacyTeamManagement, makeIsLegacyTeamMembership } from '../selectors'
import { Card, closedGoalOpacity, ContentButton, ContentSeparator, ContentText, ContentTextInput, SquareCheckbox, View } from '../styles'
import ScreenWrapper from '../components/ScreenWrapper'
import { LS } from '../loc/loc'
import { flattenArray, makeShowListSelectorScreenTyped, membershipsEqual, numberToLocString, personHasPlatformRole, sortedBy, StringifyMap, StringifySet, stringToNumber } from '../utils'
import produce from 'immer'
import { GoalInstanceComplete, InfluenceRef, PlatformRoleId, Team, TeamMembership } from '../backendTypes'
import { FoldableTable, FoldableTableProps } from '../components/Table'
import { CSSProperties } from 'hoist-non-react-statics/node_modules/@types/react'

const connector = connect((s: RootState) => ({
    getMembershipString: getterMembershipString(s),
    userPerson: getUserPerson(s),
    visibleInfluences: getVisibleInfluences(s),
    rootGoals: getRootGoals(s),
    teams: getTeams(s),
    getInfluencesFromCollaboratorId: getterInfluencesFromCollaboratorId(s),
    getInfluenceFromRef: makeGetInfluenceFromRef(s),
    canPersonEditOrDeleteInfluence: makeCanPersonEditOrDeleteInfluence(s),
    isLegacyTeamManagement: makeIsLegacyTeamManagement(s),
    isLegacyTeamMembership: makeIsLegacyTeamMembership(s),
}), (dispatch) => ({
    dispatch,
    showListSelectorScreenTyped: makeShowListSelectorScreenTyped(dispatch),
}))

const CreateInfluenceScreen: React.FunctionComponent<ConnectedProps<typeof connector>> = props => {
    type TeamAndManager = { team: Team, manager: TeamMembership }
    type AssigneeAndManager = { assignee: TeamMembership, manager: TeamMembership }
    const assigneeAndManagerEqual = (a: AssigneeAndManager, b: AssigneeAndManager) =>
        membershipsEqual(a.assignee, b.assignee) && membershipsEqual(a.manager, b.manager)

    const [goal, setGoal] = React.useState(null as (GoalInstanceComplete | null))
    const [checkedAssignees, setCheckedAssignees] = React.useState(new StringifySet<AssigneeAndManager>())
    const [weightsStr, setWeightsStr] = React.useState(new StringifyMap<AssigneeAndManager, string>())

    const privileged = personHasPlatformRole(props.userPerson, PlatformRoleId.Privileged)

    const teamsAndManagers = flattenArray(
        props.teams.map(t => t.managers
            .filter(m => privileged || m.personId === props.userPerson.id)
            .map(m => ({team: t, manager: m}))))

    const getInfluenceFromAssigneeAndManager = (goal: GoalInstanceComplete, am: AssigneeAndManager) =>
        props.getInfluenceFromRef({
            goalId: goal.id,
            collaboratorMembership: am.assignee,
            managerMembership: am.manager,
        })
    
    const getExistingInfluenceAssigneesAndManagers = (g: GoalInstanceComplete) => {
        const allPossibleAssigneesAndManagers: AssigneeAndManager[] = flattenArray(teamsAndManagers.map(({team, manager}) =>
            team.memberIds.map(collId => ({assignee: {personId: collId, teamId: team.id}, manager: manager}))))
        return allPossibleAssigneesAndManagers.filter(am => undefined !== getInfluenceFromAssigneeAndManager(g, am))
    }

    const canEditAssigneeAndManager = (am: AssigneeAndManager): boolean => {
        if (goal === null) throw new Error()
        const infl = getInfluenceFromAssigneeAndManager(goal, am)
        return infl === undefined ? true : props.canPersonEditOrDeleteInfluence(props.userPerson, infl)
    }

    const goalSelected = (g: GoalInstanceComplete) => {
        setGoal(g)

        const initialCheckedAssignees = getExistingInfluenceAssigneesAndManagers(g)
        setCheckedAssignees(new StringifySet(initialCheckedAssignees))

        const initialWeightsStr: [AssigneeAndManager, string][] = initialCheckedAssignees.map(am => {
            const infl = getInfluenceFromAssigneeAndManager(g, am)
            return [am, infl === undefined ? '' : numberToLocString(infl.weight)]
        })
        setWeightsStr(new StringifyMap(initialWeightsStr))
    }

    const weightsMap = new StringifyMap(checkedAssignees.toArray()
        .map(am => [am, stringToNumber(weightsStr.get(am) ?? '')]))

    const existingAms = goal ? getExistingInfluenceAssigneesAndManagers(goal) : []
    const checkedAmsArray = checkedAssignees.toArray();

    const toDelete = existingAms.filter(am => undefined === checkedAmsArray.find(am2 => assigneeAndManagerEqual(am, am2)))
    const toCreate = checkedAmsArray.filter(am => undefined === existingAms.find(am2 => assigneeAndManagerEqual(am, am2)))
    const toEdit = checkedAmsArray.filter(am => undefined !== existingAms.find(am2 => {
        const existingInfluence = props.getInfluenceFromRef({goalId: goal?.id ?? '', collaboratorMembership: am.assignee, managerMembership: am.manager})
        return assigneeAndManagerEqual(am, am2) &&
            goal !== null && weightsMap.get(am) !== existingInfluence?.weight
    }))
    console.log(toDelete.length, toCreate.length, toEdit.length)
    
    const assigneesTopItems = goal === null ? [] : teamsAndManagers.map(tm => ({
        outer: tm,
        inners: tm.team.memberIds
            .map(pId => ({assignee: {personId: pId, teamId: tm.team.id}, manager: tm.manager}))
    })).filter(top => top.inners.length > 0)
    
    const inputValid = {
        goal: goal !== null,
        assignments: toDelete.length > 0 || toCreate.length > 0 || toEdit.length > 0,
        weightValues: new StringifyMap(weightsMap.keysArray().map(am => {
            const w = weightsMap.get(am)
            return [am, w !== undefined && w !== null && w > 0]
        })),
    }
    const allInputsValid = Object.values(inputValid).every(v => typeof v === 'object' ?
        v.valuesArray().every(v2 => v2) :
        v)

    const assigneesTableProps: FoldableTableProps<TeamAndManager, AssigneeAndManager> = {
        columnNames: [LS('team'), LS('manager'), LS('collaborator')],
        columnWidths: [1, 1, 1],
        showFilters: [true, true, true],
        topItemToFilterStrings: [null, null, _ => ''],
        innerToFilterStrings: [
            (_, top) => top.outer.team.name,
            (_, top) => props.getMembershipString(top.outer.manager),
            inner => props.getMembershipString(inner.assignee, '@n')
        ],
        topItems: assigneesTopItems,
        topItemToRow: top => {
            const style: CSSProperties | undefined = props.isLegacyTeamManagement(top.outer.manager, top.outer.team.id)
                ? {opacity: closedGoalOpacity}
                : undefined
            return [
                <ContentText string={top.outer.team.name} style={style} />,
                <ContentText string={props.getMembershipString(top.outer.manager)} style={style} />,
                <SquareCheckbox string={top.inners.filter(am => checkedAssignees.has(am)).length + ' / ' + top.inners.length}
                    enabled={top.inners.some(canEditAssigneeAndManager)}
                    checked={top.inners.filter(canEditAssigneeAndManager).every(am => checkedAssignees.has(am))} onPress={wasChecked => {
                        setCheckedAssignees(produce(checkedAssignees, newCheckedAssignees => {
                            top.inners.filter(canEditAssigneeAndManager).forEach(am => {
                                if (!wasChecked) {
                                    newCheckedAssignees.add(am)
                                } else {
                                    newCheckedAssignees.delete(am)
                                }
                            })
                        }))
                    }} />,
            ]
        },
        innerToRow: am => {
            const style: CSSProperties | undefined = props.isLegacyTeamMembership(am.assignee)
                ? {opacity: closedGoalOpacity}
                : undefined
            return [
                '',
                '',
                <SquareCheckbox string={props.getMembershipString(am.assignee, '@n')} checked={checkedAssignees.has(am)} onPress={wasChecked => {
                    setCheckedAssignees(produce(checkedAssignees, newCheckedAssignees => {
                        if (!wasChecked) {
                            newCheckedAssignees.add(am)
                        } else {
                            newCheckedAssignees.delete(am)
                        }
                    }))
                }} enabled={canEditAssigneeAndManager(am)} textStyle={style} />
            ]
        },
    }
    const influencesTableProps: FoldableTableProps<TeamAndManager, AssigneeAndManager> = {
        columnNames: [LS('team'), LS('manager'), LS('collaborator'), LS('credits')],
        columnWidths: [2, 2, 1.5, 0.5],
        topItems: assigneesTopItems.filter(top => top.inners.some(am => checkedAssignees.has(am))).map(top => ({outer: top.outer, inners: top.inners.filter(am => checkedAssignees.has(am))})),
        topItemToRow: top => [
            top.outer.team.name,
            props.getMembershipString(top.outer.manager),
            '',
            <ContentTextInput
                editable={top.inners.some(canEditAssigneeAndManager)}
                value={top.inners.filter(canEditAssigneeAndManager).every(am => weightsStr.get(am) === weightsStr.get(top.inners[0])) ? weightsStr.get(top.inners[0]) ?? '' : ''}
                onChangeText={str => {
                    setWeightsStr(produce(weightsStr, newWeightsStr => {
                        top.inners.filter(canEditAssigneeAndManager).forEach(am => newWeightsStr.set(am, str))
                    }))
                }}
            />,
        ],
        innerToRow: am => [
            '',
            '',
            props.getMembershipString(am.assignee, '@n'),
            <ContentTextInput value={weightsStr.get(am) ?? ''} onChangeText={text => {
                setWeightsStr(produce(weightsStr, newWeightsStr => {
                    newWeightsStr.set(am, text)
                }))
            }} valid={inputValid.weightValues.get(am) ?? false} editable={canEditAssigneeAndManager(am)} />,
        ],
        expandedByDefault: true,
    }

    const onOkPressed = () => {
        if (goal === null) throw new Error()

        const amToInflRef = (am: AssigneeAndManager): InfluenceRef => ({
            goalId: goal.id,
            managerMembership: { personId: am.manager.personId, teamId: am.manager.teamId },
            collaboratorMembership: { personId: am.assignee.personId, teamId: am.assignee.teamId },
        })
        const action: AffectInfluences = {
            type: ActionType.AFFECT_INFLUENCES,
            toDelete: toDelete.map(amToInflRef),
            toCreate: toCreate.map(am => ({...amToInflRef(am), weight: weightsMap.get(am) ?? 0})),
            toEdit: toEdit.map(am => ({...amToInflRef(am), weight: weightsMap.get(am) ?? 0})),
        }
        props.dispatch(action)
    }

    return <ScreenWrapper headerItem={
            <View style={{flexDirection: 'row'}}>
                <ContentButton label={LS('ok')} onPress={onOkPressed} enabled={allInputsValid} />
                <ContentButton label={LS('cancel')} onPress={() => props.dispatch({type: ActionType.NAVIGATION_POP})} />
            </View>
        }>
        <View>
            <Card>
                <ContentSeparator string={LS('rootGoal')} />
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        {goal && <ContentText string={goal.title} />}
                        <ContentButton label={LS('select')} onPress={() => props.showListSelectorScreenTyped(
                            LS('selectRootGoal'),
                            [LS('title')],
                            undefined,
                            sortedBy(props.rootGoals, g => g.title),
                            g => [g.title],
                            false,
                            gs => goalSelected(gs[0])
                        )} valid={inputValid.goal} />
                    </View>
                <ContentSeparator string={LS('recipients')} />
                <FoldableTable {...assigneesTableProps} containerStyle={{maxHeight: 360}} />
                <ContentSeparator string={LS('influences')} />
                <FoldableTable {...influencesTableProps} />
            </Card>
        </View>
    </ScreenWrapper>
}

export default connector(CreateInfluenceScreen)