import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Person } from '../backendTypes'
import { LS } from '../loc/loc'
import { getterMembershipString, getAllManagerPersonsWithLegacy } from '../selectors'
import { RootState } from '../state'
import { ContentButton, ContentLabel, ContentText, View, WrapperLabel } from '../styles'
import { makeShowListSelectorScreenTyped, sortedByMany } from '../utils'
import BaseGoalsByPersonTable from './BaseGoalsByPersonTable'

const connector = connect((s: RootState) => ({
    getMembershipString: getterMembershipString(s),
    allManagerPersonsWithLegacy: getAllManagerPersonsWithLegacy(s),
}), dispatch => ({
    showListSelectorScreenTyped: makeShowListSelectorScreenTyped(dispatch),
}))

const GoalsByManager:  React.FunctionComponent<ConnectedProps<typeof connector>> = props => {
    const [managerPerson, setManagerPerson] = React.useState(null as Person | null)
    const allManagersSorted = sortedByMany(props.allManagerPersonsWithLegacy, [[p => p.lastName, 'asc'], [p => p.firstName, 'asc']])
    const showManagerSelection = () => props.showListSelectorScreenTyped(
        LS('selectManager'),
        [LS('manager')],
        undefined,
        allManagersSorted,
        p => [p.firstName + " " + p.lastName],
        false,
        selPersons => setManagerPerson(selPersons[0])
    )
    return <View style={{flexDirection: 'column'}}>
        <WrapperLabel string={LS('manager')}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                {managerPerson !== null && <ContentText string={managerPerson.firstName + ' ' + managerPerson.lastName} />}
                <ContentButton label={LS('select')} onPress={showManagerSelection} />
            </View>
        </WrapperLabel>
        {managerPerson !== null && <BaseGoalsByPersonTable managerId={managerPerson.id} />}
    </View>
}

export default connector(GoalsByManager)