import React, { CSSProperties } from 'react'
import {connect, ConnectedProps} from 'react-redux'
import { Dispatch } from 'redux'
import { ActionType, AppAction, NavigationPop } from '../actions'
import { GoalInstanceComplete, GoalPhaseId, PlatformRoleId, TeamMembership } from '../backendTypes'
import ScreenWrapper from '../components/ScreenWrapper'
import { TableTyped } from '../components/Table'
import { LS } from '../loc/loc'
import { getTeamsById, makeGetTeamsFromMemberId, getterMembershipString, getUserPerson, makeGetPossibleParentGoalsForManager, makeUserHasRole, makeGetPossibleParentGoalsForAssignee, makeGetManagerMembershipsOfPerson } from '../selectors'
import { RootState } from '../state'
import { Card, closedGoalOpacity, ContentButton, ContentText, getTextStyleForGoal, View } from '../styles'
import { IdType, makeShowListSelectorScreenTyped, membershipsEqual, sortedByMany } from '../utils'
import { GoalUserMode } from './CreateGoalScreen'

const connector = connect((s: RootState) => ({
    userPerson: getUserPerson(s),
    userHasRole: makeUserHasRole(s),
    getMembershipString: getterMembershipString(s),
    getPossibleParentsForManager: makeGetPossibleParentGoalsForManager(s),
    getPossibleParentGoalsForAssignee: makeGetPossibleParentGoalsForAssignee(s),
    getManagerMembershipsOfPerson: makeGetManagerMembershipsOfPerson(s),
    getTeamsFromMemberId: makeGetTeamsFromMemberId(s),
}), (dispatch: Dispatch<AppAction>) => ({
    dispatch,
    showListSelectorScreenTyped: makeShowListSelectorScreenTyped(dispatch),
}))

export type SelectParentScreenExternalProps = {
    userMode: GoalUserMode,
    onConfirm: (goalId: IdType, managerMembership: TeamMembership) => void,
}

type ComponentProps = ConnectedProps<typeof connector> & SelectParentScreenExternalProps

const SelectParentScreen: React.FunctionComponent<ComponentProps> = props => {
    const possibleParents = sortedByMany(
        props.userMode === GoalUserMode.Manager
            ? props.getPossibleParentsForManager(props.userPerson)
            : props.getPossibleParentGoalsForAssignee(props.userPerson),
        [[g => g.phaseId === GoalPhaseId.Closed, 'asc'], [g => g.title, 'asc']])

    const assigned = possibleParents.filter(g => !g.isRoot)
    const roots = possibleParents.filter(g => g.isRoot)

    const onPressCancel = () => {
        const action: NavigationPop = {
            type: ActionType.NAVIGATION_POP,
        }
        props.dispatch(action)
    }
    const getAssigneesString = (g: GoalInstanceComplete) => {
        const numShownAss = 2
        const assigneesString = g.assigneePersons.slice(0, numShownAss)
            .map(m => props.getMembershipString(m)).join("\n")
            + (g.assigneePersons.length > numShownAss ? " (...)" : "")
        return assigneesString
    }
    const makeGoalsTable = (goals: GoalInstanceComplete[]) =>
        <TableTyped
            items={goals}
            columnNames={[LS('title'), LS('assignees')]}
            itemToRow={g => {
                const rowStyle = getTextStyleForGoal(g)
                return [<ContentText string={g.title} style={rowStyle} />, <ContentText string={getAssigneesString(g)} style={rowStyle} />]
            }}
            onPressItem={g => {
                const managersOfUser = props.getManagerMembershipsOfPerson(props.userPerson, false)
                const membershipsOfUser = props.getTeamsFromMemberId(props.userPerson.id).map(t => {
                    const memb: TeamMembership = {
                        personId: props.userPerson.id,
                        teamId: t.id,
                    }
                    return memb
                })
                const possibleManagers =
                    props.userMode === GoalUserMode.Manager ? g.isRoot
                        ? membershipsOfUser
                        : g.assigneePersons.filter(m => m.personId === props.userPerson.id)
                    : props.userMode === GoalUserMode.Assignee ? g.isRoot
                        ? managersOfUser
                        : g.assigneePersons.filter(m => 0 <= managersOfUser.findIndex(m2 => membershipsEqual(m ,m2)))
                    : []
                if (possibleManagers.length > 1) {
                    props.showListSelectorScreenTyped(
                        LS('selectManager'),
                        [LS('name'), LS('team')],
                        undefined,
                        possibleManagers,
                        m => ([props.getMembershipString(m, '@n'), props.getMembershipString(m, '@t')]),
                        false,
                        selItems => {
                            props.onConfirm(g.id, selItems[0])
                            props.dispatch({type: ActionType.NAVIGATION_POP})
                        })
                } else if (possibleManagers.length === 1) {
                    props.onConfirm(g.id, possibleManagers[0])
                    props.dispatch({type: ActionType.NAVIGATION_POP})
                } else {
                    throw new Error("nessun manager possibile")
                }
            }}
        />
    const headerItem = <>
        <ContentButton label={LS('cancel')} onPress={onPressCancel} />
    </>
    return (
        <ScreenWrapper headerItem={headerItem}>
            <View style={{flexDirection: 'column'}}>
                <Card titleString={
                        props.userMode === GoalUserMode.Manager ? LS('myGoals') :
                        props.userMode === GoalUserMode.Assignee ? LS('goalsOfTheManager') :
                        '-'}>
                    {makeGoalsTable(assigned)}
                </Card>
                <Card titleString={LS('rootGoals')}>
                    {makeGoalsTable(roots)}
                </Card>
            </View>
        </ScreenWrapper>
    )
}

export default connector(SelectParentScreen)