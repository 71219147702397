import { Record } from 'immutable'
import { ResultCode, GoalVisibilityPolicyId, Person, PlatformRoleId, TeamMembership, TimePeriod, SampleCollectionInstance, SampleInstance, SampleCollectionDefinition, GoalInstanceComplete, KpiInstance, KpiThreshold, KpiAggregationPolicyId, KpiDefinition, InfluenceRef, InfluenceDefinition, GoalPhaseId, GoalDefinition, InfluenceInstance } from './backendTypes'
import { Dispatch } from 'react'
import { Action } from 'redux'
import { ListSelectorScreenProps } from './screens/ListSelectorScreen'
import { NavigationPush, ActionType } from './actions'
import { ScreenRouteId } from './routing'
import produce, { immerable } from 'immer'
import { getPlatform, getLocale } from './baseUtils'
import { likertMax, likertMin, likertReached, likertStep } from './screens/CreateKpiScreen'
import stableStringify from 'json-stable-stringify'
import packageJson from '../package.json'
import ReactTooltip from 'react-tooltip'

export * from './dateUtils'

export const getVersionString = () => packageJson.version

export const mkRec = <T>(obj: T) => Record(obj)()

export enum InternalResultCode {
    AuthenticationFailed = "authentication_failed",
    FetchFailed = "fetch_failed",
    BackendReturnedFailure = "backend_error",
}

export type ResultCodeAndDetails = {
    internalCode: InternalResultCode.AuthenticationFailed,
} | {
    internalCode: InternalResultCode.FetchFailed,
} | {
    internalCode: InternalResultCode.BackendReturnedFailure,
    backendCode: ResultCode,
}

export type IdType = string

export const imageSource = (fileName: string) =>
    require('./assets/' + fileName)

export const makeShowListSelectorScreen =
    (dispatch: Dispatch<Action<any>>) =>
        (title: string, props: ListSelectorScreenProps) => {
            const pushAction: NavigationPush = {
                type: ActionType.NAVIGATION_PUSH,
                stackItem: {
                    routeId: ScreenRouteId.ListSelector,
                    title: title,
                    props: props,
                }
            }
            dispatch(pushAction)
        }

export const makeShowListSelectorScreenTyped = (dispatch: Dispatch<Action<any>>) => {
    const showListSelectorScreen = makeShowListSelectorScreen(dispatch)
    return <T>(title: string, columnNames: string[], columnWidths: number[] | undefined, items: T[], itemToRow: (item: T) => string[], multiSelection: boolean, onConfirm: (selItems: T[]) => void) => {
        const onConfirmTyped = (indices: number[]): void => {
            const selectedItems = indices.map(i => items[i])
            onConfirm(selectedItems)
        }
        showListSelectorScreen(title, {
            columnNames,
            columnWidths,
            items: items.map(itemToRow),
            multiSelection,
            onConfirm: onConfirmTyped,
        })
    }
}

export const getEnabledGoalVisibilityIds = () => [GoalVisibilityPolicyId.Standard, GoalVisibilityPolicyId.Private]

export const intEnumValues = <T>(e: object): T[] => Object.values(e).filter(v => typeof v === 'number')
export const intEnumString = <T>(e: object, v: T): string => (e as any)[v]
export const intEnumAllStrings = (e: object): string[] => Object.values(e).filter(v => typeof v === 'number').map(v => (e as any)[v])
export const intEnumValueFromString = <T>(e: object, s: string): T => (e as any)[s]

export const updateState = <C extends React.Component<any, S>, S, T>(comp: C, changer: (s: S, val: T) => void, val: T) => {
    comp.setState(produce(comp.state, s => changer(s as S, val)))
}

export const isStringValid = (str: string, minLen: number, maxLen: number): boolean =>
    str !== null && str !== undefined && str.length >= minLen && str.length <= maxLen

const decSep = (1.1).toLocaleString(getLocale())[1]

export const numberToLocString = (n: number, decDigits?: number): string =>
    (decDigits === undefined ? n.toString() : n.toFixed(decDigits)).replace('.', decSep)

export const stringToNumber = (str: string): number | null => {
    const res = new RegExp("^(-?)(\\d+)(\\" + decSep + "(\\d+))?$").exec(str)
    if (res === null) return null
    const [, sign, intStr, , fracStr] = res
    const num = Number.parseFloat(sign + intStr + '.' + fracStr)
    return Number.isFinite(num) ? num : null
}

export const stringToNumberNotNull = (str: string): number => {
    const res = stringToNumber(str)
    if (res === null) throw new Error("'" + str + "' cannot be parsed to number")
    return res
}

export const getSignificantDigitsNum = (n: number): number => {
    n = Math.abs(Number.parseInt(String(n).replace(".", ""))); //remove decimal and make positive
    if (n === 0) return 0;
    while (n !== 0 && n % 10 === 0) n /= 10; //kill the 0s at the end of n
    return Math.floor(Math.log(n) / Math.log(10)) + 1; //get number of digits
}

export const arraysEqual = (a: Array<any>, b: Array<any>) => {
    if (a === b) return true;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export const personHasPlatformRole = (person: Person, role: PlatformRoleId): boolean =>
    person.platformRoleIds.findIndex(r => r === role) >= 0

export const membershipsEqual = (m1: TeamMembership, m2: TeamMembership): boolean =>
    m1.personId === m2.personId && m1.teamId === m2.teamId

export const membershipsNullableEqual = (m1: TeamMembership | null, m2: TeamMembership | null): boolean =>
    (m1 === null && m2 === null) ||
    ((m1 !== null && m2 !== null) && (m1.personId === m2.personId && m1.teamId === m2.teamId))

export const influenceRefsEqual = (a: InfluenceRef, b: InfluenceRef): boolean =>
    a.goalId === b.goalId &&
    membershipsEqual(a.collaboratorMembership, b.collaboratorMembership) &&
    membershipsEqual(a.managerMembership, b.managerMembership)

export const influenceDefinitionsEqual = (a: InfluenceDefinition, b: InfluenceDefinition): boolean =>
    influenceRefsEqual(a, b) &&
    a.weight === b.weight

export const dateContainedInPeriod = (d: Date, p: TimePeriod): boolean =>
    d >= p.start && d <= p.end

export const timePeriodContainedIn = (a: TimePeriod, b: TimePeriod): boolean =>
    a.start >= b.start && a.end <= b.end

export const showMessageBox = (message: string, showCancel: boolean = false, onPressOk?: () => void, onPressCancel?: () => void) => {
    if (getPlatform() === 'web') {
        ReactTooltip.hide()
        if (!showCancel) {
            window.alert(message)
            if (onPressOk) {
                onPressOk()
            }
        } else {
            const res = window.confirm(message)
            if (res && onPressOk) {
                onPressOk()
            } else if (!res && onPressCancel) {
                onPressCancel()
            }
        }
    } else {
        throw new Error("implement me!")
    }
}

export const showInputBox = (message: string, defaultInput: string | undefined, onPressOk: (input: string) => void, onPressCancel?: () => void) => {
    if (getPlatform() === 'web') {
        const input = window.prompt(message, defaultInput)
        if (input !== null) {
            onPressOk(input)
        } else {
            onPressCancel && onPressCancel()
        }
    } else {
        throw new Error("implement me!")
    }
}

export const isScalarCollection = (collection: SampleCollectionDefinition): boolean =>
    !collection.labelDefinitions

export const getSampleValueAsString = (collection: SampleCollectionDefinition, value: number, withUnitOfMeasure: boolean = true): string => {
    if (isScalarCollection(collection)) {
        return numberToLocString(value) + (withUnitOfMeasure ? ' ' + collection.unitOfMeasure : '')
    } else {
        return collection.labelDefinitions[value]
    }
}

export const isGoalAndNotInfluence = (goalOrInfluence: GoalInstanceComplete | InfluenceInstance): goalOrInfluence is GoalInstanceComplete => {
    const propName: keyof GoalInstanceComplete = 'kpis'
    return propName in goalOrInfluence
}

export const getKpiLastSample = (goal: GoalInstanceComplete, kpi: KpiInstance): SampleInstance | null => {
    // considera anche campioni prima del periodo di attività
    const samplesInPeriod = kpi.sampleCollection.samples.filter(s => s.date <= goal.activePeriod.end)
    return samplesInPeriod.length > 0 ? samplesInPeriod[samplesInPeriod.length - 1] : null
}

export const remapValueOnThresholds = (interpolate: boolean, inValue: number, thresholds: {in: number, out: number}[]) => {
    const ascending = thresholds[0].in < thresholds[1].in
    const overIndex = thresholds.findIndex(t => ascending ? t.in > inValue : t.in < inValue)
    const threshIndex = overIndex < 0 ? thresholds.length - 1 : Math.max(overIndex - 1, 0)
    if (interpolate) {
        const [i0, i1] = threshIndex === thresholds.length - 1 ? [threshIndex - 1, threshIndex] : [threshIndex, threshIndex + 1]
        const [x0, x1] = [thresholds[i0].in, thresholds[i1].in]
        const [y0, y1] = [thresholds[i0].out, thresholds[i1].out]
        const x = inValue
        const y = y0 + (x - x0) * (y1 - y0) / (x1 - x0)
        // clampa tra prima e ultima soglia
        return Math.max(thresholds[0].out, Math.min(thresholds[thresholds.length - 1].out, y))
    } else {
        return thresholds[threshIndex].out
    }
}

// TODO: da togliere
export const amadoriLikertToPercent = (likert: number) =>
    remapValueOnThresholds(true, likert, [
        {in: 1, out: 25},
        {in: 2, out: 50},
        {in: 3, out: 75},
        {in: 4, out: 100},
        {in: 5, out: 120},
    ])

export const getKpiCurrentLikert = (goal: GoalInstanceComplete, kpi: KpiInstance): number | null => {
    const lastSample = getKpiLastSample(goal, kpi)
    if (lastSample === null) {
        return null
    } else {
        // TODO: usare remapValueOnThresholds
        const thresholds = (isScalarCollection(kpi.sampleCollection) ? kpi.numericThresholds : kpi.stringThresholds) as KpiThreshold[]
        const ascending = thresholds[0].kpiValue < thresholds[1].kpiValue
        const overIndex = thresholds.findIndex(t => ascending ? t.kpiValue > lastSample.value : t.kpiValue < lastSample.value)
        const threshIndex = overIndex < 0 ? thresholds.length - 1 : Math.max(overIndex - 1, 0)
        if (kpi.numericThresholds && kpi.withAchievementPercentage) {
            const [i0, i1] = threshIndex === thresholds.length - 1 ? [threshIndex - 1, threshIndex] : [threshIndex, threshIndex + 1]
            const [x0, x1] = [thresholds[i0].kpiValue, thresholds[i1].kpiValue]
            const [y0, y1] = [thresholds[i0].likertValue, thresholds[i1].likertValue]
            const x = lastSample.value
            const y = y0 + (x - x0) * (y1 - y0) / (x1 - x0)
            return Math.max(likertMin, Math.min(likertMax, y))
        } else {
            return thresholds[threshIndex].likertValue
        }
    }
}

// se non ci sono tutti i sample che servono per il calcolo, restituisce il likert minimo
export const calcGoalAggregateLikert = (goal: GoalInstanceComplete): number => {
    const likertsAndWeights = goal.kpis.map(kpi => ({likert: getKpiCurrentLikert(goal, kpi) ?? likertMin, weight: kpi.weight}))
    if (likertsAndWeights.length === 0) throw new Error()
    if (goal.kpiAggregationPolicyId === KpiAggregationPolicyId.Average) {
        return likertsAndWeights.reduce((acc, lw) => acc + (lw.likert * (lw.weight as number)), 0) / likertsAndWeights.reduce((acc, lw) => acc + (lw.weight as number), 0)
    } else if (goal.kpiAggregationPolicyId === KpiAggregationPolicyId.Minimum) {
        return likertsAndWeights.reduce((min, lw) => Math.min(min, lw.likert), likertMax)
    } else {
        throw new Error()
    }
}

export const calcGoalsAverageScore = (goals: GoalInstanceComplete[]): number => {
    const totWeight = sumNumbers(goals.map(g => g.weight))
    console.log(goals.map(g => g.weight))
    return totWeight === 0
        ? likertMin
        : sumNumbers(goals.map(g => g.weight * calcGoalAggregateLikert(g))) / totWeight
}

export const roundLikert = (v: number): number => v - v % likertStep

// true solo se la collection è singola, per quelle condivise non si aggiorna il kpi ma direttamente la collection
export const userCanUpdateKpi = (user: Person, goal: GoalInstanceComplete, kpi: KpiInstance): boolean =>
    !kpi.sampleCollection.shared && (
        personHasPlatformRole(user, PlatformRoleId.Privileged) || // è privilegiato
        (goal.phaseId !== GoalPhaseId.Closed && ( // non è chiuso
            user.id === goal.managerMembership?.personId || // è il manager del goal
            (kpi.affectedCanSample && goal.assigneePersons.find(m => m.personId === user.id) !== undefined) // è un assegnatario e affectedCanSample è true
        ))
    )

export const getKpiTargetString = (kpi: KpiDefinition | KpiInstance, collection: SampleCollectionDefinition | SampleCollectionInstance) => {
    const thresholds = kpi.numericThresholds ?? kpi.stringThresholds
    if (thresholds === null) throw new Error()
    const t = thresholds.find(t => t.likertValue === likertReached)
    if (t === undefined) throw new Error("kpi has no reached threshold")
    if (kpi.numericThresholds) {
        return numberToLocString(t.kpiValue) + (collection.unitOfMeasure ? ' ' + collection.unitOfMeasure : '')
    } else {
        return collection.labelDefinitions[t.kpiValue]
    }
}

export const isGoalOpenAndExpired = (g: GoalInstanceComplete) =>
    g.phaseId === GoalPhaseId.Open && g.activePeriod.end < new Date()

export enum GoalStatus {
    Inconsistent,
    Unapproved,
    Open,
    Expired,
    Closed,
}

export function sortedBy<T>(array: T[], selector: (e: T) => (string | number | boolean), order: 'asc' | 'desc' = 'asc'): T[] {
    return produce(array, (newArray: T[]) => {
        newArray.sort(order === 'asc' ?
            (a, b): number => {
                const sa = selector(a)
                const sb = selector(b)
                return sa < sb ? -1 : sa > sb ? 1 : 0
            } :
            (a, b): number => {
                const sa = selector(a)
                const sb = selector(b)
                return sa < sb ? 1 : sa > sb ? -1 : 0
            })
    })
}

export function sortedByMany<T>(array: T[], selectors: [(e: T) => (string | number | boolean), 'asc' | 'desc'][]): T[] {
    return produce(array, (newArray: T[]) => {
        newArray.sort(
            (a, b): number => {
                for (let i = 0; i < selectors.length; ++i) {
                    const sel = selectors[i]
                    const sa = sel[0](a)
                    const sb = sel[0](b)
                    const [va, vb] = sel[1] === 'asc' ? [sa, sb] : [sb, sa]
                    if (va < vb) return -1
                    else if (va > vb) return 1
                }
                return 0
            }
        )
    })
}

export const flattenArray = <T>(a: T[][]): T[] => a.reduce((acc, v) => acc.concat(v), [])

export const makeMap = <K, V>(items: [K, V][]): Map<K, V> => {
    const m = new Map<K, V>()
    items.forEach(([k, v]) => {
        m.set(k, v)
    })
    return m
}

export const sumNumbers = (ns: number[]) => ns.reduce((acc, n) => acc + n, 0)

export const notNullOrUndefined = <T>(v: T | null | undefined): v is T =>
    v !== null && v !== undefined

export class StringifyMap<K, V> {
    [immerable] = true

    private internalMap: Map<string, [K, V]>

    constructor(items?: [K, V][]) {
        this.internalMap = new Map()

        if (items) {
            items.forEach(([k, v]) => this.set(k, v))
        }
    }

    set(k: K, v: V): void {
        const sk = stableStringify(k)
        this.internalMap.set(sk, [k, v])
    }

    delete(k: K): void {
        const sk = stableStringify(k)
        this.internalMap.delete(sk)
    }

    has(k: K): boolean {
        const sk = stableStringify(k)
        return this.internalMap.has(sk)
    }

    get(k: K): V | undefined {
        const sk = stableStringify(k)
        const item = this.internalMap.get(sk)
        return item ? item[1] : undefined
    }

    get size(): number {
        return this.internalMap.size
    }

    valuesArray(): V[] {
        return Array.from(this.internalMap.values()).map(([k, v]) => v)
    }

    keysArray(): K[] {
        return Array.from(this.internalMap.values()).map(([k, v]) => k)
    }

    itemsArray(): [K, V][] {
        return Array.from(this.internalMap.values())
    }
}

export class StringifySet<V> {
    [immerable] = true
    
    private sMap: StringifyMap<V, V>

    constructor(values?: V[]) {
        this.sMap = values ?
            new StringifyMap(values.map(v => [v, v])) :
            new StringifyMap()
    }

    add(v: V): void {
        this.sMap.set(v, v)
    }

    delete(v: V): void {
        this.sMap.delete(v)
    }

    has(v: V): boolean {
        return this.sMap.has(v)
    }

    get size(): number {
        return this.sMap.size
    }

    toArray(): V[] {
        return this.sMap.valuesArray()
    }
}