import React from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {RootState} from '../state'
import {routeDataFromId} from '../routing'
import {hash} from 'immutable'
import {View} from '../styles'


const connector = connect((s: RootState) => ({navigation: s.navigation}))
type Props = ConnectedProps<typeof connector>

class PureRouter extends React.PureComponent<Props> {
    _lastRenderedElement: React.ReactElement | null = null

    render() {
        //return <Navigator ref={this.onNavigatorRef} renderScene={this.renderCurrentScreen.bind(this)} />
        // per adesso disattivata roba navigator perchè fa casino, magari funziona sistemando la schifezza in renderCurrentScreen

        // usa hash di ScreenStackItem come key dello screen, si spera le proprietà siano sempre diverse, se no va messo un contatore nello stato
        const {screenStack} = this.props.navigation
        return (
            <React.Fragment>
                {screenStack.map((item, index) =>
                    <View style={{display: index === screenStack.size - 1 ? 'flex' : 'none', flex: 1}} key={hash(item)}>
                        {React.createElement(routeDataFromId(item.routeId).compDef, item.props)}
                    </View>)
                }
            </React.Fragment>
        )
    }
}

export default connector(PureRouter)