import { TenantConfig, LocStringsOverride } from "./config"

export const configOverride: TenantConfig = {
    ktaskBackendUrl: "https://bocconi.k-task.app/",
    ktaskClientUrl: "https://bocconi.k-task.app/",
    oidc: {
        domain: 'k-rev.eu.auth0.com',
        clientId: 'zK2HO6R14JPPaucmyzioM3wNx3S8BL94',
    },
    overriddenCustomSettings: {
        minGoalDescriptionLength: 0,
        showLikertProgressBar: false,
        showManageInfluences: false,
        showTimeOfDayForGoals: false,
        showBackofficeForPrivileged: true,
        showAmadoriPercent: false,
        showGoalTypology: false,
        showSharedCollectionsForNormal: false,
        showSingleCollectionSamplersForNormal: false,
        locStringsOverride: {
            'it': [
                ['parent', "Obiettivo genitore"],
                ['parentTooltip', "Selezionare l’obiettivo «genitore» da cui discende l’obiettivo corrente.<br>Tipologie di obiettivo genitore:<br>- Obiettivi assegnati dal responsabile diretto,<br>- Obiettivi derivati direttamente dal Piano Strategico dell’Università (S1, S2, ... S6)<br>- Obiettivi generali/di sviluppo (G1, G2, G3)."],
                ['assigneesTooltip', "Indicare la persona o team di persone a cui assegnare l’obiettivo."],
                ['credits', "Peso %"],
                ['creditsTooltip', "Il peso rappresenta l’importanza dell’obiettivo rispetto agli altri. Assegnare obiettivi alla persona in modo che la somma dei pesi sia pari a 100%."],
                ['visibilityTooltip', "Definire la visibilità dell’obiettivo:<br><br>- STANDARD: l’obiettivo sarà visibile all’assegnatario e ai suoi collaboratori diretti. SOLO questa classificazione consente all’obiettivo di essere selezionabile come «genitore» di altri obiettivi.<br><br>- PRIVATA: l’obiettivo sarà visibile solo al responsabile e all’assegnatario."],
                ['aggregationMethod', "Metodo di aggregazione dei KPI"],
                ['kpiTooltip', "Definire gli indicatori che consentono di rilevare l’avanzamento ed il raggiungimento dell’obiettivo."],
                ['kpiAggregationTooltip', "Indicare il metodo di aggregazione dei KPI che determina la costruzione di un «indicatore unico» per il raggiungimento dell’obiettivo."],
                ['kpiAggrAverage', "Media pesata dei KPI"],
                ['kpiAggrMinimum', "Minimo dei KPI (tutti i KPI devono essere raggiunti)"],

                ['quantitativeThresholdTooltip', "Utilizzare questo indicatore per rilevare KPI di tipo numerico.<br><br>Le soglie da compilare obbligatoriamente sono due: il valore minimo, corrispondente al livello 1, ed il valore corrispondente al livello 3, che definisce quando l’obiettivo risulta raggiunto.<br><br>Tutti gli altri valori intermedi sono facoltativi e vanno compilati solo nel caso in cui si decida di attribuire un grado di raggiungimento parziale dell’obiettivo o si vogliano registrare eventuali valori eccedenti."],
                ['quantitativeContinuousTooltip', "Utilizzare questo indicatore per rilevare KPI di tipo numerico.<br><br>Le soglie da compilare obbligatoriamente sono cinque: i valori intermedi tra questi livelli (+/- 0.5) verranno calcolati automaticamente dal sistema per interpolazione, fornendo così un ulteriore aiuto nella misurazione in itinere dell’obiettivo stesso."],
                ['qualitativeTooltip', "Utilizzare questo indicatore per rilevare KPI di tipo NON numerico, indicando un parametro di valutazione di tipo descrittivo/testuale.<br><br>Le soglie da compilare obbligatoriamente sono due: il valore minimo, corrispondente al livello 1, ed il valore corrispondente al livello 3, che definisce quando l’obiettivo risulta raggiunto.<br><br>Tutti gli altri valori intermedi sono facoltativi e vanno compilati solo nel caso in cui si decida di attribuire un grado di raggiungimento parziale dell’obiettivo o si vogliano registrare eventuali valori eccedenti."],
                ['samplingNotes', "Note"],
                ['createKpi', "Aggiungi KPI"],
                ['collectionData', "Info aggiuntive"],
                ['samplersTooltip', "Indicare chi può monitorare l’andamento dell’obiettivo. In automatico il sistema riporta il nominativo del responsabile.<br><br>Flaggando la voce «assegnatari» anche il collaboratore potrà monitorare ed aggiornare lo stato di avanzamento dell’obiettivo."],
                ['unitOfMeasureTooltip', "Inserire l’unità di misura con la quale rilevare lo stato di avanzamento dell’obiettivo (n°, %, €, …)."],
            ],
        } as LocStringsOverride,
    }
}