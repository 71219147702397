import React from 'react'
import { connect, ConnectedProps} from 'react-redux'
import { Dispatch } from 'redux'
import { ActionType, AppAction } from '../actions'
import ScreenWrapper, { screenWrapperLayout } from '../components/ScreenWrapper'
import { TableTyped } from '../components/Table'
import { LS } from '../loc/loc'
import { ScreenRouteId } from '../routing'
import { RootState } from '../state'

const connector = connect((s: RootState) =>({
    dbInstances: s.dbInstances,
}), (dispatch: Dispatch<AppAction>) => ({
    dispatch,
    logout: () => dispatch({type: ActionType.LOGOUT}),
}))

type ExternalProps = {
}

type ComponentProps = ExternalProps & ConnectedProps<typeof connector>

const SelectDbInstanceScreen: React.FunctionComponent<ComponentProps> = props => {
    return screenWrapperLayout(
        [],
        null,
        null,
        false,
        props.logout,
        [{routeId: ScreenRouteId.Loading, props: null, title: LS('selectDbInstance')}], // messo un route id a caso, tanto usa solo il title
        <TableTyped
            items={props.dbInstances ?? []}
            itemToRow={inst => [inst.title]}
            onPressItem={inst => {
                props.dispatch({type: ActionType.SET_SELECTED_DB_INSTANCE_ID, dbInstanceId: inst.id})
                props.dispatch({type: ActionType.FETCH_COMMON_DATA_REQUEST})
            }}
        />
    )
}

export default connector(SelectDbInstanceScreen)