import {createSelector} from 'reselect'
import { RootState } from './state'
import Imm from 'immutable'
import { TeamMembership, GoalVisibilityPolicyId, GoalInstanceOverview, Person, GoalInstanceComplete, PlatformRoleId, InfluenceRef, InfluenceInstance, GoalPhaseId } from './backendTypes'
import { flattenArray, getCurrentDateTime, GoalStatus, IdType, influenceRefsEqual, isGoalOpenAndExpired, membershipsEqual, membershipsNullableEqual, personHasPlatformRole, sortedBy, sortedByMany, StringifySet, timePeriodContainedIn } from './utils'
import produce from 'immer'
import { dateDifferenceSeconds } from './dateUtils'

const getCommonData = (state: RootState) => {
    if (state.commonData === null) throw new Error("commonData is null in selector")
    return state.commonData
}

export const getUserPerson = createSelector(
    getCommonData,
    data => {
        const personId = data.userData.personId
        const userPerson = data.personsById.get(personId)
        if (!userPerson) throw new Error("user not found in persons")
        return userPerson
    })

export const makeUserHasRole = createSelector(
    getUserPerson,
    user => (role: PlatformRoleId) => user.platformRoleIds.indexOf(role) >= 0
)

export const getPersonsById = createSelector(
    getCommonData,
    data => data.personsById
)

export const getPersons = createSelector(
    getCommonData,
    data => sortedByMany(data.personsById.valueSeq().toArray(), [[p => p.lastName, 'asc'], [p => p.firstName, 'desc']])
)

export const getTeamsById = createSelector(
    getCommonData,
    data => data.teamsById
)

export const getTeams = createSelector(
    getCommonData,
    data => sortedBy(data.teamsById.valueSeq().toArray(), t => t.name)
)

export const getTeamsByMemberId = createSelector(
    [getPersons, getTeams],
    (persons, teams) => Imm.Map(persons.map(p => [p.id, teams.filter(t => t.memberIds.findIndex(pId => pId === p.id) >= 0)]))
)

export const makeGetTeamsFromMemberId = createSelector(
    getTeamsByMemberId,
    teamsByMemberId => (personId: IdType) => {
        const found = teamsByMemberId.get(personId)
        return found ?? []
    }
)

export const getVisibleGoalsById = createSelector(
    getCommonData,
    data => data.visibleGoalsById
)

export const getterVisibleGoalFromId = createSelector(
    getVisibleGoalsById,
    goalsById => (goalId: IdType) => {
        const goal = goalsById.get(goalId)
        return goal === undefined ? null : goal
    }
)

export const getVisibleGoals = createSelector(
    getVisibleGoalsById,
    goalsById => goalsById.valueSeq().toArray()
)

export const getRootGoals = createSelector(
    getVisibleGoals,
    goals => goals.filter(g => g.isRoot)
)

export const getterPersonFromId = createSelector(
    getPersonsById,
    personsById => (id: IdType) => {
        const person = personsById.get(id)
        if (person === undefined) throw new Error("person id " + id + " not found")
        return person
    }
)

export const getterTeamFromId = createSelector(
    getTeamsById,
    teamsById => (id: IdType) => {
        const team = teamsById.get(id)
        if (team === undefined) throw new Error("team id " + id + " not found")
        return team
    }
)

export const getterGoalInstanceOverviewFromId = createSelector(
    getCommonData,
    data => (id: IdType) => {
        const goal = data.visibleGoalsById.get(id)
        if (goal === undefined) throw new Error("goal overview id " + id + " not found")
        return goal
    }
)

export const makeIsLegacyTeamMembership = createSelector(
    getTeamsById,
    (teamsById) => (m: TeamMembership) => {
        const team = teamsById.get(m.teamId)
        if (team === undefined) throw new Error("makeIsLegacyTeamMembership: team id " + m.teamId + " not found")
        return team.legacyMemberIds.findIndex(pId => pId === m.personId) >= 0
    }
)

export const makeIsLegacyAssignedGoal = createSelector(
    [makeIsLegacyTeamMembership],
    isLegacyTeamMembership => (goal: GoalInstanceComplete, assigneeId: IdType) => {
        const assMembs = goal.assigneePersons.filter(m => m.personId === assigneeId)
        const assNonLegacyMembs = assMembs.filter(m => !isLegacyTeamMembership(m))
        return assNonLegacyMembs.length === 0
    }
)

export const getterVisibleGoalsAffectingPerson = createSelector(
    [getVisibleGoals],
    (goals) => (person: Person): GoalInstanceComplete[] => {
        return goals.filter(g => g.assigneePersons.findIndex(m => m.personId === person.id) >= 0)
    }
)

export const makeIsLegacyTeamManagement = createSelector(
    getTeamsById,
    teamsById => (managerMembership: TeamMembership, managedTeamId: IdType) => {
        const team = teamsById.get(managedTeamId)
        if (team === undefined) throw new Error("makeIsLegacyTeamManagement: team id " + managedTeamId + " not found")
        return team.legacyManagers.findIndex(m => membershipsEqual(m, managerMembership)) >= 0
    }
)

export const makeIsLegacyManagementForPerson = createSelector(
    getTeamsById,
    teamsById => (managerId: IdType, teamId: IdType) => {
        const team = teamsById.get(teamId)
        if (team === undefined) throw new Error("makeIsLegacyManagementFromPerson: team id " + teamId + " not found")
        return team.legacyManagers.findIndex(m => m.personId === managerId) >= 0
    }
)

export const getterVisibleGoalsAffectingMembership = createSelector(
    [getVisibleGoals],
    (goals) => (memb: TeamMembership): GoalInstanceComplete[] => {
        return goals.filter(g => g.assigneePersons.findIndex(m => membershipsEqual(m, memb)) >= 0)
    }
)

export const getterVisibleGoalsAffectingPersonForManager = createSelector(
    [getterVisibleGoalsAffectingPerson],
    (getVisibleGoalsAffectingPerson) => (person: Person, manager: Person) => {
        return getVisibleGoalsAffectingPerson(person).filter(goal => goal.managerMembership?.personId === manager.id)
    }
)

// tutte le persone che sono manager di almeno una squadra
export const getAllManagerPersonsWithLegacy = createSelector(
    [getTeams, getterPersonFromId],
    (teams, getPersonFromId) => {
        const ids = new Set(flattenArray(teams.map(t => t.managers.map(m => m.personId))))
        return Array.from(ids).map(id => getPersonFromId(id))
    }
)

// tutte le membership che sono manager di almeno una squadra
export const getAllManagerMembershipsWithLegacy = createSelector(
    [getTeams],
    teams => new StringifySet(flattenArray(teams.map(t => t.managers))).toArray()
)

export const getAllManagerMembershipsNoLegacy = createSelector(
    [getTeams, makeIsLegacyTeamManagement],
    (teams, makeIsLegacyTeamManagement) => new StringifySet(flattenArray(teams.map(t => t.managers.filter(m => !makeIsLegacyTeamManagement(m, t.id))))).toArray()
)

export const getterMembershipsManagedByPersonId = createSelector(
    [getTeams, makeIsLegacyTeamMembership, makeIsLegacyTeamManagement],
    (teams, isLegacyTeamMembership, isLegacyTeamManagement) => (personId: IdType, includeLegacy: boolean): TeamMembership[] => {
        const managedTeams = teams.filter(t => t.managers.some(m => m.personId === personId && (includeLegacy || !isLegacyTeamManagement(m, t.id))))
        const membs = managedTeams.map(t => t.memberIds.map(pId => ({personId: pId, teamId: t.id}))).reduce((acc, el) => acc.concat(el), [] as TeamMembership[])
        return includeLegacy ? membs : membs.filter(m => !isLegacyTeamMembership(m))
    }
)

export const getterMembershipsManagedByPerson = createSelector(
    [getterMembershipsManagedByPersonId],
    (getMembershipsManagedByPersonId) => (person: Person, includeLegacy: boolean) => getMembershipsManagedByPersonId(person.id, includeLegacy)
)

export const getterMembershipsManagedByMembership = createSelector(
    [getTeams, makeIsLegacyTeamManagement, makeIsLegacyTeamMembership],
    (teams, isLegacyTeamManagement, isLegacyTeamMembership) => (memb: TeamMembership, includeLegacy: boolean): TeamMembership[] => {
        const allManagedTeams = teams.filter(t => t.managers.some(teamMemb => teamMemb.personId === memb.personId && teamMemb.teamId === memb.teamId))
        const managedTeams = includeLegacy ? allManagedTeams : allManagedTeams.filter(t => !isLegacyTeamManagement(memb, t.id))
        const allMembs = managedTeams.map(t => t.memberIds.map(pId => ({personId: pId, teamId: t.id}))).reduce((acc, el) => acc.concat(el), [] as TeamMembership[])
        return includeLegacy ? allMembs : allMembs.filter(m => !isLegacyTeamMembership(m))
    }
)

export const getAllMembershipsWithLegacy = createSelector(
    [getTeams],
    (teams) => teams.map(t => t.memberIds.map(pId => ({personId: pId, teamId: t.id}))).reduce((acc, el) => acc.concat(el), [] as TeamMembership[])
)

export const makeIsLegacyCollaborator = createSelector(
    [makeIsLegacyTeamMembership, getterMembershipsManagedByPerson],
    (isLegacyTeamMembership, getMembershipsManagedByPerson) => (manager: Person, collaborator: Person) => {
        const nonLegacyMs = getMembershipsManagedByPerson(manager, false).filter(m => m.personId === collaborator.id)
        return nonLegacyMs.length === 0
    }
)

export const getAllGoalTypologies = createSelector(
    [getCommonData],
    (commonData) => commonData.goalTypologiesById.valueSeq().toArray()
)

export const getterGoalTypologyFromId = createSelector(
    getCommonData,
    commonData => (typId: IdType) => {
        const typ = commonData.goalTypologiesById.get(typId)
        if (typ === undefined) throw new Error("typology id " + typId + " not found")
        return typ
    }
)

export const getAllTiers = createSelector(
    getCommonData,
    data => data.goalTiersById.valueSeq().toArray()
)

export const getterTierFromId = createSelector(
    [getCommonData],
    data => (tierId: IdType) => {
        const tier = data.goalTiersById.get(tierId)
        if (tier === undefined) throw new Error("tier id " + tierId + " not found")
        return tier
    }
)

export const getterGoalTypologiesUsableByTierId = createSelector(
    [getAllGoalTypologies, getterTierFromId],
    (typs, getTierFromId) => (tierId: IdType) => {
        const tierOrdinal = getTierFromId(tierId).ordinal
        return Imm.List(typs.filter(t => getTierFromId(t.tierId).ordinal >= tierOrdinal)).sortBy(t => getTierFromId(t.tierId).ordinal).toArray()
    }
)

export const getterGoalTypologiesUsableByPersonId = createSelector(
    [getterPersonFromId, getterGoalTypologiesUsableByTierId],
    (getPersonFromId, getTypologiesUsableByTierId) => (personId: IdType) => {
        const tierId = getPersonFromId(personId).goalTierId
        return getTypologiesUsableByTierId(tierId)
    }
)

export const getTopTier = createSelector(
    [getAllTiers],
    (tiers) => {
        return tiers.reduce((minOrdinalTier, t) => t.ordinal < minOrdinalTier.ordinal ? t : minOrdinalTier, tiers[0])
    }
)

export const getTopTierTipologies = createSelector(
    [getTopTier, getAllGoalTypologies],
    (topTier, typologies) => typologies.filter(t => t.tierId === topTier.id)
)

export const getSharedSampleCollections = createSelector(
    getCommonData,
    (data) => data.sharedSampleCollectionsById.valueSeq().toArray()
)

export const getterSharedSampleCollection = createSelector(
    getCommonData,
    data => (collId: IdType) => {
        const coll = data.sharedSampleCollectionsById.get(collId)
        if (!coll) throw new Error("shared collection " + collId + " not found")
        return coll
    }
)

export const getSamplerGroups = createSelector(
    getCommonData,
    (data) => data.samplerGroupsById.valueSeq().toArray()
)

export const getSamplerGroupsById = createSelector(
    getCommonData,
    data => data.samplerGroupsById
)

export const getCycle = createSelector(
    getCommonData,
    data => data.cycle
)

export const getRootTeams = createSelector(
    getTeams,
    teams => teams.filter(t => t.managers.length === 0)
)

export const getRootMemberships = createSelector(
    getRootTeams,
    rootTeams => rootTeams.flatMap(t => t.memberIds.map(personId => ({personId: personId, teamId: t.id} as TeamMembership)))
)

export const makeGetGoalConsistency = createSelector(
    [
        getterVisibleGoalFromId,
        getterMembershipsManagedByMembership,
        getRootTeams,
        getTopTierTipologies,
        getterGoalTypologiesUsableByPersonId,
        getterTierFromId,
        getterGoalTypologyFromId,
        getCycle,
    ],
    (
        getVisibleGoalFromId,
        getMembershipsManagedByMembership,
        rootTeams,
        topTierTipologies,
        getGoalTypologiesUsableByPersonId,
        getTierFromId,
        getGoalTypologyFromId,
        cycle
    ) => (g: GoalInstanceComplete) => {
        let parent: GoalInstanceComplete | null
        if (g.parentId === null) {
            parent = null
        } else {
            const foundParent = getVisibleGoalFromId(g.parentId)
            if (foundParent === null) {
                return null // parent non visibile da questo utente
            }
            parent = foundParent
        }
        return {
            parentship: {
                valid:
                    // deve rispettare i vincoli per essere di primo, secondo o altro livello
                    ((g.isRoot && parent === null) ||
                    (!g.isRoot && parent !== null && parent.isRoot && g.managerMembership === null && g.assigneePersons.every(m => rootTeams.findIndex(t => t.id === m.teamId) >= 0)) ||
                    (!g.isRoot && parent !== null && g.managerMembership !== null && !g.assigneePersons.some(m => rootTeams.findIndex(t => t.id === m.teamId) >= 0))) &&
                    // se presente, il manager rispetto al proprio team deve essere capo di tutti i team degli assegnatari
                    (g.managerMembership === null || g.assigneePersons.every(m => !!getMembershipsManagedByMembership(g.managerMembership as TeamMembership, true).find(m2 => membershipsEqual(m2, m)))) &&
                    // se c'è il parent, esso non può essere privato
                    (parent === null || parent.visibilityPolicyId !== GoalVisibilityPolicyId.Private) &&
                    // se ci sono parent e manager, il parent deve avere essere root o essere assegnato al manager o alla sua squadra
                    (!(parent !== null && g.managerMembership !== null) || (parent.isRoot || !!parent.assigneePersons.find(m => membershipsEqual(m, g.managerMembership as TeamMembership))))
            },
            assignment: {
                valid:
                    // deve rispettare i vincoli per essere di primo, secondo o altro livello
                    ((g.isRoot && g.assigneePersons.length === 0) ||
                    (!g.isRoot && g.assigneePersons.length > 0))
            },
            typology: {
                valid:
                    // se è root, deve avere una tipologia top tier
                    (!g.isRoot || !!topTierTipologies.find(t => t.id === g.typologyId)) &&
                    // se c'è il manager, la tipologia deve essere permessa al manager
                    (g.managerMembership === null || !!getGoalTypologiesUsableByPersonId(g.managerMembership.personId).find(t => t.id === g.typologyId))
            },
            activePeriod: {
                valid:
                    // se c'è il parent, il periodo di attività del goal deve essere contenuto in quello del parent
                    (parent === null || timePeriodContainedIn(g.activePeriod, parent.activePeriod)) &&
                    // il periodo di attività deve essere contenuto nel periodo del ciclo
                    timePeriodContainedIn(g.activePeriod, cycle.period)
            }
        }
    }
)

export const makeIsGoalConsistent = createSelector(
    makeGetGoalConsistency,
    getGoalConsistency => (goal: GoalInstanceComplete) => {
        const consistency = getGoalConsistency(goal)
        return consistency === null ? null :
            Object.values(consistency).every(v => v.valid)
    }
)

export const makeIsGoalConsistentFromId = createSelector(
    [getterVisibleGoalFromId, makeIsGoalConsistent],
    (getGoalFromId, isGoalConsistent) => (goalId: IdType) => {
        const goal = getGoalFromId(goalId)
        return goal ? isGoalConsistent(goal) : null
    }
)

export const makeIsGoalEffective = createSelector(
    [],
    () => (goal: GoalInstanceComplete) =>
        goal.phaseId !== GoalPhaseId.Unapproved
)

export const makeIsInfluenceEffective = createSelector(
    [getCycle],
    (cycle) => (infl: InfluenceInstance) =>
        !(infl.managerMembership !== null && infl.creatorId === infl.managerMembership.personId &&
            dateDifferenceSeconds(getCurrentDateTime(), infl.creationDate) / 3600 < cycle.influenceEffectDelayHours)
)

export const makeCanPersonEditGoal = createSelector(
    [makeIsGoalEffective],
    (isGoalEffective) => (person: Person, goal: GoalInstanceComplete) => {
        if (personHasPlatformRole(person, PlatformRoleId.Privileged)) {
            return true
        } else {
            return !isGoalEffective(goal)
                && (goal.managerMembership?.personId === person.id || null != goal.assigneePersons.find(m => m.personId === person.id))
        }
    }
)

export const makeCanPersonDeleteGoal = createSelector(
    [makeIsGoalEffective],
    (isGoalEffective) => (person: Person, goal: GoalInstanceComplete) => {
        if (personHasPlatformRole(person, PlatformRoleId.Privileged)) {
            return true
        } else {
            return !isGoalEffective(goal)
                && (goal.managerMembership?.personId === person.id || (goal.creatorId === person.id && null != goal.assigneePersons.find(m => m.personId === person.id)))
        }
    }
)

export const makeCanPersonEditOrDeleteInfluence = createSelector(
    [makeIsInfluenceEffective],
    (isInfluenceEffective) => (person: Person, infl: InfluenceInstance) =>
        personHasPlatformRole(person, PlatformRoleId.Privileged)
            ? infl.managerMembership?.personId === person.id || isInfluenceEffective(infl)
            : infl.managerMembership?.personId === person.id && !isInfluenceEffective(infl)
)

export const makeCanGoalBeParent = createSelector(
    [makeIsGoalConsistent, makeIsGoalEffective],
    (isGoalConsistent, isGoalEffective) => (forPrivileged: boolean, g: GoalInstanceComplete) =>
        g.visibilityPolicyId !== GoalVisibilityPolicyId.Private &&
        (forPrivileged || g.activePeriod.end > new Date()) &&
        isGoalConsistent(g) &&
        isGoalEffective(g)
)

export const makeGetManagerMembershipsOfPerson = createSelector(
    [getTeamsByMemberId, makeIsLegacyManagementForPerson],
    (teamsByMemberId, isLegacyManagementForPerson) => (person: Person, includeLegacy: boolean) => {
        const teams = teamsByMemberId.get(person.id) ?? []
        const membs = flattenArray(teams.map(t => t.managers))
        return includeLegacy ? membs : membs.filter(m => !isLegacyManagementForPerson(m.personId, m.teamId))
    }
)

export const getAllPossibleParentGoalsForPrivileged = createSelector(
    [getVisibleGoals, makeCanGoalBeParent],
    (goals, canGoalBeParent): GoalInstanceOverview[] => {
        return goals.filter(g => canGoalBeParent(true, g))
    }
)

// per manager normale, non privilegiato
export const makeGetPossibleParentGoalsForManager = createSelector(
    [getterVisibleGoalsAffectingPerson, makeCanGoalBeParent, getRootGoals, makeIsLegacyAssignedGoal],
    (getVisibleGoalsAffectingPerson, canGoalBeParent, rootGoals, isLegacyAssignedGoal) => (manager: Person) => {
        return getVisibleGoalsAffectingPerson(manager)
            .filter(g => !isLegacyAssignedGoal(g, manager.id))
            .concat(rootGoals)
            .filter(g => canGoalBeParent(false, g))
    }
)

export const makeGetPossibleParentGoalsForAssignee = createSelector(
    [makeGetManagerMembershipsOfPerson, getterVisibleGoalsAffectingMembership, getRootGoals, makeIsLegacyAssignedGoal, makeCanGoalBeParent],
    (getManagerMembershipsOfPerson, getVisibleGoalsAffectingMembership, rootGoals, isLegacyAssignedGoal, canGoalBeParent) => (assignee: Person) => {
        const managers = getManagerMembershipsOfPerson(assignee, false)
        const parents = rootGoals
            .concat(flattenArray(managers.map(m => getVisibleGoalsAffectingMembership(m).filter(g => !isLegacyAssignedGoal(g, m.personId)))))
        const uniqueParents = parents.filter((g, i) => parents.indexOf(g) === i)
        return uniqueParents.filter(g => canGoalBeParent(false, g))
    }
)

export const getterMembershipString = createSelector(
    [getPersonsById, getTeamsById],
    (personsById, teamsById) => (membership: TeamMembership | null, format: string = "@n - @t") => {
        if (membership) {
            const person = personsById.get(membership.personId)
            const team = teamsById.get(membership.teamId)
            const personName = person ? person.firstName + " " + person.lastName : "PERSON_ID_NOT_FOUND(" + membership.personId + ")"
            const teamName = team ? team.name : "TEAM_ID_NOT_FOUND(" + membership.teamId + ")"
            return format.replace('@n', personName).replace('@t', teamName)
        } else {
            return "-"
        }
    }
)

export const getterPossibleOtherParentsForGoalByManager = createSelector(
    [getterTeamFromId, getVisibleGoals, getterGoalTypologiesUsableByPersonId, getterGoalTypologyFromId, getterGoalTypologiesUsableByTierId, makeIsGoalEffective],
    (getTeamFromId, visibleGoals, getGoalTypologiesUsableByPersonId, getGoalTypologyFromId, getGoalTypologiesUsableByTierId, isGoalEffective) =>
        (reparentedGoal: GoalInstanceOverview | GoalInstanceComplete): Map<TeamMembership | null, (GoalInstanceComplete | null)[]> => {
            // se è root, non ci sono parent possibili
            if (reparentedGoal.isRoot) return new Map()

            // trova le membership che sono a capo di tutte le membership assegnatarie
            const assigneeTeamIds = new Set(reparentedGoal.assigneePersons.map(m => m.teamId))
            const managerSets = Array.from(assigneeTeamIds.values()).map(id => {
                const managers = getTeamFromId(id).managers
                return new Set(managers.length > 0 ? managers : [null])
            })
            const possibleManagers = managerSets.length === 0 ?
                new Set() as Set<TeamMembership> :
                managerSets.reduce((acc, set) => new Set(Array.from(acc.values()).filter(m => set.has(m)))) // intersezione di tutti i set

            // obiettivi per ogni manager possibile
            const goalsByManagerEntries = (Array.from(possibleManagers.values()).map(manager => [
                manager,
                visibleGoals.filter(possibleParent =>
                    isGoalEffective(possibleParent) &&
                    ((manager === null && possibleParent.isRoot) || (manager !== null && !possibleParent.isRoot)) &&
                    !(possibleParent.id === reparentedGoal.parentId && membershipsNullableEqual(manager, reparentedGoal.managerMembership)) && // non è già il parent per lo stesso manager
                    (manager === null || possibleParent.assigneePersons.find(assignee => membershipsEqual(manager, assignee))) && // l'obiettivo è assegnato al manager
                    possibleParent.visibilityPolicyId !== GoalVisibilityPolicyId.Private // non è privato
                )
            ]) as [TeamMembership | null, (GoalInstanceComplete | null)[]][]).filter(entry => entry[1].length > 0)
            const goalsByManager = new Map(goalsByManagerEntries)

            // permette nessun parent (se non è già così)
            const goalsByManagerWithNull = produce(goalsByManager, gbm => {
                if (reparentedGoal.parentId !== null) {
                    const noManagerGoals = gbm.get(null)
                    if (noManagerGoals !== undefined) {
                        noManagerGoals.push(null)
                    } else {
                        gbm.set(null, [null])
                    }
                }
            })

            return goalsByManagerWithNull
        }
)

export const getVisibleGoalIdsByParent = createSelector(
    [getCommonData],
    (data) => {
        const goals = data.visibleGoalsById.valueSeq().toArray()
        const goalsByParent = goals.map(parent => [parent.id, goals.filter(g => g.parentId === parent.id).map(g => g.id)] as [IdType, IdType[]])
        return new Map(goalsByParent)
    }
)

export const getVisibleInfluences = createSelector(
    getCommonData,
    data => data.visibleInfluences
)

export const getInfluencesByCollaboratorPerson = createSelector(
    getVisibleInfluences,
    influences => {
        const inflByCollab: Map<IdType, InfluenceInstance[]> = new Map()
        for (let i = 0; i < influences.length; ++i) {
            const infl = influences[i]
            const key = infl.collaboratorMembership.personId
            const collabInfluences = inflByCollab.get(key)
            if (collabInfluences !== undefined) {
                collabInfluences.push(infl)
            } else {
                inflByCollab.set(key, [infl])
            }
        }
        return inflByCollab
    }
)

export const getterInfluencesFromCollaboratorId = createSelector(
    getInfluencesByCollaboratorPerson,
    inflByCollab => (personId: IdType) => inflByCollab.get(personId) ?? []
)

export const getVisibleInfluencesByGoalId = createSelector(
    getVisibleInfluences,
    (influences) => {
        let inflByGoal = new Map<IdType, InfluenceInstance[]>()
        for (let i = 0; i < influences.length; ++i) {
            const infl = influences[i]
            if (!inflByGoal.has(infl.goalId)) {
                inflByGoal.set(infl.goalId, [infl])
            } else {
                inflByGoal.get(infl.goalId)?.push(infl)
            }
        }
    }
)

export const makeGetInfluenceFromRef = createSelector(
    getInfluencesByCollaboratorPerson,
    influencesByCollabId => (ref: InfluenceRef) =>
        influencesByCollabId.get(ref.collaboratorMembership.personId)?.find(infl => influenceRefsEqual(ref, infl))
)

export const makeSortGoals = createSelector(
    [makeIsGoalConsistent],
    (isGoalConsistent) => (goals: GoalInstanceComplete[]) => sortedByMany(goals,
        [[g => isGoalConsistent(g) !== false, 'asc'], [g => g.phaseId, 'asc'], [g => isGoalOpenAndExpired(g), 'desc'], [g => g.title.toLocaleLowerCase(), 'asc']])
)

export const makeGetGoalStatus = createSelector(
    [makeIsGoalConsistent],
    (isGoalConsistent) => (g: GoalInstanceComplete): GoalStatus =>
        !isGoalConsistent(g) ? GoalStatus.Inconsistent :
        g.phaseId === GoalPhaseId.Unapproved ? GoalStatus.Unapproved :
        isGoalOpenAndExpired(g) ? GoalStatus.Expired :
        g.phaseId === GoalPhaseId.Open ? GoalStatus.Open :
        g.phaseId === GoalPhaseId.Closed ? GoalStatus.Closed :
        (() => { throw new Error("could not detect goal status") })()
)

export const getSelectedDbInstance = createSelector(
    [(s: RootState) => s.dbInstances, (s: RootState) => s.selectedDbInstanceId],
    (dbInstances, selDbInstId) => {
        if (dbInstances === null || selDbInstId === null) throw new Error()
        const inst = dbInstances.find(i => i.id === selDbInstId)
        if (!inst) throw new Error()
        return inst
    }
)