import React, { CSSProperties } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../state'
import { AppAction, ActionType } from '../actions'
import { getterMembershipString, getterVisibleGoalsAffectingPersonForManager, getTeamsById, getterMembershipsManagedByPerson, getterPersonFromId, getInfluencesByCollaboratorPerson, getterVisibleGoalFromId, getterTeamFromId, makeIsGoalEffective, makeIsLegacyTeamMembership, makeIsLegacyCollaborator, getUserPerson } from '../selectors'
import { GoalInstanceComplete, GoalPhaseId, InfluenceInstance, Person } from '../backendTypes'
import { FoldableTable, FoldableTableProps, TopItem } from './Table'
import { amadoriLikertToPercent, calcGoalAggregateLikert, calcGoalsAverageScore, flattenArray, IdType, isGoalAndNotInfluence, isGoalOpenAndExpired, notNullOrUndefined, numberToLocString, sortedBy, sortedByMany, sumNumbers } from '../utils'
import { LS, LSl } from '../loc/loc'
import LikertProgressBar from './LikertProgressBar'
import { CardHeaderText, closedGoalOpacity, ContentBigText, ContentButton, ContentText, getTextStyleForGoal, normalTextColor, palette, standardHalfMargin, standardHalfMarginVert, TitleText, View } from '../styles'
import GoalStatus from './GoalStatus'
import { ExportGoalsButton } from '../xlsxUtils'
import { customSettings } from '../config'
import GoalsAggregateInfoPanel from './GoalsAggretateInfoPanel'


const connector = connect((s: RootState) => ({
    getMembershipString: getterMembershipString(s),
    getVisibleGoalsAffectingPersonForManager: getterVisibleGoalsAffectingPersonForManager(s),
    getTeamFromId: getterTeamFromId(s),
    getMembershipsManagedByPerson: getterMembershipsManagedByPerson(s),
    getPersonFromId: getterPersonFromId(s),
    influencesByCollaboratorPerson: getInfluencesByCollaboratorPerson(s),
    getVisibleGoalFromId: getterVisibleGoalFromId(s),
    isLegacyCollaborator: makeIsLegacyCollaborator(s),
}))

type ExternalProps = {
    managerId: IdType,
}

type ComponentProps = ExternalProps & ConnectedProps<typeof connector>

const BaseGoalsByPersonTable: React.FunctionComponent<ComponentProps> = (props) => {
    const makeOpenGoalAction = (goalId: IdType): AppAction => ({type: ActionType.TRY_OPEN_GOAL_COMPLETE, goalId: goalId})
    const {getVisibleGoalsAffectingPersonForManager, getTeamFromId, getMembershipsManagedByPerson, getPersonFromId} = props
    const manager = getPersonFromId(props.managerId)
    const unsortedPersons = Array.from(new Set(getMembershipsManagedByPerson(manager, true).map(m => m.personId))).map(pId => getPersonFromId(pId))

    const collabHasNonClosedGoals = (p: Person) =>
        getVisibleGoalsAffectingPersonForManager(p, manager).some(g => g.phaseId !== GoalPhaseId.Closed)

    const persons = sortedByMany(unsortedPersons, [[p => props.isLegacyCollaborator(manager, p), 'asc'], [p => collabHasNonClosedGoals(p), 'desc'], [p => p.lastName, 'asc'], [p => p.firstName, 'asc']])
    const topItems: TopItem<Person, GoalInstanceComplete | InfluenceInstance>[] = persons.map(p => ({
        outer: p,
        inners: (sortedByMany(getVisibleGoalsAffectingPersonForManager(p, manager), [[g => g.phaseId, 'asc'], [g => isGoalOpenAndExpired(g), 'desc'], [g => g.weight, 'desc']]) as (GoalInstanceComplete | InfluenceInstance)[])
            .concat(sortedBy(props.influencesByCollaboratorPerson.get(p.id) ?? [], i => i.weight, 'desc')),
    }))

    const getCollabStyle = (top: TopItem<Person, GoalInstanceComplete | InfluenceInstance>) => {
        if (props.isLegacyCollaborator(manager, top.outer)) {
            const highlight = collabHasNonClosedGoals(top.outer)
            return highlight ? {color: palette.logo} : {opacity: closedGoalOpacity}
        } else {
            return {}
        }
    }

    /*const subsInfluencesWithGoals = (goalsAndInfluences: (GoalInstanceComplete | InfluenceInstance)[]) =>
        goalsAndInfluences.map(goalOrInfluence => isGoalAndNotInfluence(goalOrInfluence)
            ? goalOrInfluence
            : props.getVisibleGoalFromId(goalOrInfluence.goalId))
                .filter(notNullOrUndefined)*/

    const foldProps: FoldableTableProps<Person, GoalInstanceComplete | InfluenceInstance> = {
        topItems: topItems,
        columnNames: [LS('collaborator'), ' ', LS('goal'), LS('credits'), LS('team')].concat(customSettings.showLikertProgressBar ? [LS('score')] : []),
        columnWidths: [1, '30px', 2, '75px', 1].concat(customSettings.showLikertProgressBar ? ['258px'] : []),
        showFilters: [true, false, true, false, true, false],
        filterPlaceholders: [LS('filterCollaborator'), '', LS('filterTitle'), '', LS('filterTeam')],
        innerToFilterStrings: [
            (_, top) => top.outer.firstName + ' ' + top.outer.lastName,
        ],
        topItemToRow: top => {
            const style: CSSProperties = getCollabStyle(top)
            const averageScore = calcGoalsAverageScore(top.inners.filter(isGoalAndNotInfluence))
            return [
                <ContentText style={style} string={top.outer.firstName + ' ' + top.outer.lastName} />,
                '',
                (() => {
                    const goalsNum = top.inners.filter(isGoalAndNotInfluence).length
                    const text = numberToLocString(goalsNum) + ' ' + (goalsNum === 1 ? LSl('goal') : LSl('goals'))
                    return <ContentText string={text} style={getCollabStyle(top)} />
                })(),
                '',
                '',
            ].concat(customSettings.showLikertProgressBar ? [
                <ContentText tooltip={numberToLocString(averageScore, 3)} string={LS('averageScore') + ": " + numberToLocString(averageScore, 1) + " (" + numberToLocString(amadoriLikertToPercent(averageScore), 2) + " %)"} />
            ] : [])
        },
        expandSignStyleFunc: top => getCollabStyle(top),
        innerToRow: (inner, rowIndex, relativeTop) => {
            if (isGoalAndNotInfluence(inner)) {
                const style = getTextStyleForGoal(inner)
                return [
                    '',
                    <View style={style}><GoalStatus goal={inner} lightBg={false} /></View>,
                    <ContentText string={inner.title} style={style} />,
                    <ContentText string={numberToLocString(inner.weight)} style={style} />,
                    <ContentText string={getTeamFromId(inner.assigneePersons.find(m => m.personId === relativeTop.outer.id)?.teamId ?? "").name ?? '-'} style={style} />,
                ].concat(customSettings.showLikertProgressBar ? [
                    <View style={style}><LikertProgressBar aggregateValue={calcGoalAggregateLikert(inner)} lightBg={rowIndex % 2 === 0} /></View>,
                ] : [])
            } else {
                const goal = props.getVisibleGoalFromId(inner.goalId)
                return [
                    '',
                    null,
                    <ContentText style={{fontStyle: 'italic'}} string={goal?.title ?? LS('unknownLabel')} />,
                    numberToLocString(inner.weight),
                    getTeamFromId(inner.collaboratorMembership.teamId).name,
                ].concat(customSettings.showLikertProgressBar ? [
                    goal === null ? null : <LikertProgressBar aggregateValue={calcGoalAggregateLikert(goal)} lightBg={rowIndex % 2 === 0} />,
                ] : [])
            }
        },
        fillContainer: 'both',
        onPressInner: goalOrInfluence => 'kpis' in goalOrInfluence && props.dispatch(makeOpenGoalAction(goalOrInfluence.id)),
        sideItem: <ExportGoalsButton goals={flattenArray(topItems.map(top => top.inners)).filter(inner => isGoalAndNotInfluence(inner)) as GoalInstanceComplete[]} />
    }
    const totGoalNum = sumNumbers(topItems.map(top => top.inners.filter(isGoalAndNotInfluence).length))
    const totAvgScore = sumNumbers(topItems.map(top => calcGoalsAverageScore(top.inners.filter(isGoalAndNotInfluence)))) / topItems.length
    return (
        <View style={{flexDirection: 'column'}}>
            <GoalsAggregateInfoPanel showAmadoriPercent={false} totGoalNum={totGoalNum} totAvgScore={totAvgScore} />
            <FoldableTable {...foldProps} />
        </View>
    )
}

export default connector(BaseGoalsByPersonTable)