import {ScreenRouteId, getUrlFromRoute, getRouteFromUrl} from '../routing'
import {sagaMiddleware} from '../index'
import {put} from 'redux-saga/effects'
import {AppAction, ActionType} from '../actions'

class UrlManager {
    _navigatingToNewPlace = false
    _handlingPopState = false

    constructor() {
        window.addEventListener('popstate', e => this._onUrlChange(e))
    }

    _onUrlChange = (_: any) => {
        if (!this._navigatingToNewPlace) {
            this._handlingPopState = true
            const route = getRouteFromUrl(window.location.href)
            console.log("back to " + ScreenRouteId[route])
            const action: AppAction = {type: ActionType.NAVIGATE_TO, stackItem: {routeId: route, props: null}}
            sagaMiddleware.run(function*() {
                yield put(action)
            })
            this._handlingPopState = false
        }
    }

    navigatedTo = (route: ScreenRouteId) => {
        if (!this._handlingPopState) {
            this._navigatingToNewPlace = true
            window.history.pushState(null, '', getUrlFromRoute(route))
            this._navigatingToNewPlace = false
        }
    }
}

export default new UrlManager()