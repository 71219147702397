import React from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {RootState} from '../state'
import { KpiDefinition, TeamMembership, GoalVisibilityPolicyId, GoalInstanceOverview, KpiAggregationPolicyId, GoalDefinition, StringMaxLength, PlatformRoleId, GoalInstanceComplete, KpiInstance, SampleCollectionDefinition } from '../backendTypes'
import { Card, ContentTextInput, ContentButton, normalFontSize, ContentText, ContentPicker, View, ContentSeparator, WrapperLabel, ScrollView, VerticalContentSeparator, SquareCheckbox } from '../styles'
import ScreenWrapper from '../components/ScreenWrapper'
import { ListSelectorScreenProps } from './ListSelectorScreen'
import { getUserPerson, getVisibleGoalsById, getAllPossibleParentGoalsForPrivileged, getterPersonFromId, getterTeamFromId, getterMembershipString, getterMembershipsManagedByMembership, getterGoalTypologiesUsableByPersonId, getAllGoalTypologies, getCycle, getVisibleGoalIdsByParent, getRootMemberships, makeGetPossibleParentGoalsForManager, getterSharedSampleCollection, getSharedSampleCollections, getterGoalTypologyFromId, getterVisibleGoalFromId, makeGetPossibleParentGoalsForAssignee, getAllManagerMembershipsWithLegacy, getAllManagerMembershipsNoLegacy } from '../selectors'
import { makeShowListSelectorScreen, makeShowListSelectorScreenTyped, getEnabledGoalVisibilityIds, IdType, intEnumString, updateState, intEnumValueFromString, isStringValid, stringToNumber, personHasPlatformRole, dateToLocalString, intEnumValues, getKpiTargetString, stringToNumberNotNull, membershipsEqual, numberToLocString, sortedBy, sortedByMany } from '../utils'
import DateInput from '../components/DateInput'
import { NavigationPush, ActionType, AppAction } from '../actions'
import { ScreenRouteId } from '../routing'
import { CreateKpiScreenExternalProps } from './CreateKpiScreen'
import produce from 'immer'
import NumberTextInput from '../components/NumberTextInput'
import moment from 'moment'
import { TableItemType, Table, TableTyped } from '../components/Table'
import { LS, locAggregationPolicy, locVisibilityPolicy, LSl } from '../loc/loc'
import { v4 as uuidv4 } from 'uuid'
import { customSettings } from '../config'
import { Dispatch } from 'redux'
import { SelectParentScreenExternalProps } from './SelectParentForNormalScreen'
import { SmallIcon } from '../components/Icon'

const connector = connect((s: RootState) => ({
    userPerson: getUserPerson(s),
    getPersonFromId: getterPersonFromId(s),
    getTeamFromId: getterTeamFromId(s),
    getVisibleGoalFromId: getterVisibleGoalFromId(s),
    visibleGoalsById: getVisibleGoalsById(s),
    getMembershipString: getterMembershipString(s),
    getMembershipsManagedBy: getterMembershipsManagedByMembership(s),
    rootMemberships: getRootMemberships(s),
    getTypologiesUsableByPersonId: getterGoalTypologiesUsableByPersonId(s),
    allGoalTypologies: getAllGoalTypologies(s),
    cycle: getCycle(s),
    possibleParentsForPrivileged: getAllPossibleParentGoalsForPrivileged(s),
    getPossibleParentsForManager: makeGetPossibleParentGoalsForManager(s),
    getPossibleParentsForAssignee: makeGetPossibleParentGoalsForAssignee(s),
    visibleGoalIdsByParent: getVisibleGoalIdsByParent(s),
    getSharedSampleCollection: getterSharedSampleCollection(s),
    allManagerMembershipsWithLegacy: getAllManagerMembershipsWithLegacy(s),
    allManagerMembershipsNoLegacy: getAllManagerMembershipsNoLegacy(s),
}), (dispatch: Dispatch<AppAction>) => ({
    dispatch,
    showListSelectorScreen: makeShowListSelectorScreen(dispatch),
    showListSelectorScreenTyped: makeShowListSelectorScreenTyped(dispatch),
}))

export enum GoalUserMode {
    Privileged,
    Manager,
    Assignee,
}

export type CreateGoalScreenExternalProps = {
    userMode: GoalUserMode,
    dataMode: { type: 'empty' } | { type: 'edit' | 'copy', data: GoalInstanceComplete },
}

type ComponentProps = CreateGoalScreenExternalProps & ConnectedProps<typeof connector>

type CreateGoalScreenState = {
    id: IdType,
    isRoot: boolean | undefined,
    managerMembership: TeamMembership | null | undefined,
    parent: GoalInstanceOverview | null | undefined,
    assigneePersons: TeamMembership[],
    title: string,
    typologyId: IdType | null,
    weightString: string,
    activePeriodStart: Date | null,
    activePeriodEnd: Date | null,
    visibilityPolicyId: GoalVisibilityPolicyId,
    kpiAggregationPolicyId: KpiAggregationPolicyId,
    kpis: KpiDefinition[],
    description: string,
}

const getEnabledKpiAggregationPolicies = () => 
    customSettings.kpiAggregationsOverride.length === 0 ?
        intEnumValues<KpiAggregationPolicyId>(KpiAggregationPolicyId) :
        customSettings.kpiAggregationsOverride

class CreateGoalScreen extends React.PureComponent<ComponentProps, CreateGoalScreenState> {
    private emptyState: CreateGoalScreenState

    constructor(props: ComponentProps) {
        super(props)

        const { dataMode, userMode } = props

        this.emptyState = {
            id: uuidv4(),
            isRoot: false,
            managerMembership: undefined,
            parent: undefined,
            assigneePersons: [],
            title: "",
            typologyId: null,
            weightString: "",
            activePeriodStart: new Date(),
            activePeriodEnd: props.cycle.period.end,
            visibilityPolicyId: getEnabledGoalVisibilityIds()[0],
            kpiAggregationPolicyId: KpiAggregationPolicyId.Minimum,
            kpis: [],
            description: "",
        }

        this.state = dataMode.type === 'empty' ? this.emptyState : {
            id: dataMode.type === 'edit' ? dataMode.data.id : uuidv4(),
            isRoot: dataMode.type === 'edit'
                ? dataMode.data.isRoot
                : this.emptyState.isRoot,
            managerMembership: dataMode.data.managerMembership,
            parent: dataMode.type === 'edit'
                ? dataMode.data.parentId ? (props.visibleGoalsById.get(dataMode.data.parentId) ?? null) : null
                : this.emptyState.parent,
            assigneePersons: dataMode.type === 'edit'
                ? dataMode.data.assigneePersons
                : this.emptyState.assigneePersons,
            title: dataMode.data.title,
            description: dataMode.data.description,
            typologyId: dataMode.data.typologyId,
            weightString: numberToLocString(dataMode.data.weight),
            activePeriodStart: dataMode.data.activePeriod.start,
            activePeriodEnd: dataMode.data.activePeriod.end,
            visibilityPolicyId: dataMode.data.visibilityPolicyId,
            kpiAggregationPolicyId: dataMode.data.kpiAggregationPolicyId,
            kpis: dataMode.data.kpis.map(k => ({
                id: dataMode.type === 'copy' ? uuidv4() : k.id,
                ordinal: k.ordinal,
                title: k.title,
                affectedCanSample: k.affectedCanSample,
                weight: k.weight,
                withAchievementPercentage: k.withAchievementPercentage,
                sampleCollectionDef: k.sampleCollection.shared ? null : {
                    id: dataMode.type === 'copy' ? uuidv4() : k.sampleCollection.id,
                    name: k.sampleCollection.name,
                    description: k.sampleCollection.description,
                    samplerGroupIds: k.sampleCollection.samplerGroupIds,
                    shared: k.sampleCollection.shared,
                    samplingNotes: k.sampleCollection.samplingNotes,
                    unitOfMeasure: k.sampleCollection.unitOfMeasure,
                    labelDefinitions: k.sampleCollection.labelDefinitions,
                } as SampleCollectionDefinition,
                existingSampleCollectionId: k.sampleCollection.shared ? k.sampleCollection.id : null,
                numericThresholds: k.numericThresholds,
                stringThresholds: k.stringThresholds,
            } as KpiDefinition)),
        }
    }

    render() {
        const {props, state} = this

        const creating = props.dataMode.type === 'empty' || props.dataMode.type === 'copy'
        const privileged = props.userMode === GoalUserMode.Privileged
        const possibleParents = sortedByMany(
            privileged ? props.possibleParentsForPrivileged
                : props.userMode === GoalUserMode.Manager ? props.getPossibleParentsForManager(props.userPerson)
                : props.userMode === GoalUserMode.Assignee ? props.getPossibleParentsForAssignee(props.userPerson)
                : [],
            [[g => g.isRoot, 'desc'], [g => g.title, 'asc']])

        const itemFromMembership = (m: TeamMembership) => {
            return [props.getMembershipString(m, '@n'), props.getMembershipString(m, '@t')]
        }
        const listItemsFromMemberships = (ms: TeamMembership[]) =>
            ms.map(itemFromMembership)

        const showSelectParent = () => {
            if (props.userMode === GoalUserMode.Privileged) {
                const selectParentForPrivilegedProps: ListSelectorScreenProps = {
                    columnNames: [LS('title'), LS('assignees')],
                    items: possibleParents.map(g => {
                        if (g === null) return [LS('noParentSoIsRoot'), ""]
                        const numShownAss = 2
                        const assigneesString = g.assigneePersons.slice(0, numShownAss)
                            .map(m => props.getMembershipString(m)).join("\n")
                            + (g.assigneePersons.length > numShownAss ? " (...)" : "")
                        return [g.title, assigneesString]
                    }),
                    multiSelection: false,
                    onConfirm: indices => {
                        const parent = possibleParents[indices[0]]
                        if (parent === null) {
                            updateState(this, changeParentGoalAndManager, {parent: null, manager: null})
                        } else {
                            const possibleManagers = parent.isRoot
                                ? [null as TeamMembership | null].concat(props.allManagerMembershipsWithLegacy)
                                : parent.assigneePersons
                            if (possibleManagers.length === 1) {
                                updateState(this, changeParentGoalAndManager, {parent: parent, manager: possibleManagers[0]})
                            } else { // in caso non ci sia nessun manager possibile va comunque a finire qui, e la lista sarà quindi vuota
                                props.showListSelectorScreenTyped<TeamMembership | null>(
                                    LS('selectManager'),
                                    [LS('name'), LS('team')],
                                    undefined,
                                    possibleManagers,
                                    m => m ? itemFromMembership(m) : [LS('noManager'), ''],
                                    false,
                                    selItems => {
                                        updateState(this, changeParentGoalAndManager, {parent: parent, manager: selItems[0]})
                                    })
                            }
                        }
                    },
                }
                props.showListSelectorScreen(LS('selectParent'), selectParentForPrivilegedProps)
            } else {
                const selectParentForNormalProps: SelectParentScreenExternalProps = {
                    userMode: props.userMode,
                    onConfirm: (gId, m) => updateState(this, changeParentGoalAndManager, {parent: props.getVisibleGoalFromId(gId), manager: m})
                }
                props.dispatch({type: ActionType.NAVIGATION_PUSH, stackItem: {
                    routeId: ScreenRouteId.SelectParentForNormal,
                    props: selectParentForNormalProps,
                }})
            }
        }

        const showSelectAssignees =
            state.managerMembership === undefined ?
                undefined
            :
                () => {
                    /*const possibleAssignees = state.managerMembership ?
                        props.getMembershipsManagedBy(state.managerMembership) :
                        props.rootMemberships*/
                    const possibleAssignees = props.userMode === GoalUserMode.Privileged || props.userMode === GoalUserMode.Manager
                            ? state.managerMembership
                                ? props.getMembershipsManagedBy(state.managerMembership, props.userMode === GoalUserMode.Privileged)
                                : props.rootMemberships
                        : props.userMode === GoalUserMode.Assignee
                            ? state.managerMembership ? props.getMembershipsManagedBy(state.managerMembership, false).filter(m => m.personId === props.userPerson.id) : []
                        : []
                    const initSelIndices = possibleAssignees
                        .map((m, i) => [m, i] as [TeamMembership, number])
                        .filter(([m, _]) => state.assigneePersons.findIndex(m2 => membershipsEqual(m, m2)) >= 0)
                        .map(([_, i]) => i)
                    props.showListSelectorScreen(LS('selectAssignees'), {
                        columnNames: [LS('name'), LS('team')],
                        items: listItemsFromMemberships(possibleAssignees),
                        multiSelection: true,
                        initialSelectedIndices: initSelIndices,
                        onConfirm: indices => {
                            const assignees = indices.map(i => possibleAssignees[i])
                            updateState(this, changeAssignees, assignees)
                        }
                    })
                }

        const getUsableTypologiesFromManager = (m: typeof state.managerMembership) =>
            m === undefined ? [] :
            m === null ? props.allGoalTypologies :
            props.getTypologiesUsableByPersonId(m.personId)

        const usableTypologies = getUsableTypologiesFromManager(state.managerMembership)

        const showKpiScreen = (kpiIndex: number | null) => {
            const createKpiScreenProps: CreateKpiScreenExternalProps = {
                creatingNewGoal: creating,
                kpiData: kpiIndex !== null ? state.kpis[kpiIndex] : null,
                otherKpis: state.kpis.filter((_, index) => index !== kpiIndex),
                manager: state.managerMembership,
                assignees: state.assigneePersons,
                goalIsRoot: state.isRoot ?? false,
                onConfirm: kpi => {
                    if (kpiIndex === null) {
                        updateState(this, addKpi, {kpi: kpi, weight: null})
                    } else {
                        updateState(this, changeKpi, {index: kpiIndex, kpi: kpi})
                    }
                }
            }
            const pushCreateKpiScreen: NavigationPush = {
                type: ActionType.NAVIGATION_PUSH,
                stackItem: {
                    routeId: ScreenRouteId.CreateKpi,
                    props: createKpiScreenProps,
                    title: kpiIndex === null ? undefined : LS('editKpi'),
                },
            }
            props.dispatch(pushCreateKpiScreen)
        }

        const totKpiWeight = state.kpis.reduce((acc, kpi) => acc + (kpi.weight ?? 0), 0)
        const showKpiWeights = state.kpiAggregationPolicyId === KpiAggregationPolicyId.Average

        const onPressOk = () => {
            if (state.isRoot !== undefined &&
                    state.managerMembership !== undefined &&
                    state.parent !== undefined &&
                    state.activePeriodStart !== null &&
                    state.activePeriodEnd !== null &&
                    state.typologyId !== null) {
                const goalDef: GoalDefinition = {
                    id: state.id,
                    isRoot: state.isRoot,
                    teamGoal: false,
                    managerMembership: state.managerMembership,
                    parentId: state.parent?.id ?? null,
                    assigneePersons: state.assigneePersons,
                    title: state.title,
                    typologyId: state.typologyId,
                    weight: stringToNumberNotNull(state.weightString),
                    activePeriod: {
                        start: state.activePeriodStart,
                        end: state.activePeriodEnd,
                    },
                    visibilityPolicyId: state.visibilityPolicyId,
                    kpiAggregationPolicyId: state.kpiAggregationPolicyId,
                    description: state.description,
                    kpis: state.kpis.map(k => {
                        return produce(k, k => {
                            if (state.kpiAggregationPolicyId !== KpiAggregationPolicyId.Average) k.weight = null
                        })}),
                }
                const action: AppAction = {
                        type: creating ? ActionType.CREATE_GOAL : ActionType.EDIT_GOAL,
                        goalDef: goalDef,
                    }
                props.dispatch(action)
            } else {
                console.log("some input missing, state is:\n" + JSON.stringify(state))
            }
        }

        const changeTitle = (s: CreateGoalScreenState, t: string) => {
            s.title = t
        }
        const changeDescription = (s: CreateGoalScreenState, d: string) => {
            s.description = d
        }
        const changeManager = (s: CreateGoalScreenState, m: typeof s.managerMembership) => {
            s.managerMembership = m
            changeAssignees(s, this.emptyState.assigneePersons)
        }
        const changeAssignees = (s: CreateGoalScreenState, ms: TeamMembership[]) => {
            s.assigneePersons = ms
        }
        const changeActivePeriodStart = (s: CreateGoalScreenState, start: typeof s.activePeriodStart) => {
            s.activePeriodStart = start
        }
        const changeActivePeriodEnd = (s: CreateGoalScreenState, end: typeof s.activePeriodEnd) => {
            s.activePeriodEnd = end
        }
        const changeWeight = (s: CreateGoalScreenState, w: typeof s.weightString) => {
            s.weightString = w
        }
        const changeTypology = (s: CreateGoalScreenState, tId: typeof s.typologyId) => {
            s.typologyId = tId
        }
        const changeVisibility = (s: CreateGoalScreenState, v: typeof s.visibilityPolicyId) => {
            s.visibilityPolicyId = v
        }
        const addKpi = (s: CreateGoalScreenState, args: {kpi: KpiDefinition, weight: number | null}) => {
            if (s.kpis.length === 1) {
                changeKpiAggregation(s, getEnabledKpiAggregationPolicies()[0])
            }

            const kpiWithWeight = produce(args.kpi, k => {
                k.weight = args.weight
            })
            s.kpis.push(kpiWithWeight)
        }
        const removeKpi = (s: CreateGoalScreenState, index: number) => {
            s.kpis.splice(index, 1)

            if (s.kpis.length === 1) {
                changeKpiAggregation(s, KpiAggregationPolicyId.Minimum)
            }
        }
        const changeKpi = (s: CreateGoalScreenState, args: {index: number, kpi: KpiDefinition}) => {
            s.kpis[args.index] = args.kpi
        }
        const changeKpiWeight = (s: CreateGoalScreenState, args: {index: number, weight: number | null}) => {
            s.kpis[args.index].weight = args.weight
        }
        const changeKpiAggregation = (s: CreateGoalScreenState, a: KpiAggregationPolicyId) => {
            s.kpiAggregationPolicyId = a
            s.kpis.forEach(k => {
                k.weight = null
            })
        }
        const changeParentGoalAndManager = (s: CreateGoalScreenState,
                args: {parent: GoalInstanceOverview | null | undefined, manager: TeamMembership | null | undefined}) => {
            s.parent = args.parent
            changeManager(s, args.manager)
            // commentato, non cambia periodo automaticamente
            /*changeActivePeriodStart(s, args.parent?.activePeriod.start ?? props.cycle.period.start)
            changeActivePeriodEnd(s, args.parent?.activePeriod.end ?? props.cycle.period.end)*/
            const newUsableTyps = getUsableTypologiesFromManager(args.manager)
            changeTypology(s, newUsableTyps.length > 0 ? newUsableTyps[0].id : null)
        }
        const changeIsRoot = (s: CreateGoalScreenState, newIsRoot: boolean | undefined) => {
            s.isRoot = newIsRoot
            changeParentGoalAndManager(s,
                newIsRoot === true ? { parent: null, manager: null }
                : { parent: undefined, manager: undefined })
        }

        const inputValid = {
            title: isStringValid(state.title, 1, StringMaxLength.mediumName),
            description: isStringValid(state.description, customSettings.minGoalDescriptionLength, StringMaxLength.description),
            parent: state.parent !== undefined && state.managerMembership !== undefined,
            assignees: state.parent === null || state.assigneePersons.length > 0,
            activePeriodStart: state.activePeriodStart !== null && state.activePeriodStart >= (state.parent?.activePeriod.start ?? props.cycle.period.start),
            activePeriodEnd: state.activePeriodEnd !== null && state.activePeriodEnd <= (state.parent?.activePeriod.end ?? props.cycle.period.end) &&
                (privileged || state.activePeriodEnd >= moment().add(10, 'minutes').toDate()) &&
                (state.activePeriodStart !== null && state.activePeriodEnd > state.activePeriodStart),
            weight: (w => w !== null && w > 0)(stringToNumber(state.weightString)),
            kpis: state.kpis.length > 0,
            kpiWeights: state.kpis.map(k =>
                state.kpiAggregationPolicyId === KpiAggregationPolicyId.Average ?
                    k.weight !== null && k.weight > 0 :
                    k.weight === null),
        }
        const allInputsValid = Object.values(inputValid).every(v => typeof v === 'object' ? v.every(w => w) : v)

        const headerButtons = 
            <React.Fragment>
                <ContentButton label={LS('ok')} onPress={allInputsValid ? onPressOk : undefined} />
                <ContentButton label={LS('cancel')} onPress={() => props.dispatch({type: ActionType.NAVIGATION_POP})} />
            </React.Fragment>
        
        const enabledVisibilityIds = state.isRoot ?
            getEnabledGoalVisibilityIds().filter(v => v !== GoalVisibilityPolicyId.Private) :
            getEnabledGoalVisibilityIds()

        return (
            <ScreenWrapper headerItem={headerButtons}>
                <View style={{flexDirection: 'column', flex: 1}}>
                    <View style={{flexDirection: 'column'}}>
                        <Card titleString={LS('goalData')}>
                            <View style={{flexDirection: 'row'}}>
                                <View style={{flexDirection: 'column', flex: 0.5}}>
                                    {privileged && <SquareCheckbox string={LS('rootGoal')} checked={state.isRoot ?? false} onPress={wasChecked => updateState(this, changeIsRoot, !wasChecked)} enabled={props.dataMode.type !== 'edit' && state.kpis.length === 0} />}

                                    <WrapperLabel string={LS('title')}>
                                        <ContentTextInput value={this.state.title} onChangeText={newText => updateState(this, changeTitle, newText)} valid={inputValid.title} />
                                    </WrapperLabel>

                                    <WrapperLabel string={LS('description')}>
                                        <ContentTextInput multiline={true} style={{minHeight: normalFontSize * 4}} value={state.description}
                                            onChangeText={newText => updateState(this, changeDescription, newText)} valid={inputValid.description} />
                                    </WrapperLabel>

                                    {state.isRoot === false &&
                                        <WrapperLabel string={LS('parent')} tooltip={LS('parentTooltip')}>
                                            <ContentButton label={LS('select')} icon={<SmallIcon name='arrow-right' />} onPress={showSelectParent} valid={inputValid.parent} />
                                            {
                                                <Table items={
                                                    state.parent === undefined || state.managerMembership === undefined ?
                                                        [] :
                                                    state.parent === null && state.managerMembership === null ?
                                                        [[LS('noParentSoIsRoot')]] :
                                                    state.parent !== null ?
                                                        [
                                                            [state.parent.title],
                                                            state.parent.isRoot ? [LS('rootGoal')] : [],
                                                            [dateToLocalString(state.parent.activePeriod.start) + ' - ' + dateToLocalString(state.parent.activePeriod.end)],
                                                            [state.managerMembership ? props.getMembershipString(state.managerMembership) : LS('noManager')],
                                                        ].filter(r => r.length > 0) :
                                                        [["error"]]
                                                } />
                                            }
                                        </WrapperLabel>
                                    }
                                </View>

                                <VerticalContentSeparator />

                                <View style={{flexDirection: 'column', flex: 0.5}}>
                                    {!state.isRoot && <WrapperLabel string={LS('assignees')} tooltip={LS('assigneesTooltip')}>
                                        <ContentButton label={LS('select')} icon={<SmallIcon name='arrow-right' />} onPress={showSelectAssignees} valid={inputValid.assignees} />
                                        <ScrollView style={{maxHeight: 150}}>
                                            <Table items={state.assigneePersons.map(m => [props.getMembershipString(m)])} />
                                        </ScrollView>
                                        <ContentSeparator />
                                    </WrapperLabel>}

                                    <WrapperLabel string={LS('activityPeriod')}>
                                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                            <DateInput id='period-start' dateValue={state.activePeriodStart} valid={inputValid.activePeriodStart}
                                                onDateChange={date => updateState(this, changeActivePeriodStart, date)}
                                                implicitTimeOfDay={customSettings.showTimeOfDayForGoals ? undefined : {h: 0, m: 0, s: 0}} />
                                            <ContentText string='-' />
                                            <DateInput dateValue={state.activePeriodEnd} valid={inputValid.activePeriodEnd}
                                                onDateChange={date => updateState(this, changeActivePeriodEnd, date)}
                                                implicitTimeOfDay={customSettings.showTimeOfDayForGoals ? undefined : {h: 23, m: 59, s: 59}} />
                                        </View>
                                    </WrapperLabel>

                                    <View style={{flexDirection: 'row', flex: 1, alignItems: 'flex-start'}}>
                                        <WrapperLabel string={LS('credits')} tooltip={LS('creditsTooltip')}>
                                            <ContentTextInput value={state.weightString} style={{width: 70}} onChangeText={text => {
                                                updateState(this, changeWeight, text)
                                            }} valid={inputValid.weight} />
                                        </WrapperLabel>

                                        { customSettings.showGoalTypology &&
                                            <WrapperLabel string={LS('typology')}>
                                                <ContentPicker items={usableTypologies.map(t => ({label: t.title, value: t.id}))}
                                                    selectedValue={state.typologyId?.toString() ?? ""}
                                                    onValueChange={value => updateState(this, changeTypology, value)}
                                                    editable={usableTypologies.length > 0}
                                                />
                                            </WrapperLabel>
                                        }

                                        <WrapperLabel string={LS('visibility')} tooltip={LS('visibilityTooltip')}>
                                            <ContentPicker items={enabledVisibilityIds.map(id => ({label: locVisibilityPolicy(id), value: intEnumString(GoalVisibilityPolicyId, id)}))}
                                                selectedValue={intEnumString(GoalVisibilityPolicyId, state.visibilityPolicyId)}
                                                onValueChange={value => updateState(this, changeVisibility, intEnumValueFromString(GoalVisibilityPolicyId, value))}
                                            />
                                        </WrapperLabel>
                                    </View>
                                </View>
                            </View>
                        </Card>
                        <Card titleString={LS('kpis')} tooltip={LS('kpiTooltip')} headerItem={<ContentButton label={LS('addKpi')} icon={<SmallIcon name='add' />} onPress={() => showKpiScreen(null)} valid={inputValid.kpis} enabled={state.isRoot !== undefined} />}>
                            {state.kpis.length >= 2 &&
                                <WrapperLabel string={LS('aggregationMethod')} tooltip={LS('kpiAggregationTooltip')}>
                                    <ContentPicker items={getEnabledKpiAggregationPolicies().map(aggrId => ({label: locAggregationPolicy(aggrId), value: intEnumString(KpiAggregationPolicyId, aggrId)}))}
                                        selectedValue={intEnumString(KpiAggregationPolicyId, state.kpiAggregationPolicyId)}
                                        onValueChange={value => updateState(this, changeKpiAggregation, intEnumValueFromString(KpiAggregationPolicyId, value))}
                                    />
                                </WrapperLabel>
                            }
                            <TableTyped
                                columnNames={[LS('kpi'), LS('kpiTarget')].concat(showKpiWeights ? [LS('weight'), "%"] : []).concat([""])}
                                columnWidths={[1 as (number | string), "180px"].concat(showKpiWeights ? ["90px", "90px"] : []).concat(["90px"])}
                                items={state.kpis}
                                itemToRow={(k, i) => {
                                    const collection = k.sampleCollectionDef ?? props.getSharedSampleCollection(k.existingSampleCollectionId ?? '')
                                    return [(<ContentText string={k.title} />) as TableItemType, getKpiTargetString(k, collection)].concat(showKpiWeights ? [
                                        <NumberTextInput style={{flex: 1}} numberValue={k.weight} valid={inputValid.kpiWeights[i]} onChangeNumber={num => updateState(this, changeKpiWeight, {index: i, weight: num})} />,
                                        totKpiWeight > 0 && k.weight !== null ? numberToLocString(k.weight / totKpiWeight * 100, 2) + " %" : "-",
                                    ] : []).concat([
                                        <View style={{flexDirection: 'row', overflow: 'hidden', justifyContent: 'flex-end'}}>
                                            <ContentButton icon={<SmallIcon name='edit' />} iconSize={1.5 * normalFontSize} onPress={() => showKpiScreen(i)} />
                                            <ContentButton icon={<SmallIcon name='cancel' />} iconSize={1.5 * normalFontSize} onPress={() => updateState(this, removeKpi, i)} />
                                        </View>
                                    ])}
                                }
                            />
                        </Card>
                    </View>
                </View>
            </ScreenWrapper>
        )
    }
}

export default connector(CreateGoalScreen)