import React, { Dispatch } from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {View, ContentText, ContentButton, BaseText, headerFontSize, cardHeaderFontSize} from '../styles'
import {RootState} from '../state'
import {ActionType, AppAction} from '../actions'
import {LS} from '../loc/loc'
import { InternalResultCode } from '../utils'
import { screenWrapperLayout } from '../components/ScreenWrapper'
import { ScreenRouteId } from '../routing'
import { ResultCode } from '../backendTypes'

const connector = connect((s: RootState) => ({
    failure: s.failure
}), (dispatch: Dispatch<AppAction>) => ({
    dispatch,
    logout: () => dispatch({type: ActionType.LOGOUT})
}))

const ErrorScreen: React.FunctionComponent<ConnectedProps<typeof connector>> = props => {
    if (!props.failure) throw new Error()
    const failure = props.failure
    if (!failure.present) throw new Error()
    const message =
        failure.data.internalCode === InternalResultCode.BackendReturnedFailure ?
            failure.data.backendCode :
            failure.data.internalCode
    const tokenExpired = failure.data.internalCode === InternalResultCode.BackendReturnedFailure && failure.data.backendCode === ResultCode.authenticationTokenExpired
    return screenWrapperLayout(
        [],
        null,
        null,
        false,
        props.logout,
        [{routeId: ScreenRouteId.Loading, props: null, title: LS(tokenExpired ? 'sessionExpired' : 'error')}],
        <View style={{flexDirection: 'column', alignItems: 'center'}}>
            <ContentText style={{fontSize: cardHeaderFontSize}} string={LS(tokenExpired ? 'sessionExpiredMessage' : 'errorMessage')} />
            <ContentText string={LS('description') + ": " + message} />
            <View style={{flexDirection: 'column', alignItems: 'center'}}>
                <ContentButton onPress={() => props.dispatch({type: ActionType.LOGOUT})} label={LS(tokenExpired ? 'login' : 'restart')} />
            </View>
        </View>
    )
}

export default connector(ErrorScreen)