import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../state'
import { AppAction, ActionType } from '../actions'
import { ScreenRouteId } from '../routing'
import { getterMembershipString, getUserPerson, getterVisibleGoalsAffectingPerson, getterInfluencesFromCollaboratorId, makeIsGoalConsistent, getterMembershipsManagedByPerson, getterVisibleGoalsAffectingPersonForManager, getterPersonFromId } from '../selectors'
import BaseGoalsByGoalTable from '../components/BaseGoalsByGoalTable'
import BaseGoalsByPersonTable from '../components/BaseGoalsByPersonTable'
import ScreenWrapper from '../components/ScreenWrapper'
import { TabCard } from '../components/TabCard'
import { LS } from '../loc/loc'
import { ContentButton, View } from '../styles'
import { customSettings } from '../config'
import { GoalInstanceComplete, GoalPhaseId, PlatformRoleId } from '../backendTypes'
import { personHasPlatformRole, makeShowListSelectorScreenTyped, intEnumValues, isGoalOpenAndExpired, flattenArray } from '../utils'
import GoalsByManager from '../components/GoalsByManager'
import { Dispatch } from 'redux'
import { CreateGoalScreenExternalProps, GoalUserMode } from './CreateGoalScreen'
import { SelectUserModeExternalProps } from './SelectUserModeScreen'
import SearchGoalScreen from '../components/SearchGoalComponent'

const connector = connect((s: RootState) => ({
    getMembershipString: getterMembershipString(s),
    userPerson: getUserPerson(s),
    getVisibleGoalsAffectingPerson: getterVisibleGoalsAffectingPerson(s),
    getInfluencesFromCollaboratorId: getterInfluencesFromCollaboratorId(s),
    isGoalConsistent: makeIsGoalConsistent(s),
    getMembershipsManagedByPerson: getterMembershipsManagedByPerson(s),
    getVisibleGoalsAffectingPersonForManager: getterVisibleGoalsAffectingPersonForManager(s),
    getPersonFromId: getterPersonFromId(s),
}), (dispatch: Dispatch<AppAction>) => ({
    dispatch,
}))

const GoalsScreenComponent: React.FunctionComponent<ConnectedProps<typeof connector>> = (props) => {
    const [tabIndex, setTabIndex] = React.useState(0)

    const privileged = personHasPlatformRole(props.userPerson, PlatformRoleId.Privileged)

    const onPressCreateGoal = () => {
        const selectUserModeScreenProps: SelectUserModeExternalProps = {
            onConfirm: mode => {
                const createGoalScreenProps: CreateGoalScreenExternalProps = {
                    userMode: mode,
                    dataMode: {
                        type: 'empty',
                    }
                }
                props.dispatch({type: ActionType.NAVIGATION_PUSH, stackItem: {
                    routeId: ScreenRouteId.CreateGoal,
                    props: createGoalScreenProps,
                }})
            }
        }
        props.dispatch({type: ActionType.NAVIGATION_PUSH, stackItem: {
            routeId: ScreenRouteId.SelectUserMode,
            props: selectUserModeScreenProps,
        }})
    }
    
    const createInfluencesAction: AppAction = {type: ActionType.NAVIGATION_PUSH, stackItem: {routeId: ScreenRouteId.CreateInfluences, props: null}}

    const shouldShowCollaboratorGoals = props.getMembershipsManagedByPerson(props.userPerson, true).length > 0

    const shouldGoalBeNotified = (g: GoalInstanceComplete) => !props.isGoalConsistent(g) || g.phaseId === GoalPhaseId.Unapproved || isGoalOpenAndExpired(g)
    const myNotifNum = props.getVisibleGoalsAffectingPerson(props.userPerson).filter(shouldGoalBeNotified).length
    const collabPersons = Array.from(new Set(props.getMembershipsManagedByPerson(props.userPerson, true).map(m => m.personId))).map(pId => props.getPersonFromId(pId))
    const collabGoals = flattenArray(collabPersons.map(p => props.getVisibleGoalsAffectingPersonForManager(p, props.userPerson)))
    const collabNotifNum = collabGoals.filter(shouldGoalBeNotified).length

    return (
        <ScreenWrapper headerItem={
            <View style={{flexDirection: 'row'}}>
                <ContentButton label={LS('createGoal')} onPress={onPressCreateGoal} />
                {customSettings.showManageInfluences && <ContentButton label={LS('manageInfluences')} onPress={() => props.dispatch(createInfluencesAction)} />}
            </View>
        }>
            <TabCard
                items={
                    (shouldShowCollaboratorGoals ? [{label: LS('collaboratorGoals'), content: <BaseGoalsByPersonTable managerId={props.userPerson.id} />, notificationNum: collabNotifNum}] : [])
                        .concat([{label: LS('myGoals'), content: <BaseGoalsByGoalTable mode={'my'} />, notificationNum: myNotifNum}])
                        .concat(privileged ? [{label: LS('goalsByManager'), content: <GoalsByManager />, notificationNum: 0}] : [])
                        .concat([{label: LS('searchGoals'), content: <SearchGoalScreen />, notificationNum: 0}])
                }
                selectedIndex={tabIndex}
                onPressLabel={index => setTabIndex(index)}
            />
        </ScreenWrapper>
    )
}
export const GoalsScreen = connector(GoalsScreenComponent)