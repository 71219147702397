import React, { CSSProperties } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../state'
import { AppAction, ActionType } from '../actions'
import { View, ContentText, standardHalfMarginVert, ContentTextInput, screenTitleFontSize, palette, headerFontSize, closedGoalOpacity, getTextStyleForGoal, ContentButton, WrapperLabel, ContentPicker, ContentLabel, SquareCheckbox, InputLikePressableArea} from '../styles'
import { getAllGoalTypologies, getCycle, getPersons, getTeams, getterInfluencesFromCollaboratorId, getterMembershipString, getterVisibleGoalFromId, getUserPerson, getVisibleGoals, getVisibleInfluences, makeGetGoalStatus, makeIsGoalConsistent, makeIsGoalEffective, makeSortGoals } from '../selectors'
import { TableTyped } from './Table'
import { calcGoalAggregateLikert, dateToLocalString, GoalStatus, IdType, intEnumString, intEnumValueFromString, isGoalAndNotInfluence, isGoalOpenAndExpired, makeShowListSelectorScreenTyped, numberToLocString, sortedBy, sortedByMany, stringToNumber } from '../utils'
import { LS } from '../loc/loc'
import { GoalInstanceComplete, GoalPhaseId, InfluenceInstance, Person, Team } from '../backendTypes'
import { ExportGoalsButton, makeCreateGoalsXlsx } from '../xlsxUtils'
import { GoalListTable } from './GenericGoalListTable'
import NumberTextInput from './NumberTextInput'
import DateInput from './DateInput'
import { likertMax, likertMin } from '../screens/CreateKpiScreen'
import { SmallIcon } from './Icon'
import { customSettings } from '../config'

const connector = connect((s: RootState) => ({
    getMembershipString: getterMembershipString(s),
    visibleGoals: getVisibleGoals(s),
    userPerson: getUserPerson(s),
    visibleInfluences: getVisibleInfluences(s),
    getInfluencesFromCollaboratorId: getterInfluencesFromCollaboratorId(s),
    getVisibleGoalFromId: getterVisibleGoalFromId(s),
    isGoalConsistent: makeIsGoalConsistent(s),
    createGoalsXlsx: makeCreateGoalsXlsx(s),
    sortGoals: makeSortGoals(s),
    goalTypologies: getAllGoalTypologies(s),
    getGoalStatus: makeGetGoalStatus(s),
    cycle: getCycle(s),
    allPersons: getPersons(s),
    allTeams: getTeams(s),
}))

type ExternalProps = {
}

type ComponentProps = ExternalProps & ConnectedProps<typeof connector>

const BaseGoalsByGoalTable: React.FunctionComponent<ComponentProps> = (props) => {
    const defaultState = {
        titleString: '',
        status: null as null | GoalStatus,
        typologyId: null as null | IdType,
        activityStart: props.cycle.period.start,
        activityEnd: props.cycle.period.end,
        activityPartial: true,
        scoreMinString: numberToLocString(likertMin) as string,
        scoreMaxString: numberToLocString(likertMax) as string,
        assigneeTeam: null as null | Team,
        assigneePerson: null as null | Person,
        managerTeam: null as null | Team,
        managerPerson: null as null | Person,
        foundGoalIds: null as null | IdType[],
    }

    const [titleString, setTitleString] = React.useState(defaultState.titleString)
    const [status, setStatus] = React.useState(defaultState.status)
    const [typologyId, setTypologyId] = React.useState(defaultState.typologyId)
    const [activityStart, setActivityStart] = React.useState(defaultState.activityStart)
    const [activityEnd, setActivityEnd] = React.useState(defaultState.activityEnd)
    const [activityPartial, setActivityPartial] = React.useState(defaultState.activityPartial)
    const [scoreMinString, setScoreMinString] = React.useState(defaultState.scoreMinString)
    const [scoreMaxString, setScoreMaxString] = React.useState(defaultState.scoreMaxString)
    const [assigneeTeam, setAssigneeTeam] = React.useState(defaultState.assigneeTeam)
    const [assigneePerson, setAssigneePerson] = React.useState(defaultState.assigneePerson)
    const [managerTeam, setManagerTeam] = React.useState(defaultState.managerTeam)
    const [managerPerson, setManagerPerson] = React.useState(defaultState.managerPerson)

    const [foundGoalIds, setFoundGoalIds] = React.useState(defaultState.foundGoalIds)

    const resetState = () => {
        setTitleString(defaultState.titleString)
        setStatus(defaultState.status)
        setTypologyId(defaultState.typologyId)
        setActivityStart(defaultState.activityStart)
        setActivityEnd(defaultState.activityEnd)
        setActivityPartial(defaultState.activityPartial)
        setScoreMinString(defaultState.scoreMinString)
        setScoreMaxString(defaultState.scoreMaxString)
        setAssigneeTeam(defaultState.assigneeTeam)
        setAssigneePerson(defaultState.assigneePerson)
        setManagerTeam(defaultState.managerTeam)
        setManagerPerson(defaultState.managerPerson)
        setFoundGoalIds(defaultState.foundGoalIds)
    }

    const scoreMin = stringToNumber(scoreMinString)
    const scoreMax = stringToNumber(scoreMaxString)

    const showSelectAssigneeTeam = () => makeShowListSelectorScreenTyped(props.dispatch)(
        LS('selectTeam'),
        [LS('name')],
        [1],
        [null as null | Team].concat(props.allTeams),
        t => [t?.name ?? LS('none')],
        false,
        selItems => setAssigneeTeam(selItems[0])
    )

    const showSelectAssigneePerson = () => makeShowListSelectorScreenTyped(props.dispatch)(
        LS('selectPerson'),
        [LS('name')],
        [1],
        [null as null | Person].concat(props.allPersons),
        p => [p !== null ? p.firstName + ' ' + p.lastName : LS('none')],
        false,
        selItems => setAssigneePerson(selItems[0])
    )

    const showSelectManagerTeam = () => makeShowListSelectorScreenTyped(props.dispatch)(
        LS('selectTeam'),
        [LS('name')],
        [1],
        [null as null | Team].concat(props.allTeams),
        t => [t?.name ?? LS('none')],
        false,
        selItems => setManagerTeam(selItems[0])
    )

    const showSelectManagerPerson = () => makeShowListSelectorScreenTyped(props.dispatch)(
        LS('selectPerson'),
        [LS('name')],
        [1],
        [null as null | Person].concat(props.allPersons),
        p => [p !== null ? p.firstName + ' ' + p.lastName : LS('none')],
        false,
        selItems => setManagerPerson(selItems[0])
    )

    const inputValid = {
        scoreMin: scoreMin !== null && (scoreMax === null || scoreMin <= scoreMax) && scoreMin >= likertMin && scoreMin <= likertMax,
        scoreMax: scoreMax !== null && (scoreMin === null || scoreMax >= scoreMin) && scoreMax >= likertMin && scoreMax <= likertMax,
    }
    const allInputsValid = Object.values(inputValid).every(v => v)

    const inputLikeStyle: CSSProperties = {width: 120, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}

    const foundGoals = foundGoalIds === null
        ? []
        : foundGoalIds.map(id => props.getVisibleGoalFromId(id)).filter(g => g !== null) as GoalInstanceComplete[]

    return <View style={{flexDirection: 'column'}}>
        <View style={{flexDirection: 'row'}}>
            <View style={{flexDirection: 'column'}}>
                <View style={{flexDirection: 'row'}}>
                    <WrapperLabel string={LS('title')}>
                        <ContentTextInput value={titleString} onChangeText={setTitleString} />
                    </WrapperLabel>
                    <WrapperLabel string={LS('status')}>
                        <ContentPicker items={[
                            {label: '', value: ''},
                            {label: LS('goalStatusInconsistent'), value: intEnumString(GoalStatus, GoalStatus.Inconsistent)},
                            {label: LS('goalPhaseUnapproved'), value: intEnumString(GoalStatus, GoalStatus.Unapproved)},
                            {label: LS('goalPhaseOpen'), value: intEnumString(GoalStatus, GoalStatus.Open)},
                            {label: LS('goalStatusExpired'), value: intEnumString(GoalStatus, GoalStatus.Expired)},
                            {label: LS('goalPhaseClose'), value: intEnumString(GoalStatus, GoalStatus.Closed)},
                        ]} selectedValue={intEnumString(GoalStatus, status)} onValueChange={v => setStatus(v === '' ? null : intEnumValueFromString(GoalStatus, v))} />
                    </WrapperLabel>
                    {customSettings.showGoalTypology &&
                        <WrapperLabel string={LS('typology')}>
                            <ContentPicker
                                items={[{label: '', value: ''}].concat(props.goalTypologies.map(t => ({label: t.title, value: t.id})))}
                                selectedValue={typologyId ?? ''} onValueChange={tId => setTypologyId(tId === '' ? null : tId)}
                            />
                        </WrapperLabel>
                    }
                    <WrapperLabel string={LS('score')} contentStyle={{flexDirection: 'row', alignItems: 'center'}}>
                        <ContentTextInput value={scoreMinString} onChangeText={s => setScoreMinString(s)} valid={inputValid.scoreMin} style={{width: 50}} />
                        <ContentText string={'-'} />
                        <ContentTextInput value={scoreMaxString} onChangeText={s => setScoreMaxString(s)} valid={inputValid.scoreMax} style={{width: 50}} />
                    </WrapperLabel>
                </View>
                <WrapperLabel string={LS('activityPeriod')} contentStyle={{flexDirection: 'row', alignItems: 'center'}}>
                    <DateInput dateValue={activityStart} onDateChange={date => setActivityStart(date || props.cycle.period.start)} />
                    <ContentText string={'-'} />
                    <DateInput dateValue={activityEnd} onDateChange={date => setActivityEnd(date || props.cycle.period.end)} />
                    <SquareCheckbox string={LS('includePartialActivityPeriods')} checked={activityPartial} onPress={v => setActivityPartial(!v)} />
                </WrapperLabel>
            </View>
            <View style={{flexDirection: 'column', marginLeft: 'auto'}}>
                <WrapperLabel string={LS('assignee')} contentStyle={{flexDirection: 'row'}}>    
                    <InputLikePressableArea icon={<SmallIcon name={'team'} />} children={<ContentText style={inputLikeStyle} string={assigneeTeam === null ? '' : assigneeTeam.name} />} onPress={() => showSelectAssigneeTeam()} />
                    <InputLikePressableArea icon={<SmallIcon name={'person'} />} children={<ContentText style={inputLikeStyle} string={assigneePerson === null ? '' : assigneePerson.firstName + ' ' + assigneePerson.lastName} />} onPress={() => showSelectAssigneePerson()} />
                </WrapperLabel>
                <WrapperLabel string={LS('manager')} contentStyle={{flexDirection: 'row'}}>
                    <InputLikePressableArea icon={<SmallIcon name={'team'} />} children={<ContentText style={inputLikeStyle} string={managerTeam === null ? '' : managerTeam.name} />} onPress={() => showSelectManagerTeam()} />
                    <InputLikePressableArea icon={<SmallIcon name={'person'} />} children={<ContentText style={inputLikeStyle} string={managerPerson === null ? '' : managerPerson.firstName + ' ' + managerPerson.lastName} />} onPress={() => showSelectManagerPerson()} />
                </WrapperLabel>
            </View>
        </View>

        <View style={{width: '100%', flexDirection: 'row', marginBottom: foundGoalIds !== null ? 2 * standardHalfMarginVert.marginBottom : 0, marginTop: 2 * standardHalfMarginVert.marginTop}}>
            <View style={{flex: 0.25}}>
                <View style={{marginRight: 'auto'}}><ContentButton label={LS('reset')} onPress={resetState} /></View>
            </View>
            <View style={{flex: 0.5, flexDirection: 'row', justifyContent: 'center'}}>
                <ContentButton label={LS('search')} enabled={allInputsValid} onPress={() => {
                    setFoundGoalIds(props.sortGoals(props.visibleGoals.filter(g => {
                        const aggrVal = calcGoalAggregateLikert(g)
                        return (titleString === '' || g.title.toLowerCase().indexOf(titleString.toLowerCase()) >= 0)
                            && (status === null || props.getGoalStatus(g) === status)
                            && (typologyId === null || g.typologyId === typologyId)
                            && (!activityPartial
                                ? (g.activePeriod.start >= activityStart && g.activePeriod.end <= activityEnd)
                                : (g.activePeriod.start <= activityEnd && g.activePeriod.end >= activityStart))
                            && (scoreMin === null || aggrVal >= scoreMin)
                            && (scoreMax === null || aggrVal <= scoreMax)
                            && (assigneeTeam === null || g.assigneePersons.find(m => m.teamId === assigneeTeam.id))
                            && (assigneePerson === null || g.assigneePersons.find(m => m.personId === assigneePerson.id))
                            && (managerTeam === null || g.managerMembership?.teamId === managerTeam.id)
                            && (managerPerson === null || g.managerMembership?.personId === managerPerson.id)
                    })).map(g => g.id))
                }} />
            </View>
            <View style={{flex: 0.25, flexDirection: 'row', justifyContent: 'flex-end'}}>
                {foundGoalIds !== null && <View style={{marginLeft: 'auto'}}><ExportGoalsButton goals={foundGoals} /></View>}
            </View>
        </View>
        
        {foundGoalIds !== null && <GoalListTable items={foundGoals} showFilters={false} />}
    </View>
}

export default connector(BaseGoalsByGoalTable)