import React from 'react'
import { connect, ConnectedProps} from 'react-redux'
import { Dispatch } from 'redux'
import { ActionType, AppAction, CreateDummyGoals } from '../actions'
import DateInput from '../components/DateInput'
import ScreenWrapper from '../components/ScreenWrapper'
import { TableTyped } from '../components/Table'
import { LS } from '../loc/loc'
import { ScreenRouteId } from '../routing'
import { getRootGoals, getSharedSampleCollections } from '../selectors'
import { RootState } from '../state'
import { Card, ContentButton, ContentLabel, ContentSeparator, View, WrapperLabel } from '../styles'
import { dateToLocalString, getSampleValueAsString, makeShowListSelectorScreenTyped, showInputBox, showMessageBox } from '../utils'
import { UpdateCollectionsScreenExternalProps } from './UpdateCollectionsScreen'
import { makeCreatePersonsXlsx, startBlobDownload } from '../xlsxUtils'

const connector = connect((s: RootState) =>({
    sharedSampleCollections: getSharedSampleCollections(s),
    rootGoals: getRootGoals(s),
    createPersonsXlsx: makeCreatePersonsXlsx(s),
}), (dispatch: Dispatch<AppAction>) => ({
    dispatch,
    showListSelectorScreenTyped: makeShowListSelectorScreenTyped(dispatch),
}))

type ExternalProps = {
}

type ComponentProps = ExternalProps & ConnectedProps<typeof connector>

const confirmString = "OK"
const showConfirmBox = (actionFunc: () => void) => {
    showInputBox("Type " + confirmString + " to confirm", undefined, input => {
        if (input === confirmString) {
            actionFunc()
        } else {
            showMessageBox("Wrong confirmation")
        }
    })
}

const BackofficeScreen: React.FunctionComponent<ComponentProps> = props => {
    const [moveDate, setMoveDate] = React.useState(null as Date | null)

    const showRootSelector = () => props.showListSelectorScreenTyped(
        LS('selectRootGoal'),
        [LS('title')],
        undefined,
        props.rootGoals,
        g => [g.title],
        false,
        selItems => {
            showConfirmBox(() => {
                props.dispatch({type: ActionType.SET_ALL_MISSING_PARENTS_TO_ROOT, rootId: selItems[0].id})
            })
        })
    
    const exportGoals = () => {
        props.dispatch({type: ActionType.EXPORT_XLS})
    }

    const exportGoalsExpanded = () => {
        props.dispatch({type: ActionType.EXPORT_XLS_EXPANDED})
    }

    const exportPersons = () => {
        const blob = props.createPersonsXlsx()
        startBlobDownload(blob, "persons.xlsx")
    }

    return <ScreenWrapper>
        <View style={{flexDirection: 'column'}}>
            <ContentButton label={"Create dummy goals"} onPress={() => showConfirmBox(() => props.dispatch({type: ActionType.CREATE_DUMMY_GOALS} as CreateDummyGoals))} />

            <ContentButton label={"Set all missing parents to root"} onPress={showRootSelector} />

            <ContentButton label={"Export goals"} onPress={exportGoals} />

            <ContentButton label={"Export goals expanded"} onPress={exportGoalsExpanded} />

            <ContentButton label={"Export persons"} onPress={exportPersons} />

            <ContentSeparator string={"Move goals and influences with persons"} />
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <ContentLabel string={"From date"} />
                <DateInput dateValue={moveDate} onDateChange={d => setMoveDate(d)} />
                <ContentButton label={"Move"} onPress={moveDate === null ? undefined : () => showConfirmBox(() => props.dispatch({type: ActionType.MOVE_GOALS_AND_INFLUENCES_WITH_PERSONS, time: moveDate}))} />
            </View>
        </View>
    </ScreenWrapper>
}

export default connector(BackofficeScreen)