import produce from 'immer'
import React from 'react'
import {connect, ConnectedProps} from 'react-redux'
import { ActionType, AddSamples } from '../actions'
import { CreateSamplesArgsEntry, GoalInstanceComplete, KpiInstance, SampleCollectionInstance, SampleInstance } from '../backendTypes'
import DateInput from '../components/DateInput'
import ScreenWrapper from '../components/ScreenWrapper'
import { TableTyped } from '../components/Table'
import { LS } from '../loc/loc'
import { RootState } from '../state'
import { Card, ContentButton, ContentPicker, ContentSeparator, ContentText, ContentTextInput, View } from '../styles'
import { getKpiTargetString, getSampleValueAsString, IdType, isScalarCollection, showMessageBox, stringToNumber, userCanUpdateKpi } from '../utils'
import { v4 as uuidv4 } from 'uuid'
import { getCycle, getSharedSampleCollections, getterVisibleGoalFromId, getUserPerson } from '../selectors'
import { SmallIcon } from '../components/Icon'
import ReactTooltip from 'react-tooltip'

const connector = connect((s: RootState) =>({
    cycle: getCycle(s),
    userPerson: getUserPerson(s),
    getVisibleGoalFromId: getterVisibleGoalFromId(s),
    sharedCollections: getSharedSampleCollections(s),
}))

// se goal è null, si è in modalità collezioni condivise
export type UpdateCollectionsScreenExternalProps = {
    goalId: IdType | null,
}

type ComponentProps = UpdateCollectionsScreenExternalProps & ConnectedProps<typeof connector>

const UpdateCollectionsScreen: React.FunctionComponent<ComponentProps> = props => {
    const goal = props.goalId ? props.getVisibleGoalFromId(props.goalId) : null
    // i kpi ci sono solo se c'è il goal, cioè si è in modalità collezioni singole
    const kpis = goal ? goal.kpis.filter(k => userCanUpdateKpi(props.userPerson, goal, k)) : null
    const collections = kpis ? kpis.map(k => k.sampleCollection) : props.sharedCollections

    const [hoveredRowIndex, setHoveredRowIndex] = React.useState(null as number | null)
    // per adesso commentate parti che usano focusedCollection, perchè fanno casino e bisogna cliccare la roba due volte
    //const [focusedCollection, setFocusedCollection] = React.useState(null as SampleCollectionInstance | null)
    const [sampleValueStrings, setSampleValues] = React.useState(collections.map(_ => null as string | null))
    const [sampleDates, setSampleDates] = React.useState(collections.map(_ => null as Date | null))
    
    const detailsCollection: SampleCollectionInstance | null = /*focusedCollection ?? */(hoveredRowIndex !== null ? collections[hoveredRowIndex] : null)
    const detailsKpi: KpiInstance | null = detailsCollection && kpis ? (kpis.find(k => k.sampleCollection.id === detailsCollection.id) ?? null) : null

    const onPressOk = () => {
        const argsEntries = sampleValueStrings.map((s, index) => ({string: s, date: sampleDates[index], coll: collections[index]}))
            .filter(item => item.string !== null)
            .map(item => {
                const valueNumber = stringToNumber(item.string ?? '')
                if (valueNumber === null) throw new Error()
                if (item.date === null) throw new Error()
                const addSamplesEntry: CreateSamplesArgsEntry = {
                    collectionId: item.coll.id,
                    samples: [
                        {
                            id: uuidv4(),
                            value: valueNumber,
                            date: item.date,
                        }
                    ]
                }
                return addSamplesEntry
            })
        const addSamplesAction: AddSamples = {
            type: ActionType.ADD_SAMPLES,
            argsEntries: argsEntries,
            backToSharedCollection: collections[0].shared,
        }
        props.dispatch(addSamplesAction)
    }

    const sampleStringsValid = sampleValueStrings.map(s => s === null || stringToNumber(s ?? '') !== null)
    const datesValid = sampleDates.map((d, index) => sampleValueStrings[index] === null ||
        (d !== null && d <= new Date() && (
            goal === null ? true :
                d <= goal.activePeriod.end
        )))

    const headerItem =
        <React.Fragment>
            <ContentButton label={LS('ok')} onPress={onPressOk}
                enabled={sampleStringsValid.every(b => b) && datesValid.every(b => b) && sampleValueStrings.filter(s => s !== null).length > 0} />
            <ContentButton label={LS('cancel')} onPress={() => props.dispatch({type: ActionType.NAVIGATION_POP})} />
        </React.Fragment>
    
    const makeOnPressDeleteLastSample = (lastSample: SampleInstance) => () => {
        showMessageBox(LS('deleteSampleConfirmMessage'), true, () => {
            props.dispatch({type: ActionType.DELETE_SAMPLE, sampleId: lastSample.id})
        })
    }

    return <ScreenWrapper headerItem={headerItem}>
        <View style={{flexDirection: 'row', alignItems: 'flex-start'}}>
            <Card containerStyle={{flex: 0.67}}>
                <TableTyped
                    items={collections}
                    itemToRow={(coll, collIndex) => {
                        const lastSample = coll.samples.length > 0 ? coll.samples[coll.samples.length - 1] : null
                        return [
                            kpis ? kpis[collIndex].title : coll.name,
                            lastSample ?
                                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                    <ContentText string={getSampleValueAsString(coll, lastSample.value)} />
                                    <View style={{marginLeft: 'auto'}}>
                                        <View tooltip={LS('deleteLastSample')}>
                                            <ContentButton icon={<SmallIcon name='cancel' />} onPress={makeOnPressDeleteLastSample(lastSample)} />
                                        </View>
                                    </View>
                                </View>
                            :
                                '-' + (coll.unitOfMeasure !== null ? ' ' + coll.unitOfMeasure : ''),
                            isScalarCollection(coll) ?
                                <ContentTextInput
                                    value={sampleValueStrings[collIndex] ?? ''}
                                    onChangeText={text => {
                                        const newValueString = text === '' ? null : text
                                        setSampleValues(produce(sampleValueStrings, sv => {
                                            sv[collIndex] = newValueString
                                        }))
                                        if (newValueString === null) {
                                            setSampleDates(produce(sampleDates, sd => {
                                                sd[collIndex] = null
                                            }))
                                        }
                                    }}
                                    valid={sampleStringsValid[collIndex]}
                                    /*onChangeFocus={focus => {
                                        if (focus) {
                                            setFocusedCollection(coll)
                                        } else {
                                            setFocusedCollection(null)
                                        }
                                    }}*/
                                /> :
                                <ContentPicker
                                    items={[{value: '', label: ''}].concat(coll.labelDefinitions.map((l, index) => ({value: index.toString(), label: l})))}
                                    selectedValue={(sampleValueStrings[collIndex] as string | null) ?? ''}
                                    onValueChange={val => setSampleValues(produce(sampleValueStrings, sv => {
                                        sv[collIndex] = val
                                    }))}
                                    /*onChangeFocus={focus => {
                                        if (focus) {
                                            setFocusedCollection(coll)
                                        } else {
                                            setFocusedCollection(null)
                                        }
                                    }}*/
                                />,
                            <DateInput dateValue={sampleDates[collIndex]} onDateChange={date => setSampleDates(produce(sampleDates, sd => {
                                sd[collIndex] = date
                            }))} /*onFocus={() => setFocusedCollection(coll)} onBlur={() => setFocusedCollection(null)}*/
                            valid={datesValid[collIndex]} editable={sampleValueStrings[collIndex] !== null} />
                        ]}
                    }
                    columnNames={[LS('name'), LS('lastValue'), LS('newValue'), LS('date')]}
                    columnWidths={[1, 1, 1, 1.5]}
                    onChangeHover={state => {
                        ReactTooltip.hide() // senza questo rimane in giro il tooltip del bottone elimina si si passa velocemente a altre righe della tabella
                        setHoveredRowIndex(state.hovered ? state.index : null)
                    }}
                    forceShowHover={/*focusedCollection ? false : */true}
                    fillContainer={'both'}
                />
            </Card>
            <Card containerStyle={{flex: 0.33}} titleString={LS('details')}>
                {detailsKpi !== null && detailsCollection !== null ?
                    <React.Fragment>
                        <ContentText string={detailsKpi?.title ?? detailsCollection.name} />

                        <ContentSeparator string={LS('kpiTarget')} />
                        <ContentText string={getKpiTargetString(detailsKpi, detailsCollection)} />

                        <ContentSeparator string={LS('samplingNotes')} />
                        <ContentText string={detailsCollection.samplingNotes !== '' ? detailsCollection.samplingNotes : '-'} />

                        <ContentSeparator string={LS('description')} />
                        <ContentText string={detailsCollection.description !== '' ? detailsCollection.description : '-'} />
                    </React.Fragment>
                :
                    <React.Fragment>
                        <ContentText string={'-'} />
                    </React.Fragment>
                }
            </Card>
        </View>
    </ScreenWrapper>
}
export default connector(UpdateCollectionsScreen)