import React, { CSSProperties } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { GoalInstanceComplete, GoalPhaseId } from '../backendTypes'
import { customSettings } from '../config'
import { LS } from '../loc/loc'
import { makeIsGoalConsistent, makeIsGoalEffective } from '../selectors'
import { RootState } from '../state'
import { calcGoalAggregateLikert, isGoalOpenAndExpired } from '../utils'
import { ConfirmedLargeIcon, ConfirmedSmallIcon, HourglassLargeIcon, HourglassSmallIcon, LargeIcon, SmallIcon, WarningLargeIcon, WarningSmallIcon } from './Icon'
import LikertProgressBar from './LikertProgressBar'

const connector = connect((s: RootState) => ({
    isGoalConsistent: makeIsGoalConsistent(s),
    isGoalEffective: makeIsGoalEffective(s),
}))

type ExternalProps = {
    goal: GoalInstanceComplete,
    lightBg: boolean,
}

type ComponentProps = ExternalProps & ConnectedProps<typeof connector>

const iconStyle: CSSProperties = {textAlign: 'center'}

const GoalStatus: React.FunctionComponent<ComponentProps> = props =>
    props.isGoalConsistent(props.goal) === false ?
        <WarningLargeIcon style={iconStyle} tooltip={LS('goalStatusInconsistent')} />
    : !props.isGoalEffective(props.goal) ?
        <HourglassLargeIcon style={iconStyle} tooltip={LS('goalPhaseUnapproved')} />
    : isGoalOpenAndExpired(props.goal) ?
        <LargeIcon name='timer' style={iconStyle} tooltip={LS('goalStatusExpired')} />
    : props.goal.phaseId === GoalPhaseId.Closed ?
        <LargeIcon name='confirmed' tooltip={LS('goalPhaseClose')} />
    : <LargeIcon name='play' style={iconStyle} tooltip={LS('goalPhaseOpen')} />

export default connector(GoalStatus)