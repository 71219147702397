import React, { CSSProperties } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { ActionType, AppAction } from '../actions'
import { GoalInstanceComplete, InfluenceInstance } from '../backendTypes'
import { customSettings } from '../config'
import { dateToLocalString } from '../dateUtils'
import { LS } from '../loc/loc'
import { getterMembershipString, getterVisibleGoalFromId } from '../selectors'
import { RootState } from '../state'
import { ContentText, getTextStyleForGoal, standardHalfMarginVert, View } from '../styles'
import { calcGoalAggregateLikert, IdType, isGoalAndNotInfluence } from '../utils'
import { ExportGoalsButton } from '../xlsxUtils'
import GoalStatus from './GoalStatus'
import LikertProgressBar from './LikertProgressBar'
import { TableTyped } from './Table'

const connector = connect((s: RootState) => ({
    getMembershipString: getterMembershipString(s),
    getVisibleGoalFromId: getterVisibleGoalFromId(s),
}))

type ExternalProps = {
    items: (GoalInstanceComplete | InfluenceInstance)[],
    showFilters: boolean,
}

type ComponentProps = ExternalProps & ConnectedProps<typeof connector>

export const makeTitleAndDate = (goal: GoalInstanceComplete) =>
    // un po' di margine in più in verticale
    <View style={{flexDirection: 'column', ...standardHalfMarginVert}}>
        <ContentText string={goal.title} />
        <ContentText style={{fontStyle: 'italic'}} string={dateToLocalString(goal.activePeriod.start, '@d') + " - " + dateToLocalString(goal.activePeriod.end, '@d')} />
    </View>

export const titleFilterFunc = (items: (GoalInstanceComplete | InfluenceInstance)[], getVisibleGoalFromId: (goalId: IdType) => GoalInstanceComplete | null) => (s: string, index: number): boolean => {
    const goalOrInfluence = items[index]
    const title = isGoalAndNotInfluence(goalOrInfluence) ?
        goalOrInfluence.title :
        getVisibleGoalFromId(goalOrInfluence.goalId)?.title ?? LS('unknownLabel')
    return title.toLocaleLowerCase().indexOf(s.toLocaleLowerCase()) >= 0
}

const GoalListTableComponent: React.FunctionComponent<ComponentProps> = props => {
    const makeOpenGoalAction = (goalId: IdType): AppAction => ({type: ActionType.TRY_OPEN_GOAL_COMPLETE, goalId: goalId})
    return <TableTyped
        fillContainer={'both'}
        columnNames={['', LS('goal'), LS('assignees'), LS('manager')].concat(customSettings.showLikertProgressBar ? [LS('score')] : [])}
        columnWidths={['30px', 0.5, 1, 1, '258px']}
        showFilters={props.showFilters ? [titleFilterFunc(props.items, props.getVisibleGoalFromId), true, true, false] : undefined}
        items={props.items}
        itemToRow={(goalOrInfluence, index) => {
            const assigneesString = isGoalAndNotInfluence(goalOrInfluence) ?
                goalOrInfluence.assigneePersons.map(m => props.getMembershipString(m)).join("\n") :
                props.getMembershipString(goalOrInfluence.collaboratorMembership)
            if (isGoalAndNotInfluence(goalOrInfluence)) {
                const style = getTextStyleForGoal(goalOrInfluence)
                return [
                    <View style={style}><GoalStatus goal={goalOrInfluence} lightBg={false} /></View>,
                    <View style={style}>{makeTitleAndDate(goalOrInfluence)}</View>,
                    <ContentText string={assigneesString !== '' ? assigneesString : '-'} style={style} />,
                    <ContentText string={props.getMembershipString(goalOrInfluence.managerMembership)} style={style} />,
                ].concat(customSettings.showLikertProgressBar ? [
                    <View style={style}>{<LikertProgressBar aggregateValue={calcGoalAggregateLikert(goalOrInfluence)} lightBg={index % 2 === 0} />}</View>,
                ] : [])
            } else {
                const goal = props.getVisibleGoalFromId(goalOrInfluence.goalId)
                return [
                    null,
                    <View style={{flexDirection: 'column', ...standardHalfMarginVert}}>
                        <ContentText style={{fontStyle: 'italic'}} string={goal?.title ?? LS('unknownLabel')} />
                    </View>,
                    props.getMembershipString(goalOrInfluence.collaboratorMembership),
                    props.getMembershipString(goalOrInfluence.managerMembership),
                ].concat(customSettings.showLikertProgressBar ? [
                    goal === null ? null : <LikertProgressBar aggregateValue={calcGoalAggregateLikert(goal)} lightBg={index % 2 === 0} />,
                ] : [])
            }
        }}
        onPressItem={item => isGoalAndNotInfluence(item) && props.dispatch(makeOpenGoalAction(item.id))}
        sideItem={<ExportGoalsButton goals={props.items.filter(item => isGoalAndNotInfluence(item)) as GoalInstanceComplete[]} />}
        lazyItemsNum={50}
    />
}
export const GoalListTable = connector(GoalListTableComponent)