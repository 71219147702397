import React from 'react'
import { InputLikePressableArea, View } from '../styles'
import moment from 'moment'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import enUs from 'date-fns/locale/en-US'
import it from 'date-fns/locale/it'

import { dateFormatString, timeFormatString } from '../utils'
import { getLocale } from '../baseUtils'
import { SmallIcon } from './Icon'

const localeObj = getLocale() === 'it' ? it : enUs

type DateInputProps = {
    dateValue: Date | null,
    implicitTimeOfDay?: {h: number, m: number, s: number},
    onDateChange: (date: Date | null) => void,
    valid?: boolean,
    editable?: boolean,
    onFocus?: () => void,
    onBlur?: () => void,
    id?: string,
}

export const DateInput: React.FunctionComponent<DateInputProps> = props => {
    const selLocalDate = props.dateValue !== null ?
        moment(props.dateValue).local().toDate() :
        null

    const dateTimeSep = " - "
    const showTimeOfDay = props.implicitTimeOfDay === undefined

    const CustomInput = ({ value, onClick }: {value?: string, onClick?: () => void}) => {
        if (value === undefined || onClick === undefined) return null
        const valid = props.valid ?? true
        const [dateStr, timeStr] = value.split(dateTimeSep)
        const enabled = props.editable ?? true
        return <View style={{flexDirection: 'row'}}>
            <InputLikePressableArea string={dateStr} onPress={onClick} enabled={enabled} valid={valid} icon={<SmallIcon name='calendar' />} containerStyle={{width: 130}} />
            { showTimeOfDay &&
                <InputLikePressableArea string={timeStr} onPress={onClick} enabled={enabled} valid={valid} icon={<SmallIcon name='clock' />} containerStyle={{width: 100}} />
            }
        </View>
    };

    return <DatePicker
        showTimeSelect={showTimeOfDay}
        dateFormat={dateFormatString.replace("DD", "dd").replace("YYYY", "yyyy") + dateTimeSep + timeFormatString}
        timeFormat={showTimeOfDay ? timeFormatString : undefined}
        locale={localeObj}
        timeIntervals={60}
        timeCaption={'🕓'}
        selected={selLocalDate}
        onChange={date => {
            props.onDateChange(props.implicitTimeOfDay === undefined
                ? date
                : date === null
                    ? null
                    : new Date(date.getFullYear(), date.getMonth(), date.getDate(),
                        props.implicitTimeOfDay.h, props.implicitTimeOfDay.m, props.implicitTimeOfDay.s))
        }}
        customInput={<CustomInput />}
        onCalendarOpen={props.onFocus}
        onCalendarClose={props.onBlur}
    />
}

export default DateInput