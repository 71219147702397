import { KpiAggregationPolicyId } from "./backendTypes"
import { LocStrings } from "./loc/def"
import { Language } from "./loc/loc"
import { configOverride as tenantConfig } from "./tenantConfig"

export type LocStringsOverride = {
    [key in Language]: [keyof LocStrings, string][]
}

export const ktaskBackendUrl = tenantConfig.ktaskBackendUrl
export const ktaskClientUrl = tenantConfig.ktaskClientUrl
export const oidc = tenantConfig.oidc

type DefaultCustomSettings = {
    // lunghezza minima della descrizione di un goal (se 0 la descrizione può essere lasciata vuota)
    minGoalDescriptionLength: number,
    // se mostrare le barre likert in tutta l'applicazione
    showLikertProgressBar: boolean,
    // se mostrare il bottone "Modifica influenze"
    showManageInfluences: boolean,
    // se mostrare l'ora delle proprietà data dei goal
    showTimeOfDayForGoals: boolean,
    // se mostrare la tipologia dei goal
    showGoalTypology: boolean,
    // se mostrare il bottone "Fonti dati condivise" nella barra del menu per i non privilegiati
    showSharedCollectionsForNormal: boolean,
    // se mostrare il bottone "Backoffice" nella barra del menu
    showBackofficeForPrivileged: boolean,
    // se mostrare la scelta dei rilevatori per le collection singole per i non privilegiati
    showSingleCollectionSamplersForNormal: boolean,
    // se mostrare la percentuale specifica di Amadori
    showAmadoriPercent: boolean,
    // se non vuoto, elenco di aggregazioni di kpi che lascia scegliere; se vuoto le lascia scegliere tutte
    kpiAggregationsOverride: KpiAggregationPolicyId[],
    // override di stringhe di localizzazione
    locStringsOverride: LocStringsOverride,
}

export const defaultCustomSettings: DefaultCustomSettings = {
    minGoalDescriptionLength: 0,
    showLikertProgressBar: true,
    showManageInfluences: true,
    showTimeOfDayForGoals: true,
    showGoalTypology: true,
    showSharedCollectionsForNormal: true,
    showBackofficeForPrivileged: true,
    showSingleCollectionSamplersForNormal: true,
    showAmadoriPercent: false,
    kpiAggregationsOverride: [
    ] as KpiAggregationPolicyId[],
    locStringsOverride: {
    } as LocStringsOverride,
}

export type TenantConfig = {
    ktaskBackendUrl: string,
    ktaskClientUrl: string,
    oidc: {
        domain: string,
        clientId: string,
    },
    overriddenCustomSettings: Partial<DefaultCustomSettings>,
}

export const customSettings: DefaultCustomSettings = {
    ...defaultCustomSettings,
    ...tenantConfig.overriddenCustomSettings,
}