import React from 'react'
import {connect, ConnectedProps} from 'react-redux'
import { ActionType, DeleteSharedCollection, NavigationPush } from '../actions'
import { PlatformRoleId } from '../backendTypes'
import ScreenWrapper from '../components/ScreenWrapper'
import { TableTyped } from '../components/Table'
import { LS } from '../loc/loc'
import { ScreenRouteId } from '../routing'
import { getSamplerGroupsById, getterMembershipString, getterSharedSampleCollection, getUserPerson, getVisibleGoals } from '../selectors'
import { RootState } from '../state'
import { Card, ContentButton, ContentLabel, ContentText, View, WrapperLabel } from '../styles'
import { IdType, personHasPlatformRole, showMessageBox } from '../utils'
import { CreateSharedCollectionScreenExternalProps } from './CreateSharedCollectionScreen'

export type ViewSharedCollectionScreenExternalProps = {
    collectionId: IdType,
}

const connector = connect((s: RootState) => ({
    getSharedSampleCollection: getterSharedSampleCollection(s),
    getMembershipString: getterMembershipString(s),
    samplerGroupsById: getSamplerGroupsById(s),
    userPerson: getUserPerson(s),
    goals: getVisibleGoals(s),
}))

type ComponentProps = ViewSharedCollectionScreenExternalProps & ConnectedProps<typeof connector>

const ViewSharedCollectionScreen: React.FunctionComponent<ComponentProps> = props => {
    const privileged = personHasPlatformRole(props.userPerson, PlatformRoleId.Privileged)
    const coll = props.getSharedSampleCollection(props.collectionId)
    const screenProps: CreateSharedCollectionScreenExternalProps = {
        editedCollectionId: props.collectionId,
    }
    const onPressEdit = () => {
        const navAction: NavigationPush = {
            type: ActionType.NAVIGATION_PUSH,
            stackItem: {
                routeId: ScreenRouteId.CreateSharedCollection,
                props: screenProps,
                title: LS('edit'),
            }
        }
        props.dispatch(navAction)
    }
    const onPressDelete = () => {
        const goalsUsingColl = props.goals.filter(g => g.kpis.some(k => k.sampleCollection.id === coll.id))
        if (goalsUsingColl.length > 0) {
            showMessageBox(LS('sharedCollectionUsedByFollowingGoals') + '\n' + goalsUsingColl.map(g => g.title).join('\n'))
        } else {
            showMessageBox(LS('deleteSharedCollectionConfirmQuestion'), true, () => {
                const action: DeleteSharedCollection = {
                    type: ActionType.DELETE_SHARED_COLLECTION,
                    collectionId: coll.id,
                }
                props.dispatch(action)
            })
        }
    }
    const headerItem = <>
        {privileged && <>
            <ContentButton label={LS('delete')} onPress={onPressDelete} />
            <ContentButton label={LS('edit')} onPress={onPressEdit} />
        </>}
        <ContentButton label={LS('back')} onPress={() => props.dispatch({type: ActionType.NAVIGATION_POP})} />
    </>
    return <ScreenWrapper headerItem={headerItem}>
        <View style={{flexDirection: 'row'}}>
            <Card titleString={LS('collectionData')}>
                <WrapperLabel string={LS('title')}>
                    <ContentLabel string={coll.name} />
                </WrapperLabel>
                <WrapperLabel string={LS('description')}>
                    <ContentLabel string={coll.description} />
                </WrapperLabel>
                <WrapperLabel string={LS('samplingNotes')}>
                    <ContentLabel string={coll.samplingNotes} />
                </WrapperLabel>
                {coll.unitOfMeasure !== null &&
                    <WrapperLabel string={LS('unitOfMeasure')}>
                        <ContentLabel string={coll.unitOfMeasure} />
                    </WrapperLabel>
                }
                <WrapperLabel string={LS('samplers')}>
                    {coll.samplerGroupIds.map(groupId => {
                        const group = props.samplerGroupsById.get(groupId)
                        return group === undefined ? null :
                            <ContentLabel string={group.name} />
                    })}
                </WrapperLabel>
            </Card>
        </View>
    </ScreenWrapper>
}
export default connector(ViewSharedCollectionScreen)