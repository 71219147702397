import React, { ReactElement } from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {RootState, ScreenStackItem} from '../state'
import {routeDataFromId} from '../routing'
import {BaseTextProps, TitleText, titleHeaderColor, headerRowHeight, standardSpaceHor, screenTitleFontSize, standardHalfPadding, View, palette} from '../styles'

export const screenHeaderLayout = (screenStack: ScreenStackItem[], sideComponent?: ReactElement) => {
    const pathTitleStrings = screenStack.map(stackItem => {
        const screenTitle =
            stackItem ?
                stackItem.title ?
                    stackItem.title :
                    routeDataFromId(stackItem.routeId).title :
                ""
        return screenTitle
    })
    const PathCompCurrent = (props: BaseTextProps) => <TitleText {...props} style={{fontSize: screenTitleFontSize, color: palette.white, ...props.style}} />
    const PathCompPrev = (props: BaseTextProps) => <PathCompCurrent {...props} style={{color: palette.pageTitlePrev, ...props.style}} />
    return (
        <View style={{flexDirection: 'row', backgroundColor: titleHeaderColor, height: headerRowHeight, alignItems: 'center', paddingLeft: standardSpaceHor}}>
            {pathTitleStrings.length > 1 &&
                pathTitleStrings.slice(0, -1).map((s, index) => <PathCompPrev string={s + " / "} key={index} />)
            }
            <PathCompCurrent string={pathTitleStrings[pathTitleStrings.length - 1]} />
            <View style={{...standardHalfPadding, flexGrow: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
                {sideComponent}
            </View>
        </View>
    )
}